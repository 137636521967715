import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import { Reportlist, api_url, procurment_url } from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import calender from "../../../img/Icon/calender.svg";
import { CSVLink } from 'react-csv';

import loadergif from '../../../img/landing/loader123.gif';
export default function Report() {
    var token = localStorage.getItem("access_token");
    const dispatch = useDispatch();
    const currentDate = new Date();
    const [loading, setLoading] = useState(true);
    const formattedCurrentDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    const [reportdata, setReportData] = useState([]);
    const [reportDate, setReportDate] = useState('');
    const [startDate, setStartDate] = useState(formattedCurrentDate);
    const [endDate, setEndDate] = useState(formattedCurrentDate);
    const today = new Date();
    today.setHours(0,0,0,0)

    const prevWeekStart = new Date(today);
    prevWeekStart.setDate(today.getDate() - 7 - today.getDay())
    const prevWeekEnd = new Date(prevWeekStart);
    prevWeekEnd.setDate(prevWeekEnd.getDate() + 6);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    const CurrentendOfWeek = new Date(today);
    CurrentendOfWeek.setDate(today.getDate() + (6 - today.getDay()));
    const nextOfWeek = new Date(today);
    const NextendOfWeek = new Date(today);

    nextOfWeek.setDate(today.getDate() + 7 - today.getDay()); // Set to the first day (Sunday) of the next week
    NextendOfWeek.setDate(today.getDate() + 13 - today.getDay());

    const [prevWeekStartdate, setPrevWeekStartDate] = useState(prevWeekStart.toISOString().split("T")[0]);
    const [prevWeekenddate, setPrevWeekEndDate] = useState(prevWeekEnd.toISOString().split("T")[0]);
    const [startweek, setStartWeek] = useState(startOfWeek.toISOString().split("T")[0]);
    const [CurrentendDate, setCurrentEndDate] = useState(CurrentendOfWeek.toISOString().split("T")[0]);
    const [nextstartDate, setNextStartDate] = useState(nextOfWeek.toISOString().split("T")[0]);
    const [nextendDate, setNextEndDate] = useState(NextendOfWeek.toISOString().split("T")[0]);
    const [activeTab, setActiveTab] = useState('current');


    const [weekStartDate, setWeekStartDate] = useState(startweek);
    const [weekEndDate, setWeekEndDate] = useState(CurrentendDate);

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = date.getDate();
    //     const month = date.toLocaleString("default", { month: "short" });
    //     return `${day} ${month}`;
    // };


    var dateName = function (yyyy, mm, dd, full) {
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dayNames = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        var today = new Date(yyyy, mm - 1, dd);
        console.log ("Todays Date", yyyy, mm, dd)
        var dd = today.getDate();
        var dayNameIndex = (today.getDay() - 1) == -1 ? 6 : today.getDay() - 1
        var dayname = dayNames[dayNameIndex];
        var mm1 = monthNames[today.getMonth()];
        var yyyy1 = today.getFullYear();
        var fullDate = full === "true" ? mm + "/" + dd + "/" + yyyy :mm1 + " " + dd;    
        return fullDate;
      };
    
      const formatDate = (dateString, full) => {
        if (!dateString) {
          return ""; // return empty string if dateString is undefined or empty
        }
        const formattedCurrentDate = dateString && dateString?.split("T")[0]; // Convert date to ISO string
        const dayInformation = dateName(formattedCurrentDate?.split("-")[0], formattedCurrentDate?.split("-")[1], formattedCurrentDate?.split("-")[2], full)
        return dayInformation;
      };


    // date picker 
    const handleDateChange = (event) => {
        const selectedDates = event.target.value.split(" - ");
        const selectedStartDate = selectedDates[0];
        const selectedEndDate = selectedDates[1];
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);

        setWeekStartDate(selectedStartDate);
        setWeekEndDate(selectedEndDate);

        const parts = selectedEndDate.split("/");
        const formattedEndDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
        const partsstart = selectedStartDate.split("/");
        const formattedStartDate = `${partsstart[2]}-${partsstart[0].padStart(2, '0')}-${partsstart[1].padStart(2, '0')}`;
        console.log("Selected Dates:", "startDate =", selectedEndDate, "endDate =", formattedStartDate);
        const requestBody = {
            start_date: formattedStartDate,
            end_date: formattedEndDate
        };

        setWeekStartDate(formattedStartDate);
        setWeekEndDate(formattedEndDate);
        getdatechange(requestBody);
        setActiveTab('');

    };



    const handleDatePREVIOUS = (event) => {
        const requestBody = {
            start_date: prevWeekStartdate,
            end_date: prevWeekenddate
        };
        setWeekStartDate(prevWeekStartdate);
        setWeekEndDate(prevWeekenddate);
        getdatechange(requestBody);
        setActiveTab('previous');
    };


    const handleDateCURRENT = (event) => {
        const requestBody = {
            start_date: startweek,
            end_date: CurrentendDate
        };
        setWeekStartDate(startweek);
        setWeekEndDate(CurrentendDate);

        getdatechange(requestBody);
        setActiveTab('current');
    };


    const handleDateNext = (event) => {
        const requestBody = {
            start_date: nextstartDate,
            end_date: nextendDate
        };
        setWeekStartDate(nextstartDate);
        setWeekEndDate(nextendDate);
        getdatechange(requestBody);
        setActiveTab('next');
    };


    const getdatechange = (requestBody) => {
        setLoading(true); 
        fetch(procurment_url + 'admin/user-report', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json", // Assuming you're sending form data
                Authorization:localStorage.getItem("access_token"),
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.status === true) {
                    console.log('new data ', data.data);
                    // toast.success(data?.message);
                    //   setRagister(data.data);
                    setReportData(data.data);
                } else {
                    toast.error(data?.message);
                }
            })
            .catch(error => {
                console.error(error);
            })
              .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
    });

    }
    // date picker end


    const getReportlist = () => {


        setLoading(true);
        dispatch(Reportlist()).then((res) => {
            if (res?.payload?.status == true) {
                setReportData(res?.payload?.data);
                console.log('sddsdsdsdsdsdadad', res?.payload?.data)

            } else {
                toast.error(res?.payload?.message);
            }
        }).finally(() => {
            setLoading(false); // Set loading to false when data fetching is complete
          });
    };

    useEffect(() => {
        getReportlist();

    }, []);

    let formattedStartDate = "";
    let formattedEndDate = "";

    if (reportdata && reportdata.length > 0) {
        if (reportdata[0].start_date) {
            formattedStartDate = formatDate(reportdata[0].start_date);
        }

        if (reportdata[0].end_date) {
            formattedEndDate = formatDate(reportdata[0].end_date);
        }
    }


    // sorting code

    const [sortColumn, setSortColumn] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (columnName) => {
        if (sortColumn === columnName) {
            setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(columnName);
            setSortOrder('asc');
        }
    };
    const getSortIcon = (columnName) => {
        if (sortColumn === columnName) {
            return sortOrder === 'asc' ? <i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>;
        }
        return null;
    };




    const sortreportdata = [...reportdata].sort((a, b) => {
        if (sortColumn === 'Name') {
            const nameA = a.ingredient_name.toLowerCase();
            const nameB = b.ingredient_name.toLowerCase();
            if (nameA < nameB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (nameA > nameB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        // Handle sorting for other columns if needed
        return 0;
    });
    // sorting code


    const csvData = sortreportdata.map((items) => {

        return {
            ITSNUMBER: items.its_id,
            firstname: items.first_name +' '+items.last_name,
            portionName: items.portion,
            usercount: items.user_count,
        };
    });

    // const totalusercount = sortreportdata.map((items) => {

    //     return {

    //         total_user_count: items[0].total_user_count,
    //     };
    // });

    const totalUserCountSum = sortreportdata.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.total_user_count;
    }, 0);


    const csvHeaders = [
        { label: "ITS Number", key: "ITSNUMBER" },
        { label: "User Name", key: "firstname" },
        { label: "Portion Size", key: "portionName" },
        { label: "Total Thaali Count", key: "usercount" },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = sortreportdata?.length; // Number of items to display per page

    const pageCount = Math.ceil(sortreportdata?.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1) {
            setCurrentPage(1);
        } else if (pageNumber > pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    const handleFirstPage = () => {
        handlePageChange(1);
    };

    const handleLastPage = () => {
        handlePageChange(pageCount);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const displayedItems = sortreportdata?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const reportFileName = "Reports-"+formatDate(weekStartDate) +"-"+ formatDate(weekEndDate)+".csv" 


    return (
        <div>
            <Header />
            <div
                className="side_div_menu"
                style={{ display: "flex", height: "100%" }}
            >
                <Sidebarmenu />
                <section className="main_section">
                    <div className="custam">
                        <div className="main_section2">
                            <div className="ExportInventory thali_section Thali_page">
                                <div className="container-fluid">
                                    <div className="thili_div">
                                        <div className="Back-page">
                                            <h1 className="title_heading">
                                                Report &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                {/* <span className="Line_div">|</span>
                                                <span className="Date_list">{formattedStartDate} - {formattedEndDate}</span>{" "} */}
                                            </h1>{" "}  <h1
                                                className="title_heading"
                                            >
                                                Total User Count ({totalUserCountSum ? totalUserCountSum : "0"})
                                                <span className="Date_list">{formatDate(weekStartDate) +" - "+ formatDate(weekEndDate)}</span>{" "}
                                            </h1>
                                        </div>
                                        <div className="Thali_page_btn New_section">
                                            <div className='Form_box'>
                                            <div className='calender-icon-div'>
                                                <img src={calender} className='img-fluid' /> 
                                                <div className='Calender_div'>
                                                    <DateRangePicker
                                                        initialSettings={{
                                                            startDate: formattedCurrentDate,
                                                            endDate: formattedCurrentDate,
                                                        }}
                                                        // onDateChange={handleDateChange}
                                                        onApply={handleDateChange}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={formattedCurrentDate}
                                                        />
                                                    </DateRangePicker>
                                                </div>
                                                </div>
                                            </div>
                                            {/* <span className='Line_div'>|</span> */}
                                            {/* <button
                                                className="btn thali_btn"
                                            >
                                                Total User Count
                                            </button> */}
                                            &nbsp; &nbsp;
                                            <button className="btn thali_btn csvbtn">
                                                <CSVLink data={csvData} headers={csvHeaders} filename={reportFileName}>
                                                    Export Data
                                                </CSVLink>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="thali_count_list">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Caterer"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div> */}
                                <div className="container-fluid">
                                    <div className="row mt-5">
                                        <div className="col-lg-4 col-md-6">
                                            <div className={`Week_list ${activeTab === 'previous' ? 'active' : ''}`} onClick={handleDatePREVIOUS}>
                                                <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                            fill="#957137"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="week_text">
                                                    <h4>Previous Week From Today</h4>
                                                    <h1>{formatDate(prevWeekStartdate)}-{formatDate(prevWeekenddate)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className={`Week_list INGREDIENTS_Cout  ${activeTab === 'current' ? 'active' : ''}`} onClick={handleDateCURRENT}>
                                                <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                            fill="#591333"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="week_text">
                                                    <h4>Current Week</h4>
                                                    <h1>{formatDate(startweek)}-{formatDate(CurrentendDate)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className={`Week_list Thali_Count ${activeTab === 'next' ? 'active' : ''}`} onClick={handleDateNext}>
                                                <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                            fill="#1F2F32"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="week_text">
                                                    <h4>Next Week From Today</h4>
                                                    <h1>{formatDate(nextstartDate)}-{formatDate(nextendDate)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div></div>

                                <div className="thali_table_section  mt-4">
                                {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className='Loading-img'><img src={loadergif} className='' /></div>

                  ) : (
                    <div></div>
                  )
                  }

                                    <table className="table table-bordered">
                                        <thead className="thead-dark">
                                            <tr>

                                                <th scope="col">
                                                    <span className="thali_table">ITS NUMBER</span>
                                                </th>
                                                <th scope="col" onClick={() => handleSort('Name')}>
                                                    <span className="thali_table">
                                                        USER NAME  &nbsp; &nbsp; &nbsp;
                                                        {getSortIcon('Name')}
                                                    </span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Portion</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Count</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        {displayedItems.map((items) => {

                                            return (
                                                <tbody key={items.id}>

                                                    <tr>
                                                        <th scope="row" >
                                                            <ul className="thali_menu_list">
                                                                <li>{items.its_id ? items.its_id : '-'}</li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" >
                                                            <ul className="thali_menu_list">
                                                                <li>{items.first_name ? items.first_name : '-'} {items.last_name ? items.last_name : '-'}</li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" >
                                                            <ul className="thali_menu_list">
                                                                <li>
                                                                    {items.portion ? items.portion : '-'}
                                                                </li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" >
                                                            <ul className="thali_menu_list">
                                                                <li>
                                                                    {items.user_count ? items.user_count : '-'}
                                                                </li>
                                                            </ul>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            );
                                        })}
                                    </table>

                                    {/* Pagination */}
                                    {/* <nav>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={handleFirstPage}>
                                                    First
                                                </button>
                                            </li>

                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                    Previous
                                                </button>
                                            </li>

                                            {getPageNumbers().map((pageNumber) => (
                                                <li
                                                    className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                                    key={pageNumber}
                                                >
                                                    <button className="page-link" onClick={() => handlePageChange(pageNumber)}>
                                                        {pageNumber}
                                                    </button>
                                                </li>
                                            ))}

                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link pagebutton" onClick={() => handlePageChange(currentPage + 1)}>
                                                    Next
                                                </button>
                                            </li>

                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={handleLastPage}>
                                                    Last
                                                </button>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>

                                <nav>
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={handleFirstPage}>
                                                First
                                            </button>
                                        </li>
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Previous
                                            </button>
                                        </li>

                                        {getPageNumbers().map((pageNumber) => (
                                            <li
                                                className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                                                key={pageNumber}
                                            >
                                                <button
                                                    className="page-link pagebutton"
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </button>
                                            </li>
                                        ))}

                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Next
                                            </button>
                                        </li>
                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button className="page-link" onClick={handleLastPage}>
                                                Last
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
