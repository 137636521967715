import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import { Inventorylist, api_url, Inventory_list, procurment_url, fmb_schedule_url } from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ven from "../../../img/ven.png";
import { CSVLink } from 'react-csv';
import Modal from "react-modal";
import fmblogo from "../../../img/fmb-logo.png";
import editicon from "../../../img/editicon.png";
import user_icon from "../../../img/Icon/user_icon.svg";
import email_icon from "../../../img/Icon/email.svg";
import phone_icon from "../../../img/Icon/phone.svg";
import loadergif from '../../../img/landing/loader123.gif';

export default function InventoryPage() {
    const user_Role = localStorage.getItem("access_Role");
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      };
    var token = localStorage.getItem("access_token");
    const location = useLocation();
    const dispatch = useDispatch();
    const allInventory = location?.state?.Inventorylist || [];
    const inventoryrequestid = location?.state?.inventoryrequestid || [];
    const allstatus = location?.state?.statusall || [];
    const allingredientcountall = location?.state?.ingredientcountall || [];
    const thaaliRotiData = location?.state?.thaaliRotiData || [];
    const current_week_ids = location?.state?.current_week_ids || [];
    const [selectedCheckbox, setSelectedCheckbox] = useState(current_week_ids);
    const [selectedStringCheckbox, setSelectedStringCheckbox] = useState(current_week_ids);

    const [Inventorydata, setInventoryData] = useState(allInventory);
    const [IngredientsDate, setIngredientsDate] = useState('');
    const [standardinventory, setStandardInventory] = useState("");
    const [requestid, setRequestId] = useState(inventoryrequestid);
    const [standardstatus, setStandardStatus] = useState(allstatus);
    const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
    const [RefreshIsOpen, setIsRefreshOpen] = React.useState(false);

    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [Ingredientslist, setIngredientsList] = useState([]);
    const access_id = localStorage.getItem("access_id");


    const [allingredientcount, setAllIngredientCount] = useState(allingredientcountall);
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = date.getDate();
    //     const month = date.toLocaleString("default", { month: "short" });
    //     return `${day} ${month}`;
    // };


    /**** */


    const today = new Date();
    today.setHours(0,0,0,0)
    const prevWeekStart = new Date(today);
    prevWeekStart.setDate(today.getDate() - 7 - today.getDay());
    const prevWeekEnd = new Date(prevWeekStart);
    prevWeekEnd.setDate(prevWeekEnd.getDate() + 6);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    const CurrentendOfWeek = new Date(today);
    CurrentendOfWeek.setDate(today.getDate() + (7 - today.getDay()));
    const nextOfWeek = new Date(today);
    const NextendOfWeek = new Date(today);
    nextOfWeek.setDate(today.getDate() + 7 - today.getDay()); // Set to the first day (Sunday) of the next week
    NextendOfWeek.setDate(today.getDate() + 13 - today.getDay());
  
    const [prevWeekStartdate, setPrevWeekStartDate] = useState(
      prevWeekStart.toISOString().split("T")[0]
    );
    const [prevWeekenddate, setPrevWeekEndDate] = useState(
      prevWeekEnd.toISOString().split("T")[0]
    );
    const [startweek, setStartWeek] = useState(
      startOfWeek.toISOString().split("T")[0]
    );
    const [CurrentendDate, setCurrentEndDate] = useState(
      CurrentendOfWeek.toISOString().split("T")[0]
    );
    const [nextstartDate, setNextStartDate] = useState(
      nextOfWeek.toISOString().split("T")[0]
    );
    const [nextendDate, setNextEndDate] = useState(
      NextendOfWeek.toISOString().split("T")[0]
    );

    const [selectAll, setSelectAll] = useState(false);
    const [startDate, setStartDate] = useState(startOfWeek);
    const [endDate, setEndDate] = useState(CurrentendOfWeek);
    const [procurmentdata, setProcurmentListData] = useState([]);
    const [selectedFMBCheckbox, setSelectedFMBCheckbox] = useState([]);
    const [selectedMiqaatCheckbox, setSelectedMiqaatCheckbox] = useState([]);
    const [selectedTotalFullCount, setSelectedTotalFullCount] = useState([]);
    const [selectedTotalMiqaatThaalCount, setSelectedTotalMiqaatThaalCount] = useState([]);
    const [activeTab, setActiveTab] = useState([]);
    const [weekStartDate, setWeekStartDate] = useState(startweek);
    const [weekEndDate, setWeekEndDate] = useState(CurrentendDate);
    const [totalselectedFullThaaliCount, setTotalSelectedFullThaaliCount] = useState(0);
    const [totalselectedMiqaatThaalCount, setTotalSelectedMiqaatThaalCount] = useState(0);

    const updateTotalFullThaaliCount = (procurmentdata) => {
        const totalselectedFullThaaliCount = procurmentdata.reduce(
          (sum, data) => sum + data.total_full_thaali,
          0,
        );
        //Sum
        setTotalSelectedFullThaaliCount (totalselectedFullThaaliCount);
      };
    
      const updateTotalMiqaatThaalCount = (procurmentdata) => {
        const totalselectedMiqaatThaalCount = procurmentdata.reduce(
          (sum, data) => sum + data.thaal_count,
          0,
        );
        //Sum
        setTotalSelectedMiqaatThaalCount (totalselectedMiqaatThaalCount);
      };

    function convertDateForIos(date) {
        var arr = date?.split(/[- :]/);
        date = new Date(arr[2], arr[0]-1, arr[1]);
        return date;
    }


    // console.log ("selectedCheckbox", selectedCheckbox)
    // console.log ("current_week_ids", current_week_ids)


  const getdatechange = (requestBody) => {
    setLoading(true); // Set loading to true at the beginning

    fetch(procurment_url + "admin/procurment-combined-list-date", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          setProcurmentListData(data?.data);
          updateTotalFullThaaliCount(data?.data);
          updateTotalMiqaatThaalCount(data?.data);
          setIsOpen(true);

        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };


    const handleCheckboxChange = (event, schedule_id, total_full_thaali, thaal_count, schedule_type) => {
        setLoading (true);
        const isChecked = event.target.checked;
        console.log ("handleCheckboxChange", event, schedule_id, total_full_thaali, thaal_count, schedule_type)
        let fmbScheduleIds = schedule_type == "FMBTHALI" ? [schedule_id] : []
        let miqaatScheduleIds = schedule_type == "MIQAAT" ? [schedule_id] : []

        console.log ("fmbScheduleIds", fmbScheduleIds)
        console.log ("miqaatScheduleIds", miqaatScheduleIds)
        console.log ("isChecked", isChecked)

        let schedule_id_string = schedule_id.toString()


        setSelectedCheckbox((prevCheckboxes) => {
          if (isChecked) {
            return [...prevCheckboxes, schedule_id];
          } else {
            return prevCheckboxes.filter((item) => item !== schedule_id);
          }
        });

        setSelectedStringCheckbox((prevCheckboxes) => {
            if (isChecked) {
              return [...prevCheckboxes, schedule_id_string];
            } else {
              return prevCheckboxes.filter((item) => item !== schedule_id_string);
            }
          });
    
        switch(schedule_type){
          case "FMBTHALI":
            setSelectedFMBCheckbox((prevCheckboxes) => {
              if (isChecked) {
                return [...prevCheckboxes, schedule_id];
              } else {
                return prevCheckboxes.filter((item) => item !== schedule_id);
              }
            });
            break;
          case "MIQAAT":
            setSelectedMiqaatCheckbox((prevCheckboxes) => {
              if (isChecked) {
                return [...prevCheckboxes, schedule_id];
              } else {
                return prevCheckboxes.filter((item) => item !== schedule_id);
              }
            });
            break;
        }
    
        // setSelectedTotalFullCount((prevCheckboxes) => {
        //   if (isChecked) {
        //     return [...prevCheckboxes, total_full_thaali];
        //   } else {
        //     return prevCheckboxes.filter((item) => item !== total_full_thaali);
        //   }
        // });
    
        // setSelectedTotalMiqaatThaalCount((prevCheckboxes) => {
        //   if (isChecked) {
        //     return [...prevCheckboxes, thaal_count];
        //   } else {
        //     return prevCheckboxes.filter((item) => item !== thaal_count);
        //   }
        // });
        

        if (isChecked) {
            setTimeout(() => {
                getselectdatachange(fmbScheduleIds, miqaatScheduleIds, "YES")
            }, 2000);
        } else{
            setTimeout(() => {
                getselectdatachange(fmbScheduleIds, miqaatScheduleIds, "NO")
            }, 2000);
        }

      };

    //   function partition(array, filter) {
    //     let fmbSelectedID = [], miqaatSelectedId = [];
    //     array.forEach((e, idx, arr) => (filter(e, idx, arr) ? fmbSelectedID : miqaatSelectedId).push(e));
    //     return [fmbSelectedID, miqaatSelectedId];
    //   }

    //   const handleSelectAllChange = (event) => {
    //     const checked = event.target.checked;
    //     setSelectAll(checked);
    //     if (checked) {
    //       // const allScheduleIds = procurmentdata.map((item) => item.schedule_id);
    //       const [fmbSelectedID, miqaatSelectedId] = partition(procurmentdata, e => e.schedule_type == 'FMBTHALI');
    
    //       const allScheduleIds = procurmentdata.map((item) => item.schedule_id);
    //       const allFMBScheduleIds = fmbSelectedID.map((item) => item.schedule_id);
    //       const allMiqaatScheduleIds = miqaatSelectedId.map((item) => item.schedule_id);
    
    //       setSelectedCheckbox(allScheduleIds);
    //       setSelectedFMBCheckbox(allFMBScheduleIds);
    //       setSelectedMiqaatCheckbox(allMiqaatScheduleIds);

    //       setTimeout(() => {
    //         getselectdatachange(allFMBScheduleIds, allMiqaatScheduleIds)
    //     }, 2000);

    
    //     } else {
    //       setSelectedCheckbox([]);
    //       setSelectedFMBCheckbox([]);
    //       setSelectedMiqaatCheckbox([]);
    //     }
    
    //     if (checked) {
    //       const allTotalFullCount = procurmentdata.map((item) => item.total_full_thaali);
    //       setSelectedTotalFullCount(allTotalFullCount);
    //       const allTotalMiqaatThaalCount = procurmentdata.map((item) => item.thaal_count);
    //       setSelectedTotalMiqaatThaalCount(allTotalMiqaatThaalCount);
          
    //     } else {
    //       setSelectedTotalFullCount([]);
    //       setSelectedTotalMiqaatThaalCount([]);
    //     }    
    
    //   };

      const handleDateCURRENT = (event) => {
        const requestBody = {
          start_date: startweek,
          end_date: CurrentendDate,
        };
        setEndDate(CurrentendDate);
        setStartDate(startweek);
    
        setWeekStartDate(startweek);
        setWeekEndDate(CurrentendDate);
    
        getdatechange(requestBody);
        setActiveTab("current");
      };

      const getThaalInputValue = (scheduleId) => {
        const selectedItem = procurmentdata.find(item => item.schedule_id === scheduleId);
        return selectedItem ? selectedItem.thaal_count : '';
    };

      const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    
    const [inputValues, setInputValues] = useState({}); // State to manage input values by schedule_id
    
    const handleInputChange = (e, id, displayedItems) => {
        const newCount = e.target.value;
        updateInputValue(id, newCount, displayedItems);
    };
    
    const getInputValue = (id, displayedItems) => {
        console.log ("id id", id)
        const selectedItem = displayedItems.find(item => item.ti_id === id);
        // return selectedItem ? selectedItem.current_quantity == 9898 ? 1 : selectedItem.current_quantity : '';
        return selectedItem ? selectedItem.current_quantity : '';

    };
    
    const updateInputValue = (id, value, displayedItems) => {
        const updatedItems = displayedItems.map(item => {
            if (item.ti_id === id) {
                return {
                    ...item,
                    current_quantity: value
                };
            }
            return item;
        });
        setInventoryData(updatedItems);
    };
    
    const handleTotalCurrentQuantityChange = (e, id, menu_quantity) => {
      const newTotalCount = e.target.value;
      setInputValues(prevState => ({
          ...prevState,
          [id]: newTotalCount,
      }));
    
      const requestBody = {
          id: id,
          current_quantity: newTotalCount,
          menu_quantity: menu_quantity
      };
    
      fetch(Inventory_list + 'admin/update-current-quantity', {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('access_token'),
          },
          body: JSON.stringify(requestBody),
      })
      .then(response => response.json())
      .then(data => {
          if (data?.status === true) {
              console.log('Data list ', data?.data);
              getInventory()
            //   setIsEdit (false)
              
              // Handle success
          } else {
              // Handle error
              console.log('error ', data);
          }
      })
      .catch(error => {
          console.error(error);
      });
    };
    

    const getselectdatachange = (fmbScheduleIds, miqaatScheduleIds, value) => {
        setLoading(true);
        let current_week_ids = [].concat(fmbScheduleIds, miqaatScheduleIds)
        const requestBody = {
          schedule_id: fmbScheduleIds,
          schedule_miqaat_id: miqaatScheduleIds,
          by_day: "false",
          end_date: weekEndDate,
          start_date: weekStartDate,
          curr_end_date: CurrentendDate,
          skip_remaining_days: "1",
        };
    
        fetch(Inventory_list + "admin/inventory-combined-aggregate", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Assuming you're sending form data
            Authorization: localStorage.getItem("access_token"),
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status == true) {
               setIngredientsList(data?.data);
               setTimeout(() => {
                UpdateInventory(data?.data, current_week_ids, value)
              }, 2000);
            } else {
              toast.error("Select Date");
              setLoading(false);
              console.log("error ", data);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          })
      };

      const UpdateInventory = (IngredientsDatalist, current_week_ids, value) => {
        setLoading(true);
        console.log ("UpdateInventory value", value)
        const allIngredients = IngredientsDatalist?.schedule?.map((item) => item.id);

        const menuQuantity = IngredientsDatalist?.schedule?.map(
          (item) => item.total_quantity
        );

        const ingredients = allIngredients.map((ingredient_id, index) => ({
          menu_quantity: menuQuantity[index]?.toFixed(2),
          ingredient_id: ingredient_id,
        }));

        const requestBody = {
          thaali_inventory_req_id: inventoryrequestid,
          ingredients: ingredients,
          current_week_ids, current_week_ids
        };

        let requestURL =
        value == "YES"
        ? Inventory_list + "admin/inventory-update-add"
        : Inventory_list + "admin/inventory-update-remove";
    
        console.log("requestBody", requestBody);
        fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Assuming you're sending form data
            Authorization: localStorage.getItem("access_token"),
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === true) {
                setLoading(false);
                toast.success(data?.message);
                getInventory();
            } else {
              // toast.error("Select Date");
              setLoading(false);
              console.log("error ", data);
            }
          })
          .catch((error) => {
            setLoading(false); // Set loading to false when data fetching is complete
            console.error(error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false when data fetching is complete
          });
      };

      const getInventory = () => {
        setLoading(true);
        const requestBody = {
            thaali_inventory_req_id: inventoryrequestid
        };
        fetch(Inventory_list + 'admin/inventory', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json", // Assuming you're sending form data
                 Authorization: localStorage.getItem("access_token"),
            },
            body: JSON.stringify(requestBody),
           })
            .then(response => response.json())
            .then(data => {
                if (data?.status === true) {
                    setInventoryData(data?.data);
                    console.log ("currentWeekIds", data?.data[0]?.current_week_ids)
                    let str = data?.data[0]?.current_week_ids.replace(/\s*,\s*/g, ",");
                    const currentStringWeekIds = str.length > 0 ? str.split(',') : [str]
                    setSelectedStringCheckbox(currentStringWeekIds)
                }else{
                    setLoading(false);
                    console.log("error ", data);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when data fetching is complete
              });
    };


    /**** */

    function RefreshModal() {
        setIsRefreshOpen(true)
    }

    function RefreshOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function RefreshCloseModal() {
        setIsRefreshOpen(false);
    }

    function handleRefreshData() {
    // Call Procurement
    setIsRefreshOpen(false);

    const requestBody = {
        start_date: Inventorydata[0].start_date.split("T")[0],
        end_date: Inventorydata[0].end_date.split("T")[0],
      };
  
      getRefreshDateChange(requestBody);


    // Call View Ingredients

    // Call Update Ingredients

    }


    const getRefreshDateChange = (requestBody) => {
        setLoading(true); // Set loading to true at the beginning
    
        fetch(procurment_url + "admin/procurment-combined-list-date", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Assuming you're sending form data
            Authorization: localStorage.getItem("access_token"),
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === true) {
                handleRefreshViewIngredients(data?.data);    
            } else {
              setLoading(false); // Set loading to false when data fetching is complete
              toast.error(data?.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setLoading(false); // Set loading to false when data fetching is complete
          })
          .finally(() => {
            // setLoading(false); // Set loading to false when data fetching is complete
          });
      };

    
      function partition(array, filter) {
        let fmbSelectedID = [], miqaatSelectedId = [];
        array.forEach((e, idx, arr) => (filter(e, idx, arr) ? fmbSelectedID : miqaatSelectedId).push(e));
        return [fmbSelectedID, miqaatSelectedId];
      }

      const  handleRefreshViewIngredients = (procurmentdata) => {

        const [fmbSelectedID, miqaatSelectedId] = partition(procurmentdata, e => e.schedule_type == 'FMBTHALI');
        const allFMBScheduleIds = fmbSelectedID.map((item) => item.schedule_id);
        const allMiqaatScheduleIds = miqaatSelectedId.map((item) => item.schedule_id);

        getRefreshSelectDataChange (allFMBScheduleIds, allMiqaatScheduleIds)

     }

      const getRefreshSelectDataChange = (fmbScheduleIds, miqaatScheduleIds) => {
        setLoading(true);
        const requestBody = {
          schedule_id: fmbScheduleIds,
          schedule_miqaat_id: miqaatScheduleIds,
          by_day: "false",
          end_date: Inventorydata[0].end_date.split("T")[0],
          start_date: Inventorydata[0].start_date.split("T")[0],
          curr_end_date: CurrentendDate,
          skip_remaining_days: "1",
          standard: "1"
        };
    
        fetch(Inventory_list + "admin/inventory-combined-aggregate", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Assuming you're sending form data
            Authorization: localStorage.getItem("access_token"),
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status == true) {
               setTimeout(() => {
                UpdateRefreshInventory(data?.data)
              }, 2000);
            } else {
              toast.error("Refresh Data Failed");
              setLoading(false);
              console.log("error ", data);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          })
      };


      const UpdateRefreshInventory = (IngredientsDatalist) => {
        setLoading(true);
        const allIngredients = IngredientsDatalist?.schedule?.map((item) => item.id);

        const menuQuantity = IngredientsDatalist?.schedule?.map(
          (item) => item.total_quantity
        );

        const ingredients = allIngredients.map((ingredient_id, index) => ({
          menu_quantity: menuQuantity[index]?.toFixed(2),
          ingredient_id: ingredient_id,
        }));

        const requestBody = {
          thaali_inventory_req_id: inventoryrequestid,
          ingredients: ingredients
        };

        let requestURL = Inventory_list + "admin/inventory-refresh"
    
        console.log("requestBody", requestBody);
        fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Assuming you're sending form data
            Authorization: localStorage.getItem("access_token"),
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === true) {
                setLoading(false);
                toast.success(data?.message);
                getInventory();
            } else {
              setLoading(false);
              console.log("error ", data);
            }
          })
          .catch((error) => {
            setLoading(false); // Set loading to false when data fetching is complete
            console.error(error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false when data fetching is complete
          });
      };
    

    function RemoveOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function RemoveModal() {
        standardstatus === "Pending" && setIsRemoveOpen(true)
    }
    
    function RemovecloseModal() {
        setIsRemoveOpen(false);
    }

    var dateName2 = function (yyyy, mm, dd, full) {
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dayNames = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        var today = new Date(yyyy, mm - 1, dd);
        var dd = today.getDate();
        var dayNameIndex = (today.getDay() - 1) == -1 ? 6 : today.getDay() - 1
        var dayname = dayNames[dayNameIndex];
        var mm1 = monthNames[today.getMonth()];
        var yyyy1 = today.getFullYear();
        var fullDate = full === "true" ? mm + "/" + dd + "/" + yyyy :dd + "_" + mm1;    
        return fullDate;
      };
    
      const formatDate2 = (dateString, full) => {
        if (!dateString) {
          return ""; // return empty string if dateString is undefined or empty
        }
        const formattedCurrentDate = dateString && dateString?.split("T")[0]; // Convert date to ISO string
        const dayInformation = dateName2(formattedCurrentDate?.split("-")[0], formattedCurrentDate?.split("-")[1], formattedCurrentDate?.split("-")[2], full)
        return dayInformation;
      };


    var dateName = function (yyyy, mm, dd, full) {
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dayNames = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        var today = new Date(yyyy, mm - 1, dd);
       var dd = today.getDate();
        var dayNameIndex = (today.getDay() - 1) == -1 ? 6 : today.getDay() - 1
        var dayname = dayNames[dayNameIndex];
        var mm1 = monthNames[today.getMonth()];
        var yyyy1 = today.getFullYear();
        var fullDate = full === "true" ? mm + "/" + dd + "/" + yyyy :dd + " " + mm1;    
        return fullDate;
      };
    
      const formatDate = (dateString, full) => {
        if (!dateString) {
          return ""; // return empty string if dateString is undefined or empty
        }
        const formattedCurrentDate = dateString && dateString?.split("T")[0]; // Convert date to ISO string
        const dayInformation = dateName(formattedCurrentDate?.split("-")[0], formattedCurrentDate?.split("-")[1], formattedCurrentDate?.split("-")[2], full)
        return dayInformation;
      };

    useEffect(() => {
        getInventory()
    }, []);

    let formattedStartDate = "";
    let formattedEndDate = "";

    let formattedStartDate2 = "";
    let formattedEndDate2 = "";

    if (Inventorydata && Inventorydata?.length > 0) {
        if (Inventorydata[0].start_date) {
            formattedStartDate = formatDate(Inventorydata[0].start_date);
            formattedStartDate2 = formatDate2(Inventorydata[0].start_date);

        }

        if (Inventorydata[0].end_date) {
            formattedEndDate = formatDate(Inventorydata[0].end_date);
            formattedEndDate2 = formatDate2(Inventorydata[0].end_date);
        }
    }

    const [sortColumn, setSortColumn] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (columnName) => {
        if (sortColumn === columnName) {
            setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(columnName);
            setSortOrder('asc');
        }
    };
    const getSortIcon = (columnName) => {
        if (sortColumn === columnName) {
            return sortOrder === 'asc' ? <i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>;
        }
        return null;
    };


    const sortInventorydata = [...Inventorydata].sort((a, b) => {
        if (sortColumn === 'Name') {
            const nameA = a?.ingredient_name.toLowerCase();
            const nameB = b?.ingredient_name.toLowerCase();
            if (nameA < nameB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (nameA > nameB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        // Handle sorting for other columns if needed
        return 0;
    });

    const groupedData = {};

    sortInventorydata.forEach((items) => {
        // if(items.order_quantity !== "0"){
            const vendorName1 = items.vendor_name1;
            if (!groupedData[vendorName1]) {
              groupedData[vendorName1] = [];
            }
            groupedData[vendorName1].push({
              "Ingredient Name": items.ingredient_name,
              "Order Quantity": items.order_quantity > 0 ? items.order_quantity : 0,
              "Unit": items.package_unit,
              "Package Size": items.package_size,
              "Package Type": items.package_type,
              "Notes": items.ingredient_note !== "NULL" ? items.ingredient_note  : "",
            });
        // }
    });
  
    // Initialize an array for the CSV data
    const csvDataArray = [];
    // Iterate over the grouped data and format it as an array of objects
    Object.keys(groupedData).forEach((vendorName) => {
      // Add group label
      csvDataArray.push({ "Group Label": vendorName });
        groupedData[vendorName].forEach((item) => {
        csvDataArray.push(item);
      });

    });    

    csvDataArray.push({
        "Group Label": " "
    });

    csvDataArray.push({
        "Group Label": " "
    });

    csvDataArray.push({
        "Group Label": "Zabihat By Day"
    });

    csvDataArray.push({
        "Group Label": 'Schedule Day',
        "Ingredient Name": 'Dish Name',
        "Order Quantity" : 'Quantity ',
        "Unit": 'Unit'
    })

    thaaliRotiData.forEach((items) => {    
        if (items.ingredient_name.includes("Gosh") ){
            csvDataArray.push({
                "Group Label": items.schedule_day,
                "Ingredient Name":  items.dish_name,
                "Order Quantity": items.total_quantity ? items.total_quantity : items.total_miqaat_quantity,
                "Unit":'Lbs'
              });
        }
      });
    
    csvDataArray.push({
        "Group Label": " "
    });

    csvDataArray.push({
        "Group Label": "Roti or Manda"
    });

    csvDataArray.push({
        "Group Label": 'Schedule Day',
        "Ingredient Name": 'Dish Name',
        "Order Quantity":'Roti Packet 8 Count ',
        "Unit":'Roti Packet 4 Count ',
        "Package Size": 'Roti Packet 2 Count ',
        "Package Type": 'Total Count',
    });

    thaaliRotiData.forEach((items) => {   
        if (items.ingredient_name.includes("Roti") || items.ingredient_name.includes("Manda") ){ 
            csvDataArray.push({
                "Group Label": items.schedule_day,
                "Ingredient Name": items.dish_name,
                "Order Quantity": items.roti_8_pk_count,
                "Unit": items.roti_4_pk_count,
                "Package Size": items.roti_2_pk_count,
                "Package Type": items.total_quantity          
            });
         }
      });

    const isActiveClass = standardstatus === "Pending" ? "Pending" : standardstatus === "Published" ? "Published" : standardstatus === "N/A" ? "nocolor" : "";


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = sortInventorydata.length; // Number of items to display per page
    const pageCount = Math.ceil(sortInventorydata?.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1) {
            setCurrentPage(1);
        } else if (pageNumber > pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    const handleFirstPage = () => {
        handlePageChange(1);
    };

    const handleLastPage = () => {
        handlePageChange(pageCount);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const displayedItems = sortInventorydata?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const PurchashingSheetCSVFilename =  "Ordering_Sheet_"+inventoryrequestid+"_"+formattedStartDate2+"_"+formattedEndDate2+".csv"


    /***** */

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
    function closeModal() {
      setIsOpen(false);
    }

    function afterOpenModal() {}

    const handleEdit = (e) => {
        const newValue = isEdit === "1" ? "" : "1";
        setIsEdit(newValue);
    };
    


    /**** */

    return (
        <div>
            <Header />
            <div
                className="side_div_menu"
                style={{ display: "flex", height: "100%" }}
            >
                <Sidebarmenu />
                <section className="main_section">
                    <div className="custam">
                        <div className="main_section2">
                            <div className="ExportInventory thali_section Thali_page">
                                <div className="container-fluid">
                                    <div className="thili_div">
                                        <div className="Back-page">
                                            <Link to="/ViewInventory">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                                                        fill="#777391"
                                                    />
                                                </svg>
                                            </Link>
                                            &nbsp; &nbsp;
                                            <h1 className="title_heading">
                                                Inventory Request: {inventoryrequestid} &nbsp; &nbsp; 
                                                <span className="Line_div">|</span>
                                                <span className="Date_list">{formattedStartDate} - {formattedEndDate}</span>
                                                <span className="Date_list"> Total: {allingredientcount ? allingredientcount :'-'}</span>  &nbsp; &nbsp;
                                                <span className={isActiveClass}>{standardstatus}</span>{" "}
                                            </h1>{" "}
                                        </div>
                                        { (user_Role.includes("ADM") || user_Role.includes("PRM") || user_Role.includes("PRO") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                                        <button className="btn thali_btn" onClick={RefreshModal}>
                                               Refresh Menu QTY
                                        </button>
                                        }
                                        { (user_Role.includes("ADM") || user_Role.includes("PRM") || user_Role.includes("PRO") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                                        <div className="Thali_page_btn d-flex">
                                            { standardstatus == "Published" &&
                                            <div className="Thali_page_btn AddStandardInventory">
                                                <label for="Standard" className="form-label">
                                                        Edit &nbsp; &nbsp;
                                                </label>
                                                <div className="onoffswitch">
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                                                        checked={isEdit == "1"}
                                                        onChange={handleEdit} />
                                                    <label className="onoffswitch-label" for="rice_check">
                                                        <span className="onoffswitch-inner"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                                &nbsp; &nbsp;
                                                &nbsp;{" "}

                                                &nbsp;
                                            </div>
                                            }
                                              <div>
                                                <div className={`Week_Mini_list`}
                                                    onClick={handleDateCURRENT}
                                                >
                                                    <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                        d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                        fill="#591333"
                                                        />
                                                    </svg>
                                                    </div>
                                                    <div className="week_text">
                                                    <h4> Add Current Week</h4>
                                                    <h1>
                                                        {formatDate(startweek)} - {formatDate(CurrentendDate)}
                                                    </h1>
                                                    </div>
                                                </div>
                                                </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            { standardstatus == "Pending" &&
                                                <button className="btn thali_btn" onClick={RemoveModal}>
                                                Export Ordering Sheet
                                                </button>
                                            }
                                            { standardstatus !== "Pending" &&
                                            <button className="btn thali_btn" onClick={RemoveModal}>
                                                <CSVLink data={csvDataArray}filename={PurchashingSheetCSVFilename}>
                                                     Export Ordering Sheet
                                                </CSVLink>
                                            </button>
                                            }
                                            &nbsp;
                                            {/* <button onClick={RemoveModal}>sss</button> */}
                                        </div>
                                    }
                                    </div>
                                </div>
                                {/* <div className="thali_count_list">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Caterer"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div> */}
                                {loading ? (
                                    // Render a loading indicator while data is being fetched
                                    <div className="Loading-img">
                                    <img src={loadergif} className="" />
                                    </div>
                                    ) : (
                                    <div></div>
                                )}
                                <div className="thali_table_section">

                                    {displayedItems?.length > 0 ? (
                                        <table className="table table-bordered table-responsive">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col" onClick={() => handleSort('Name')}>
                                                        <span className="thali_table">
                                                            Ingredient Name &nbsp; &nbsp; &nbsp;
                                                            {getSortIcon('Name')}
                                                        </span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Package Unit</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Package Size</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Package Type</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Vendor Name </span>
                                                    </th>

                                                    <th scope="col">
                                                        <span className="thali_table">ingredient note </span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Menu Quantity </span>
                                                    </th>

                                                    <th scope="col">
                                                        <span className="thali_table">Inventory Quantity </span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Order Quantity </span>
                                                    </th>
                                            </tr>
                                            </thead>
                                            {loading ? (
                                                    // Render a loading indicator while data is being fetched
                                                    <div className="Loading-img">
                                                    <img src={loadergif} className="" />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                            )}
                                            {displayedItems?.map((items) => {
                                                const isActiveClass = items.menu_quantity === "plenty" ? "plenty" : items.menu_quantity === "25" ? "new" : items.menu_quantity === "N/A" ? "nocolor" : "";
                                                return (
                                                    <tbody key={items.id}>

                                                        <tr>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>{items.ingredient_name ? items.ingredient_name : '-'}</li>
                                                                </ul>
                                                            </th>

                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>
                                                                        {items.package_unit ? items.package_unit : '-'}
                                                                    </li>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>{items.package_size ? items.package_size : '-'}</li>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>
                                                                        {items.package_type ? items.package_type : '-'}
                                                                    </li>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>

                                                                    {items.vendor_name1 !== "undefined" && items.vendor_name1 !== "null" &&  items.vendor_name1 !== "NULL" && items.vendor_name1 ? (<>
                                                                        {items.vendor_name1 ? items.vendor_name1 : "-"}
                                                                    </>
                                                                    ) : ''}

                                                                    </li>
                                                                    <li>
                                                                    {items.vendor_name2 !== "undefined" && items.vendor_name2 !== "null" &&  items.vendor_name2 !== "NULL" && items.vendor_name2 ? (<>
                                                                        {items.vendor_name2 ? items.vendor_name2 : "-"}
                                                                    </>
                                                                    ) : ''}
                                                                    </li>
                                                                    <li>
                                                                    {items.vendor_name3 !== "undefined" && items.vendor_name3 !== "null" &&  items.vendor_name2 !== "NULL" && items.vendor_name2 ? (<>                         
                                                                        {items.vendor_name3 ? items.vendor_name3 : "-"}
                                                                    </>
                                                                    ) : ''}
                                                                    </li>
                                                                </ul>
                                                            </th>

                                                            <th scope="row" >
                                                                <ul className="thali_menu_list Inventorydata">
                                                                    <li>
                                                                       {items.ingredient_note !== "NULL" ? items.ingredient_note : '-'} 
                                                                    </li>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list Inventorydata">
                                                                    <li>
                                                                        <span className={isActiveClass}> {items.menu_quantity && items.menu_quantity != 9898 ? items.menu_quantity : '-'} </span>
                                                                    </li>
                                                                </ul>
                                                            </th>

                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>
                                                                        {items.current_quantity != "N/A" && items.menu_quantity != 9898 ? items.current_quantity : '-'}
                                                                    </li>
                                                                </ul>
                                                                {(isEdit && items.vendor_name1 != "Standard") &&
                                                                <ul className="thali_menu_list">
                                                                    <li>
                                                                        <div className="Form_box Totalcount">
                                                                            
                                                                            <input
                                                                                type="Number"
                                                                                placeholder="0"
                                                                                className="form-control"
                                                                                id="inputcount"
                                                                                min="0"
                                                                                onKeyDown={preventMinus}
                                                                                onWheel={() => document.activeElement.blur()}
                                                                                onBlur={(e) => handleTotalCurrentQuantityChange(e, items.ti_id, items.menu_quantity)}
                                                                                onChange={(e) => handleInputChange(e, items.ti_id, displayedItems)}
                                                                                value={getInputValue(items.ti_id, displayedItems)}

                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                }
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <li>{items.order_quantity > 0 ? items.order_quantity : '-'}</li>
                                                                </ul>
                                                            </th>


                                                        </tr>
                                                    </tbody>
                                                );
                                            })}
                                        </table>
                                    )
                                        : (
                                            // Render when Inventorydata is empty
                                            <div className="No_Inventory">
                                                <h1 >No Inventory Data Available for Next Week </h1>
                                            </div>
                                        )}
                                    {/* Pagination */}
                                    {/* <nav>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={handleFirstPage}>
                                                    First
                                                </button>
                                            </li>

                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                    Previous
                                                </button>
                                            </li>

                                            {getPageNumbers().map((pageNumber) => (
                                                <li
                                                    className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                                    key={pageNumber}
                                                >
                                                    <button className="page-link pagebutton" onClick={() => handlePageChange(pageNumber)}>
                                                        {pageNumber}
                                                    </button>
                                                </li>
                                            ))}

                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                                    Next
                                                </button>
                                            </li>

                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={handleLastPage}>
                                                    Last
                                                </button>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>

                                <nav>
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={handleFirstPage}>
                                                First
                                            </button>
                                        </li>
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Previous
                                            </button>
                                        </li>

                                        {getPageNumbers().map((pageNumber) => (
                                            <li
                                                className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                                                key={pageNumber}
                                            >
                                                <button
                                                    className="page-link pagebutton"
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </button>
                                            </li>
                                        ))}

                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Next
                                            </button>
                                        </li>
                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button className="page-link" onClick={handleLastPage}>
                                                Last
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal
                isOpen={RemoveIsOpen}
                onAfterOpen={RemoveOpenModal}
                onRequestClose={RemovecloseModal}
                style={customStyles}
                contentLabel="Remove Modal"
                id="edit_inventory"
                >
                <div className="popup_modal exp_in">
                <div className="Remove_from">
                    {/* <img src={fmblogo} className="img-firud inv-img" /> */}
                    <div style={{ color: 'red', fontSize: 30, textAlign: "center" }}>Warning!</div>
                    &nbsp;
                    <h5>
                    This is a "Pending" Inventory {" "}
                    </h5>
                    <h5>
                    Do you still want to Export Ordering Sheet? {" "}
                    </h5>
                    <div className="Thali_page_btn_from">
                    <button onClick={RemovecloseModal} className="btn thali_btn">
                        NO
                    </button>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <button onClick={RemovecloseModal} className="btn thali_btn csvbtn">
                    <CSVLink data={csvDataArray}filename={PurchashingSheetCSVFilename}>
                          YES
                    </CSVLink>
                    </button>
                    </div>
                </div>
                </div>
            </Modal>
            <Modal
                isOpen={RefreshIsOpen}
                onAfterOpen={RefreshOpenModal}
                onRequestClose={RefreshCloseModal}
                style={customStyles}
                contentLabel="Refresh Modal"
                id="edit_inventory"
                >
                <div className="popup_modal exp_in">
                <div className="Remove_from">
                    {/* <img src={fmblogo} className="img-firud inv-img" /> */}
                    <div style={{ color: 'red', fontSize: 30, textAlign: "center" }}>Warning!</div>
                   
                    <h1>
                    Refreshing Quantity Data{" "}
                    </h1>
                    <h5>
                    This action cannot be un-done & will remove any Current Week Days Added.
                    </h5>
                    &nbsp;
                    <h5>
                    Do you still want to Continue? {" "}
                    </h5>
                    &nbsp;
                    <h1>
                    NOTE: Re-Add Current Week Days if added already. {" "}
                    </h1>
                    <div className="Thali_page_btn_from">
                    <button onClick={RefreshCloseModal} className="btn thali_btn">
                        NO
                    </button>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <button onClick={handleRefreshData} className="btn thali_btn csvbtn">      
                        YES
                    </button>
                    </div>
                </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                            <div className="popup_modal">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="Popup_heding">
                                </div>
                                <button onClick={closeModal}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 20"
                                    fill="none"
                                >
                                    <path
                                    d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                                    fill="#2E294E"
                                    />
                                </svg>
                                </button>
                            </div>
                            </div>
                            <div>
                            {loading ? (
                                    // Render a loading indicator while data is being fetched
                                    <div className="Loading-img">
                                    <img src={loadergif} className="" />
                                    </div>
                                    ) : (
                                    <div></div>
                                )}
                            {procurmentdata.length > 0 ? (    
                                <table className="table table-bordered  Procurementtabel table-responsive">
                                <thead className="thead-dark">
                                    <tr>
                                    <th scope="col">
                                        <span className="thali_table">
                                        {/* All &nbsp; &nbsp;
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                            selectedCheckbox.length ===
                                            procurmentdata.length
                                          }
                                        onChange={handleSelectAllChange}
                                         /> */}
                                    </span>
                                    </th>
                                    <th scope="col">
                                        <span className="thali_inv_table">Day & Date</span>
                                    </th>
                                    <th scope="col">
                                        <span className="thali_inv_table">Menu</span>
                                    </th>
                                    {/* <th scope="col">
                                        <span className="thali_table">Caterer</span>
                                    </th> */}
                                    {/* <th scope="col">
                                        <span className="thali_table">1-2</span>
                                    </th> */}
                                    {/* <th scope="col">
                                    <span className="thali_table">3-4</span>
                                    </th> */}
                                    {/* <th scope="col">
                                        <span className="thali_table">5-6</span>
                                    </th> */}
                                    {/* <th scope="col">
                                        <span className="thali_table">X-S</span>
                                    </th> */}
                                    <th scope="col">
                                        <span className="thali_inv_table">
                                        # Full
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span className="thali_inv_table">Remarks</span>
                                    </th>
                                    <th scope="col">
                                        <span className="thali_inv_table">#</span>
                                    </th>
                                    {/* <th scope="col"><span className='thali_table'>Current Qty/Unit</span></th>
                                            <th scope="col"><span className='thali_table'>Order PKG COUNT/TYPE</span></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {procurmentdata &&
                                procurmentdata?.map((item) => {
                                // const date = new Date(item.schedule_day);
                                const date = item.schedule_day && convertDateForIos(item.schedule_day);

                                const day = date.toLocaleString("en-US", {
                                weekday: "short",
                                });
                                const month = date.toLocaleString("en-US", {
                                month: "short",
                                });
                                const numericDate = date.getDate();
                                const twoDigitYear = date.getFullYear();
                                return (
                                <tr key={item.schedule_id}>
                                    <th scope="row">
                                    <ul className="thali_menu_list">
                                        {" "}
                                        <li>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedStringCheckbox.includes(
                                            item.schedule_id.toString()
                                            )}
                                            onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                item.schedule_id,
                                                item.total_full_thaali,
                                                item.thaal_count,
                                                item.schedule_type
                                            )
                                            }
                                        />
                                        </li>
                                    </ul>
                                    </th>
                                    <th scope="row">
                                    <ul className="thali_menu_list">
                                        {/* <img src={userprofilelist} className="img-firud" /> */}
                                        <li>
                                        <div className="user_Inv_Calendar_card">
                                            <h6>{day}</h6>
                                            <h1>{numericDate}</h1>
                                            <h5>
                                            {month}, {twoDigitYear}
                                            </h5>
                                        </div>
                                        </li>
                                    </ul>
                                    </th>
                                    <th>
                                    <ul className="inv_user_menu_list">
                                    {item.schedule_type == "MIQAAT" && (
                                        <span className="Draft_MIQAAT_event">
                                            {item.schedule_type == "MIQAAT" ? "MIQAAT" : ""}
                                        </span>
                                        )}
                                        {item.menu &&
                                        JSON.parse(item.menu).map(
                                            (menu, index) => (
                                            <li key={index}>
                                                {menu ? menu : "NA"}
                                            </li>
                                            )
                                        )}
                                    </ul>
                                    </th>
                                    {/* <th scope="row">
                                    {item.jamanType == "COOKING" &&
                                    <ul className="thalireg_user_menu_list">
                                        {item.cater_name1 &&
                                        item.cater_name1 !== "null" && (
                                            <>
                                            <li>
                                            &nbsp; &nbsp;
                                                <img
                                                src={user_icon}
                                                className="img-fluid"
                                                />
                                                &nbsp; &nbsp;
                                                {item.cater_name1
                                                ? item.cater_name1
                                                : "NA"}
                                            </li>
                                            </>
                                        )}
                                        {item.cater1_email &&
                                        item.cater1_email !== "null" && (
                                            <>
                                            <li>
                                            &nbsp;
                                                <img
                                                src={email_icon}
                                                className="img-fluid"
                                                width="22px"
                                                height="22px"
                                                />
                                                &nbsp; 
                                                {item.cater1_email
                                                ? item.cater1_email
                                                : "NA"}
                                            </li>
                                            </>
                                        )}
                                        {item.cater1_phone_number &&
                                        item.cater1_phone_number !== "null" && (
                                            <>
                                            <li>
                                            &nbsp; &nbsp;
                                                <img
                                                src={phone_icon}
                                                className="img-fluid"
                                                width="18px"
                                                height="18px"
                                                />
                                                &nbsp;
                                                {item.cater1_phone_number
                                                ? item.cater1_phone_number
                                                : "NA"}
                                            </li>
                                            </>
                                        )}
                                    </ul>
                                    }
                                    {item.jamanType == "ORDERING" &&
                                        <ul className="thalireg_user_menu_list">
                                            <li>
                                            {item.jamanType == "ORDERING" && (
                                            <span className="Draft_ordering_event">
                                            {item.jamanType == "ORDERING" ? "ORDERING" : ""}
                                            </span>
                                        )}
                                            </li>
                                            <>
                                            <li>
                                                <img
                                                src={ven}
                                                className="img-fluid"
                                                width="16px"
                                                height="16px"
                                                />
                                                &nbsp; &nbsp; 
                                                {item.order_by
                                                ? item.order_by
                                                : "-"}
                                            </li>
                                            </>
                                        </ul>
                                    }
                                    </th> */}
                                    {/* <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                        <li>
                                        {item.one_two_THAALIS
                                            ? item.one_two_THAALIS
                                            : "-"}
                                        </li>
                                    </ul>
                                    </th> */}
                                    {/* <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                        <li>
                                        {item.three_four_THAALIS
                                            ? item.three_four_THAALIS
                                            : "-"}
                                        </li>
                                    </ul>
                                    </th> */}
                                    {/* <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                        <li>
                                        {item.five_six_THAALIS
                                            ? item.five_six_THAALIS
                                            : "-"}
                                        </li>
                                    </ul>
                                    </th> */}
                                    {/* <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                        <li>
                                        {" "}
                                        {item.x_small ? item.x_small : "-"}{" "}
                                        </li>
                                    </ul>
                                    </th> */}
                                    <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                        <li>
                                        {" "}
                                        {item.total_full_thaali
                                            ? item.total_full_thaali
                                            : "-"}{" "}
                                        </li>
                                    </ul>
                                    </th>
                                    <th scope="row">
                                        <ul className="thalireg_user_menu_list">
                                            <li>{item.schedule_type === 'MIQAAT' && item.notes ? item.notes : '-'}</li>
                                        </ul>
                                    </th>
                                    {item.schedule_type === 'FMBTHALI'
                                    ?
                                    <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                    <li>
                                        {item.total_count
                                        ? item.total_count
                                        : "-"}
                                    </li>
                                    </ul>
                                </th>
                                    : 
                                    <th scope="row">
                                    <ul className="thalireg_user_menu_list">
                                        <li>
                                            {/* <div className="Form_box Totalcount">
                                                <input
                                                    type="Number"
                                                    placeholder="Count"
                                                    className="form-control"
                                                    id="inputcount"
                                                    min="0"
                                                    onKeyDown={preventMinus}
                                                    onWheel={() => document.activeElement.blur()}
                                                    onBlur={(e) => handleTotalCountChange(e, item.schedule_id)}
                                                    onChange={(e) => handleInputChange(e, item.schedule_id)}
                                                    value={getInputValue(item.schedule_id)}

                                                />

                                            </div> */}
                                            {getThaalInputValue(item.schedule_id)}
                                        </li>
                                    </ul>
                                </th>
                                }
                                </tr>
                                );
                            })}
                        </tbody>
                        </table>
                    ) : (
                        // Render when Inventorydata is empty
                        <div>

                        </div>
                    )}
                    </div>
                        {/* <div className="Thali_page_btn_from">
                        &nbsp; &nbsp; &nbsp;
                        <button className="btn thali_btn" onClick={closeModal}>
                            Done
                        </button>
                        </div> */}
                </Modal>
            </div>
    )
}
