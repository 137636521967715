import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import "./Components/Commonstyle.css";
import Login from "./Components/Login/Login";
import Signin from "./Components/Signin/Signin";
import Dashboard from "./Components/Dashboard/Dashboard";
// import ThaliSchedule from "./Components/Dashboard/Page/ThaliSchedule";
import { ToastContainer, toast } from "react-toastify";
import ExportInventory from "./Components/Dashboard/ProcurementFmb/ExportInventory";
import ThaliPage from "./Components/Dashboard/DashboardPage/ThaliPage";
import TotalIngredients from "./Components/Dashboard/DashboardPage/TotalIngredients";
import Dishes from "./Components/Dashboard/Dishespage/Dishes";
import Ingredients from "./Components/Dashboard/Ingredientspage/Ingredients";
import Vendors from "./Components/Dashboard/VendorsPage/Vendors";
import Users from "./Components/Dashboard/UsersPage/Users";
import Feedback from "./Components/Dashboard/Feedback/Feedback";
import ScheduleFeedback from "./Components/Dashboard/Feedback/ScheduleFeedback";
import CalendarPage from "./Components/Dashboard/CalendarPage/CalendarPage";
import Landingpage from "./Components/Landingpage/Landingpage";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Registeruser from "./Components/Dashboard/Registeruser/Registeruser";
import Procurement from "./Components/Dashboard/ProcurementFmb/Procurement";
import ProcurementMiqaat from "./Components/Dashboard/ProcurementMiqaat/ProcurementMiqaat"
import ProcurementCombined from "./Components/Dashboard/ProcurementCombined/ProcurementCombined"

import Miqaatinventory from "./Components/Dashboard/ProcurementMiqaat/Miqaatinventory";
import ExportInventoryCombined from "./Components/Dashboard/ProcurementCombined/ExportInventoryCombined";

import Caterer from "./Components/Dashboard/Caterer/Caterer";
import ViewInventory from './Components/Dashboard/InventoryPage/ViewInventory';
import InventoryPage from './Components/Dashboard/InventoryPage/InventoryPage';
import Report from "./Components/Dashboard/Report/Report";
import  Thalireg from './Components/Dashboard/ThaaliRegisteration/ThaaliRegisteration'

function App() {
  const [venaqrr, setVenaqrr] = useState([])
  const history = useNavigate();
  // useEffect(() => {
  //   if (!localStorage.getItem("access_token")) {
  //     history("/");
  //   }
  // }, [])

  // useEffect(() => {
  //   const access_token = localStorage.getItem("access_token");
  
  //   if (!access_token) {
  //     history("/");
  //   }
  // }, []);
  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
  
    if (!access_token || access_token === "null") {
      history("/");
    }
  }, [history]);

  
  // useEffect(() => {  
  //   return () => {
  //     localStorage.clear("access_token")
  //   }
  // },  [])
  const getvenderlist = (data) => {
    setVenaqrr(data)
  }
  return (
    <>

      <div>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/Landingpage" element={<Landingpage />} />
          <Route exact path="/register" element={<Signin />} />
          <Route exact path="/Dashboard" element={<Dashboard />} />
          <Route exact path="/Calendar" element={<CalendarPage />} />
          <Route exact path="/InventoryFMB" element={<ExportInventory />} />
          <Route exact path="/Miqaat-Inventory" element={<Miqaatinventory />} />
          <Route exact path="/InventoryCombined" element={<ExportInventoryCombined />} />
          <Route exact path="/ThaliPage" element={<ThaliPage />} />
          <Route exact path="/Total-Ingredients" element={<TotalIngredients />} />
          <Route exact path="/Dishes" element={<Dishes />} />
          <Route exact path="/Ingredients" element={<Ingredients />} />
          <Route exact path="/Vendors" element={<Vendors data={getvenderlist} />} />
          <Route exact path="/Users" element={<Users />} />
          <Route exact path="/Feedback" element={<Feedback />} />
          <Route exact path="/ScheduleFeedback" element={<ScheduleFeedback />} />
          <Route exact path="/Registeruser" element={<Registeruser />} />
          <Route exact path="/Procurement" element={<Procurement />} />
          <Route exact path="/Procurement-Miqaat" element={<ProcurementMiqaat />} />
          <Route exact path="/Procurement-Combined" element={<ProcurementCombined />} />
          <Route exact path="/Caterer" element={<Caterer />} />
          <Route exact path="/ViewInventory" element={<ViewInventory />} />
          <Route exact path="/Inventorylist" element={<InventoryPage />} />
          <Route exact path="/Report" element={<Report />} />
          <Route exact path="/Report" element={<Thalireg />} />
          <Route exact path="/Thaali-reg" element={<Thalireg />} />
        </Routes>
      </div>

      <ToastContainer />
    </>
  );
}

export default App;
