import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";

import user_icon from "../../../img/Icon/user_icon.svg";
import email_icon from "../../../img/Icon/email.svg";
import phone_icon from "../../../img/Icon/phone.svg";
import ven from "../../../img/ven.png";
import starticon from "../../../img/Icon/starticon.png";

export default function ScheduleFeedback() {
  const user_Role = localStorage.getItem("access_Role");
  var dateName = function (yyyy, mm, dd) {
    console.log ("yyyy", yyyy, mm, dd)
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    var today = new Date(yyyy, mm - 1, dd);
    console.log ("Todays Date", yyyy, mm, dd)
    var dd = today.getDate();
    var dayNameIndex = (today.getDay() - 1) == -1 ? 6 : today.getDay() - 1
    var dayname = dayNames[dayNameIndex];
    var mm = monthNames[today.getMonth()];
    var yyyy = today.getFullYear();
    var fullDate = dayname + ", " + mm + " " + dd + ", " + yyyy;
    return fullDate;
  };

  var token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location.state)
  const menuSummary = JSON.parse(location.state?.item?.menu)
  const item = location.state?.item
  const feedbacks = location.state?.feedbacks
  const formattedCurrentDate = item.schedule_day?.split("T")[0]  // Convert date to ISO string
  const dayInformation = dateName(formattedCurrentDate?.split("-")[0], formattedCurrentDate?.split("-")[1], formattedCurrentDate?.split("-")[2])
  console.log(menuSummary)
  
  // function convertDateForIos(date) {
  //   var arr = date?.split(/[- :]/);
  //   console.log(arr)
  //   date = new Date(arr[2], arr[0]-1, arr[1]);
  //   return date;
  // }

  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="thali_table_section  TotalIngredientstable">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">
                    <span className="thali_table">Dishes</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Caterers</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Date</span>
                  </th>
                  <th scope="col">
                    <span className="thali_table">Remarks</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <ul className="thali_menu_list">
                      {item.menu &&
                        JSON.parse(item.menu).map(
                          (menu, index) => (
                            <li key={index}>
                              {menu ? menu : "NA"}
                            </li>
                          )
                        )}
                    </ul>
                  </th>
                  <th scope="row">
                  {item.jamanType == "COOKING" &&
                    <ul className="thali_menu_list">
                      {item.cater_name1 != null &&
                        item.cater_name1 !== "" && (
                          <li>
                            &nbsp;
                            <img
                              src={user_icon}
                              className="img-fluid"
                            />
                              &nbsp;&nbsp;{item.cater_name1}
                          </li>
                        )}
                      {item.cater_name2 != null &&
                        item.cater_name2 !== "" && (
                          <li>
                            <img
                              src={user_icon}
                              className="img-fluid"
                            />
                            {item.cater_name2}
                          </li>
                        )}
                    {item.cater1_email &&
                    item.cater1_email !== "null" && (
                      <>
                        <li>
                          <img
                            src={email_icon}
                            className="img-fluid"
                            width="22px"
                            height="22px"
                          />
                          &nbsp; 
                          {item.cater1_email
                            ? item.cater1_email
                            : "NA"}
                        </li>
                      </>
                    )}
                    {item.cater1_phone_number &&
                    item.cater1_phone_number !== "null" && (
                    <>
                    <li>
                          &nbsp;
                          <img
                            src={phone_icon}
                            className="img-fluid"
                            width="18px"
                            height="18px"
                          />
                          &nbsp;
                          {item.cater1_phone_number
                            ? item.cater1_phone_number
                            : "NA"}
                        </li>
                      </>
                    )}
                  </ul>
                  }
                  {item.jamanType == "ORDERING" &&
                      <ul className="thali_menu_list">
                          <li>
                            {item.jamanType == "ORDERING" && (
                          <span className="Draft_ordering_event">
                            {item.jamanType == "ORDERING" ? "ORDERING" : ""}
                          </span>
                        )}
                          </li>
                          <>
                            <li>
                              <img
                                src={ven}
                                className="img-fluid"
                                width="16px"
                                height="16px"
                              />
                              &nbsp; &nbsp; 
                              {item.order_by
                                ? item.order_by
                                : "-"}
                            </li>
                          </>
                      </ul>
                  }
                  </th>
                  <th scope="row">
                    <ul className="thali_menu_list">
                      <li>
                        <span className="Date_list">{dayInformation ? dayInformation : "" }</span>{" "}
                      </li>
                    </ul>
                  </th>
                  <th scope="row">
                    <ul className="thali_menu_list">
                      <li>{item.notes ? item.notes : "-"}</li>
                    </ul>
                  </th>
                </tr>
              </tbody>
            </table>
         
          <div className="">
            <div className="thali_table_section">
              <table className="table table-bordered table-responsive">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <span className="thali_table">Feedback Day</span>
                    </th>
                    {user_Role.includes("FBM") && 
                      <th scope="col">
                        <span className="thali_table">Name</span>
                      </th>
                    }
                    <th scope="col">
                      <span className="thali_table">Taste</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Quantity</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Dish</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Comment</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks &&
                    feedbacks.map((item) => {
                      // const date = new Date(item.schedule_day);
                      const date = new Date(item.feedback_date)
                      const day = date.toLocaleString("en-US", {
                        weekday: "long",
                      });
                      const month = date.toLocaleString("en-US", {
                        month: "long",
                      });
                      const numericDate = date.getDate();
                      const twoDigitYear = date.getFullYear();
                      return (
                        <tr key={item.id}>
                          <th scope="row">
                          <div className="Calendar_card">
                            {" "}
                            <h6>{day}</h6>
                            <h1>{numericDate}</h1>
                            <p>{month}, {twoDigitYear}</p>
                            </div>
                          </th>
                          {user_Role.includes("FBM") && <th scope="row">
                            <ul className="thali_menu_list">
                              <li>
                                {item.user_name ? item.user_name : '-'}{" "}
                              </li>
                            </ul>
                          </th>}
                          <th scope="row">
                          <ul className="thali_menu_list">
                              <li>
                                <img
                                  src={starticon}
                                  className="img-fluid"
                                />
                                &nbsp;&nbsp;&nbsp;{item.taste_rating ? item.taste_rating : '-'}{" "}
                              </li>
                            </ul>
                          </th>
                          <th scope="row">
                          <ul className="thali_menu_list">
                              <li>
                                <img
                                  src={starticon}
                                  className="img-fluid"
                                />
                                &nbsp;&nbsp;&nbsp;{item.quantity_rating ? item.quantity_rating : '-'}{" "}
                              </li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>
                                <img
                                  src={starticon}
                                  className="img-fluid"
                                />
                                &nbsp;&nbsp;&nbsp;{item.dish_rating ? item.dish_rating : '-'}{" "}
                              </li>
                            </ul>
                          </th>
                          <th scope="row">
                          <ul>
                                <p>
                                  <div>
                                  <div style={{ fontSize: 14, width: "300px", fontWeight: '420', color: "#2E294E" }}>{item.comment ? item.comment : '-'}</div>
                                  </div>
                                </p>
                              </ul>
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </section>
      </div>
    </div>
  )
}
