import React, { useEffect, useState } from 'react'
import createPersistedState from 'use-persisted-state';

import userprofilelist from "../../../img/userprofilelist.png";
import remove from "../../../img/remove.png";
import editicon from "../../../img/editicon.png";
import Header from "../Header/Header";
import Modal from "react-modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from '../../../img/Icon/calender.svg';
import Select from 'react-select';
import Sidebarmenu from '../../Sidebar/Sidebarmenu';
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import "../Dashboard.css";
import FriedChicken from '../../../img/Icon/FriedChicken.svg';
import KadaiGosh from '../../../img/Icon/KadaiGosh.svg';
import Rice from '../../../img/Icon/Rice.svg';
import ButterRoti from '../../../img/Icon/ButterRoti.png';
import Mango from '../../../img/Icon/Mango.svg';
import user_icon from '../../../img/Icon/user_icon.svg';
import email_icon from "../../../img/Icon/email.svg";
import phone_icon from "../../../img/Icon/phone.svg";
import { procurmentlist, api_url, Inventory_list, procurment_url, fmb_schedule_url } from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import loadergif from '../../../img/landing/loader123.gif';
export default function ProcurementMiqaat() {


    const useProcurementStateData = createPersistedState('procurmentMiqaatdata');
    const useActiveTabeData = createPersistedState('activeMiqaatTab');
    const useSelectedCheckBoxData = createPersistedState('selectedMiqaatCheckbox');
    const useCurrentPageData = createPersistedState('currentMiqaatPage');

    const useStartWeekMiqaat = createPersistedState('currentStartWeekMiqaat');
    const useEndWeekMiqaat = createPersistedState('currentEndWeekMiqaat');
  
    const [procurmentdata, setProcurmentListData] = useProcurementStateData([]);
    const [selectedCheckbox, setSelectedCheckbox] = useSelectedCheckBoxData([]);


    var token = localStorage.getItem("access_token");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const currentDate = new Date();
    const formattedCurrentDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

    const today = new Date();
    today.setHours(0,0,0,0)
    const prevWeekStart = new Date(today);
    prevWeekStart.setDate(today.getDate() - 7 - today.getDay());
    const prevWeekEnd = new Date(prevWeekStart);
    prevWeekEnd.setDate(prevWeekEnd.getDate() + 6);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    const CurrentendOfWeek = new Date(today);
    CurrentendOfWeek.setDate(today.getDate() + (6 - today.getDay()));
    const nextOfWeek = new Date(today);
    const NextendOfWeek = new Date(today);

    nextOfWeek.setDate(today.getDate() + 7 - today.getDay()); // Set to the first day (Sunday) of the next week
    NextendOfWeek.setDate(today.getDate() + 13 - today.getDay());

    const [prevWeekStartdate, setPrevWeekStartDate] = useState(prevWeekStart.toISOString().split("T")[0]);
    const [prevWeekenddate, setPrevWeekEndDate] = useState(prevWeekEnd.toISOString().split("T")[0]);
    const [startweek, setStartWeek] = useState(startOfWeek.toISOString().split("T")[0]);
    const [CurrentendDate, setCurrentEndDate] = useState(CurrentendOfWeek.toISOString().split("T")[0]);
    const [nextstartDate, setNextStartDate] = useState(nextOfWeek.toISOString().split("T")[0]);
    const [nextendDate, setNextEndDate] = useState(NextendOfWeek.toISOString().split("T")[0]);

    const [activeTab, setActiveTab] = useActiveTabeData([]);

    const [totalcount, setTotalCount] = useState();
    const navigate = useNavigate();;
    // const formattedStartDate = selectedCheckbox.toISOString().split('T')[0];
    const [selectAll, setSelectAll] = useState(false);
    const [startDate, setStartDate] = useState(startOfWeek);
    const [endDate, setEndDate] = useState(CurrentendOfWeek);

    const [weekStartDate, setWeekStartDate] = useStartWeekMiqaat(startweek);
    const [weekEndDate, setWeekEndDate] = useEndWeekMiqaat(CurrentendDate);

    const [currentPage, setCurrentPage] = useCurrentPageData(1);
    const itemsPerPage = procurmentdata.length; // Number of items to display per page

    const pageCount = Math.ceil(procurmentdata.length / itemsPerPage);

    function convertDateForIos(date) {
        var arr = date.split(/[- :]/);
        date = new Date(arr[2], arr[0]-1, arr[1]);
        return date;
    }
    

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1) {
            setCurrentPage(1);
        } else if (pageNumber > pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    const handleFirstPage = () => {
        handlePageChange(1);
    };

    const handleLastPage = () => {
        handlePageChange(pageCount);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const displayedItems = procurmentdata.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // procurmentlist data 
    const getprocurment = () => {
          setLoading(true);
        dispatch(procurmentlist()).then((res) => {
            if (res?.payload?.status === true) {
                setProcurmentListData(res?.payload?.data);
                setActiveTab("current");
            } else {
                // toast.error(res?.payload?.message);
            }
        }).finally(() => {
            setLoading(false); // Set loading to false when data fetching is complete
          });
    };

    useEffect(() => {
        // getprocurment2();
    }, []);


    const getprocurment2 = () => {
        setLoading(true);
        const requestBody = {
            start_date: startweek,
            end_date: CurrentendDate
        };
       
        fetch(procurment_url + 'admin/procurment-miqaat-list', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.status === true) {
                    console.log('new data ', data?.data);
                    setProcurmentListData(data?.data);
                    // setActiveTab("current");
                } else {
                    toast.error("Select Date");
                    console.log('error ', data);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when data fetching is complete
            });

    }
    const [inputValues, setInputValues] = useState({}); // State to manage input values by schedule_id

    const handleInputChange = (e, scheduleId) => {
        const newCount = e.target.value;
        updateInputValue(scheduleId, newCount);
    };

    const getInputValue = (scheduleId) => {
        const selectedItem = procurmentdata.find(item => item.schedule_id === scheduleId);
        return selectedItem ? selectedItem.thaal_count : '';
    };

    const updateInputValue = (scheduleId, value) => {
        const updatedItems = procurmentdata.map(item => {
            if (item.schedule_id === scheduleId) {
                return {
                    ...item,
                    thaal_count: value
                };
            }
            return item;
        });
        setProcurmentListData(updatedItems);
    };

    const handleTotalCountChange = (e, scheduleId) => {
        const newTotalCount = e.target.value;
        setInputValues(prevState => ({
            ...prevState,
            [scheduleId]: newTotalCount,
        }));

        const requestBody = {
            schedule_id: scheduleId,
            thaal_count: newTotalCount,
        };

        fetch(fmb_schedule_url + 'admin/update-thaali-count', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('access_token'),
            },
            body: JSON.stringify(requestBody),
        })
        .then(response => response.json())
        .then(data => {
            if (data?.status === true) {
                console.log('Data list ', data?.data);
                // Handle success
            } else {
                // Handle error
                console.log('error ', data);
            }
        })
        .catch(error => {
            console.error(error);
        });
    };
  
    // const handleTotalCountChange = (e, scheduleId) => {
    //     const newTotalCount = e.target.value;
    //     setTotalCount(newTotalCount);

    //     const requestBody = {
    //         schedule_id: scheduleId, // Pass the scheduleId to the API
    //         thaal_count: newTotalCount
    //     };

    //     fetch(fmb_schedule_url + 'admin/update-thaali-count', {
    //         method: 'POST',
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json", // Assuming you're sending form data
    //             Authorization:localStorage.getItem("access_token"),
    //         },
    //         body: JSON.stringify(requestBody),
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data?.status === true) {
    //                 console.log('Data list ', data?.data);
    //                 // toast.success(data?.message);
    //                 // setProcurmentListData(data?.data);
    //                 // setActiveTab("current");
    //             } else {
    //                 toast.error("Enter count");
    //                 console.log('error ', data);
    //             }
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });

    // }

    // console.log('ddd', procurmentdata)

    const handleCheckboxChange = (event, schedule_id) => {
        const isChecked = event.target.checked;
        setSelectedCheckbox(prevCheckboxes => {
            if (isChecked) {
                return [...prevCheckboxes, schedule_id];
            } else {
                return prevCheckboxes.filter(item => item !== schedule_id);
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (checked) {
            const allScheduleIds = procurmentdata.map(item => item.schedule_id);
            setSelectedCheckbox(allScheduleIds);
        } else {
            setSelectedCheckbox([]);
        }
    };

    useEffect(() => {
        // Fetch or initialize your procurmentdata here
        const initialData = []; // Replace with your data fetching logic
        // Set all checkboxes to be checked by default
        const allScheduleIds = procurmentdata.map(item => item.schedule_id);
        setSelectedCheckbox(allScheduleIds);
    }, [procurmentdata]);


    const getselectdatachange = () => {
        setLoading(true);
        const requestBody = {
            schedule_id: selectedCheckbox,
            by_day: "false",
            start_date: weekStartDate,
            end_date: weekEndDate,
            curr_end_date: CurrentendDate
        };

        fetch(Inventory_list + 'admin/inventory-aggregate-miqaat', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json", // Assuming you're sending form data
                Authorization: localStorage.getItem("access_token"),
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data?.status === true) {
                    const viewInventory = data.data;
                    // Pass the thalicountData to the ThaliPage route using navigate
                    navigate('/Miqaat-Inventory', { 
                        state: { 
                            viewInventorydata: viewInventory, 
                            selectdate: selectedCheckbox,
                            rangeEndDate: data.data.rangeEndDate, 
                            rangeStartDate: data.data.rangeStartDate,
                            weekStartDate: weekStartDate,
                            weekEndDate: weekEndDate,
                            currentRangeEndDate: CurrentendDate
                        } });
                } else {
                    toast.error("Select Date");
                    console.log('error ', data);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when data fetching is complete
            });
    }

    var dateName = function (yyyy, mm, dd, full) {
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dayNames = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        var today = new Date(yyyy, mm - 1, dd);
        console.log ("Todays Date", yyyy, mm, dd)
        var dd = today.getDate();
        var dayNameIndex = (today.getDay() - 1) == -1 ? 6 : today.getDay() - 1
        var dayname = dayNames[dayNameIndex];
        var mm1 = monthNames[today.getMonth()];
        var yyyy1 = today.getFullYear();
        var fullDate = full === "true" ? mm + "/" + dd + "/" + yyyy :mm1 + " " + dd;    
        return fullDate;
      };
    
      const formatDate = (dateString, full) => {
        if (!dateString) {
          return ""; // return empty string if dateString is undefined or empty
        }
        const formattedCurrentDate = dateString && dateString?.split("T")[0]; // Convert date to ISO string
        const dayInformation = dateName(formattedCurrentDate?.split("-")[0], formattedCurrentDate?.split("-")[1], formattedCurrentDate?.split("-")[2], full)
        return dayInformation;
      };


    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = date.getDate();
    //     const month = date.toLocaleString("default", { month: "short" });
    //     return `${day} ${month}`;
    // };


    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const year = date.getUTCFullYear();
    //     const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    //     const day = String(date.getUTCDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    // const Statedate = formatDate(selectedCheckbox[0]);
    // const Enddate = formatDate(selectedCheckbox[selectedCheckbox.length - 1]);
    // 

    // date picker start

    const handleDateChange = (event) => {
        const selectedDates = event.target.value.split(" - ");
        const selectedStartDate = selectedDates[0];
        const selectedEndDate = selectedDates[1];
        const parts = selectedEndDate.split("/");
        const formattedEndDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
        const partsstart = selectedStartDate.split("/");
        const formattedStartDate = `${partsstart[2]}-${partsstart[0].padStart(2, '0')}-${partsstart[1].padStart(2, '0')}`;
        console.log("Selected Dates:", "startDate =", selectedEndDate, "endDate =", formattedStartDate);



        const requestBody = {
            start_date: formattedStartDate,
            end_date: formattedEndDate
        };

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);

        setWeekStartDate(formattedStartDate);
        setWeekEndDate(formattedEndDate);

        setActiveTab("");
        getdatechange(requestBody);
    };
    // const handleDateChange = (selectedDate) => {
    //     // 'selectedDate' is the new date value selected in the DatePicker
    //     setStartDateAll(selectedDate);

    //     const formattedStartDate = selectedDate.toISOString().split('T')[0];

    //     const requestBody = {
    //         start_date: formattedStartDate,
    //         end_date: formattedStartDate, // You might want to change this if it should be different
    //     };

    //     // Call your 'getdatechange' function with the requestBody
    //     getdatechange(requestBody);
    //     setActiveTab(null);
    // };

    const getdatechange = (requestBody) => {
        setLoading(true);
        fetch(procurment_url + 'admin/procurment-miqaat-list', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json", // Assuming you're sending form data
                Authorization: localStorage.getItem("access_token"),
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.status === true) {
                    console.log('new data ', data.data);
                    // toast.success(data?.message);
                    //   setRagister(data.data);
                    setProcurmentListData(data.data);
                } else {
                    toast.error(data?.message);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when data fetching is complete
            });

    }



    const handleDatePREVIOUS = (event) => {
        const requestBody = {
            start_date: prevWeekStartdate,
            end_date: prevWeekenddate
        };

        setStartDate(prevWeekStartdate);
        setEndDate(prevWeekenddate);

        setWeekStartDate(prevWeekStartdate);
        setWeekEndDate(prevWeekenddate);
        
        getdatechange(requestBody);
        setActiveTab('previous');
    };



    const handleDateCURRENT = (event) => {
        const requestBody = {
            start_date: startweek,
            end_date: CurrentendDate
        };

        setStartDate(startweek);
        setEndDate(CurrentendDate);

        setWeekStartDate(startweek);
        setWeekEndDate(CurrentendDate);

        getdatechange(requestBody);
        setActiveTab('current');
    };


    const handleDateNext = (event) => {
        const requestBody = {
            start_date: nextstartDate,
            end_date: nextendDate
        };

        setStartDate(nextstartDate);
        setEndDate(nextendDate);

        setWeekStartDate(nextstartDate);
        setWeekEndDate(nextendDate);

        getdatechange(requestBody);
        setActiveTab('next');
    };
    // date picker end
    const [startDateall, setStartDateAll] = useState(new Date());

    const csvData = procurmentdata?.map((items) => {
        const formattedDate = new Date(items?.schedule_day).toLocaleDateString();
        const startIndex = items?.menu?.indexOf('"') + 1;
        const endIndex = items?.menu?.lastIndexOf('"');
        const extractedText = items?.menu?.substring(startIndex, endIndex).replace(/,/g, " | ")
           
        return {
          date: formattedDate,
          menu: extractedText || "",
          caterer: items?.cater_name1+" | "+items?.cater1_phone_number+" | "+items?.cater1_email,
          thaal_count: items?.thaal_count,
        };
      });
    
      const csvHeaders = [
        { label: "Date", key: "date" },
        { label: "Menu", key: "menu" },
        { label: "Caterer", key: "caterer" },
        { label: "Thaal Count", key: "thaal_count" },
      ];


    return (
        <div>
            <Header />
            <div
                className="side_div_menu"
                style={{ display: "flex", height: "100%" }}
            >
                <Sidebarmenu />
                <section className="main_section">
                    <div className="custam">
                        <div className="main_section2">
                            <div className="ExportInventory thali_section Thali_page">
                                <div className="container-fluid">
                                    <div className="thili_div">
                                        <div className="Back-page">
                                            <h1 className="title_heading">
                                                Procurement ( Miqaat){" "}
                                                <span className="Line_div"> &nbsp; &nbsp; &nbsp;&nbsp; |</span>
                                                <span className="Date_list">{formatDate(weekStartDate) +" - "+ formatDate(weekEndDate)}</span>{" "}
                                            </h1>{" "}
                                        </div>
                                        <></>
                                        <div className="Thali_page_btn New_section">

                                            <div className='Form_box'>
                                            <div className='calender-icon-div'>
                                                <img src={calender} className='img-fluid' /> 
                                                <div className='Calender_div'>
                                                  
                                                    <DateRangePicker
                                                        initialSettings={{ startDate: startDate, endDate: endDate }}
                                                        // onDateChange={handleDateChange}
                                                        onApply={handleDateChange}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={formattedCurrentDate}
                                                        />
                                                    </DateRangePicker>
                                                </div>
                                                </div>
                                                {/* <div className='singal_calendar'>
                                                    <img src={calender} className='img-fluid' /> &nbsp; &nbsp; &nbsp;
                                                    <DatePicker
                                                        selected={startDateall}
                                                        onChange={handleDateChange} //only when value has changed
                                                    />
                                                </div> */}
                                            </div>
                                            <span className='Line_div'>|</span>
                                            {/* <Link to="/InventoryFMB"> */}


                                            <button className='btn thali_btn' onClick={getselectdatachange}>View Ingredients</button>
                                            &nbsp; &nbsp;
                                            <button className="btn thali_btn">
                                                <CSVLink
                                                data={csvData}
                                                headers={csvHeaders}
                                                filename={"Miqaat_Menu_and_Count.csv"}
                                                >
                                                Export Data
                                                </CSVLink>
                                            </button>
                                            {/* <button className='btn thali_btn' onClick={getprocurment}>All Data</button> */}


                                            {/* </Link> */}
                                        </div>
                                    </div>

                                    {/* <div className="thali_count_list">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                        />
                                    </div>
                                </div> */}
                                    {/* <div className="container-fluid mt-4"> */}

                                    <div className="row mt-2">
                                        <div className="col-lg-4 col-md-6">
                                            <div className={`Week_list ${activeTab === 'previous' ? 'active' : ''}`} onClick={handleDatePREVIOUS}>
                                                <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                            fill="#957137"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="week_text">
                                                    <h4>Previous Week From Today</h4>
                                                    <h1>{formatDate(prevWeekStartdate)} - {formatDate(prevWeekenddate)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className={`Week_list INGREDIENTS_Cout ${activeTab === 'current' ? 'active' : ''}`} onClick={handleDateCURRENT} >
                                                <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                            fill="#591333"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="week_text">
                                                    <h4>Current Week</h4>
                                                    <h1>{formatDate(startweek)} - {formatDate(CurrentendDate)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className={`  Week_list Thali_Count ${activeTab === 'next' ? 'active' : ''}`} onClick={handleDateNext}>
                                                <div className="week_img">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="28"
                                                        viewBox="0 0 24 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.33333 15.9998C6.04444 15.9998 5.80556 15.9054 5.61667 15.7165C5.42778 15.5276 5.33333 15.2887 5.33333 14.9998C5.33333 14.7109 5.42778 14.4721 5.61667 14.2832C5.80556 14.0943 6.04444 13.9998 6.33333 13.9998H17.6667C17.9556 13.9998 18.1944 14.0943 18.3833 14.2832C18.5722 14.4721 18.6667 14.7109 18.6667 14.9998C18.6667 15.2887 18.5722 15.5276 18.3833 15.7165C18.1944 15.9054 17.9556 15.9998 17.6667 15.9998H6.33333ZM6.33333 21.9998C6.04444 21.9998 5.80556 21.9054 5.61667 21.7165C5.42778 21.5276 5.33333 21.2887 5.33333 20.9998C5.33333 20.7109 5.42778 20.4721 5.61667 20.2832C5.80556 20.0943 6.04444 19.9998 6.33333 19.9998H13.6333C13.9222 19.9998 14.1611 20.0943 14.35 20.2832C14.5389 20.4721 14.6333 20.7109 14.6333 20.9998C14.6333 21.2887 14.5389 21.5276 14.35 21.7165C14.1611 21.9054 13.9222 21.9998 13.6333 21.9998H6.33333ZM2 27.3332C1.46667 27.3332 1 27.1332 0.6 26.7332C0.2 26.3332 0 25.8665 0 25.3332V4.6665C0 4.13317 0.2 3.6665 0.6 3.2665C1 2.8665 1.46667 2.6665 2 2.6665H4.16667V1.73317C4.16667 1.44428 4.27222 1.19428 4.48333 0.983171C4.69444 0.772059 4.95556 0.666504 5.26667 0.666504C5.55556 0.666504 5.80556 0.772059 6.01667 0.983171C6.22778 1.19428 6.33333 1.44428 6.33333 1.73317V2.6665H17.6667V1.73317C17.6667 1.44428 17.7722 1.19428 17.9833 0.983171C18.1944 0.772059 18.4556 0.666504 18.7667 0.666504C19.0556 0.666504 19.3056 0.772059 19.5167 0.983171C19.7278 1.19428 19.8333 1.44428 19.8333 1.73317V2.6665H22C22.5333 2.6665 23 2.8665 23.4 3.2665C23.8 3.6665 24 4.13317 24 4.6665V25.3332C24 25.8665 23.8 26.3332 23.4 26.7332C23 27.1332 22.5333 27.3332 22 27.3332H2ZM2 25.3332H22V10.9998H2V25.3332Z"
                                                            fill="#1F2F32"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="week_text">
                                                    <h4>Next Week From Today</h4>
                                                    <h1>{formatDate(nextstartDate)} - {formatDate(nextendDate)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="thali_table_section mt-4">
                                {loading ? (
                                        // Render a loading indicator while data is being fetched
                                        <div className='Loading-img'><img src={loadergif} className='' /></div>
                                    ) : (
                                 <div></div>
                                    )
                                    }
                                    {displayedItems.length > 0 ? (
                                    <table className="table table-bordered  Procurementtabel table-responsive">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">
                                                    <span className="thali_table">All &nbsp; &nbsp;<input className="form-check-input" type="checkbox" checked={selectedCheckbox.length === procurmentdata.length}
                                                        onChange={handleSelectAllChange} /></span>

                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Day & Date</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Menu</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Caterer</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Remarks</span>
                                                </th>
                                                {/* <th scope="col">
                                                    <span className="thali_table">1-2 Thali</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">3-4 Thali</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">5-6 Thali</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table"></span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Total Full Thali</span>
                                                </th> */}
                                                <th scope="col">
                                                    <span className="thali_table">Total Count</span>
                                                </th>
                                                {/* <th scope="col"><span className='thali_table'>Current Qty/Unit</span></th>
                                  <th scope="col"><span className='thali_table'>Order PKG COUNT/TYPE</span></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedItems && displayedItems?.map((item) => {
                                                // const date = new Date(item.schedule_day);
                                                const date = convertDateForIos(item.schedule_day);

                                                const day = date.toLocaleString('en-US', { weekday: 'long' });
                                                const month = date.toLocaleString('en-US', { month: 'long' });
                                                const numericDate = date.getDate();
                                                const twoDigitYear = date.getFullYear();
                                                return (

                                                    <tr key={item.schedule_id}>
                                                        <th scope="row">
                                                            <ul className="thali_menu_list">   <li>

                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={selectedCheckbox.includes(item.schedule_id)}
                                                                    onChange={(e) => handleCheckboxChange(e, item.schedule_id)}
                                                                />

                                                            </li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row">
                                                            <ul className="thali_menu_list">
                                                                {/* <img src={userprofilelist} className="img-firud" /> */}
                                                                <li><div className='Calendar_card'>
                                                                    <h6>{day}</h6>
                                                                    <h1>{numericDate}</h1>
                                                                    <p>{month},{twoDigitYear}</p>

                                                                </div>
                                                                </li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row">
                                                            <ul className='thali_menu_list'>
                                                                {item.menu && JSON.parse(item.menu).map((menu, index) => (
                                                                    <li key={index}>

                                                                        {menu ? menu : 'NA'}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </th>
                                                        <th scope="row">
                                                            <ul className='thali_menu_list'>
                                                                {item.cater_name1 && item.cater_name1 !== "null" && (<>
                                                                    <li>&nbsp;&nbsp;<img src={user_icon} className='img-fluid' /> &nbsp; &nbsp;{item.cater_name1 ? item.cater_name1 : "NA"}</li></>)}
                                                                {item.cater1_email && item.cater1_email !== "null" && (<>
                                                                    <li><img src={email_icon} className='img-fluid' width="22px" height="22px"/> &nbsp; &nbsp;{item.cater1_email ? item.cater1_email : "NA"}</li></>)}
                                                                {item.cater1_phone_number && item.cater1_phone_number !== "null" && (<>
                                                                    <li>&nbsp;<img src={phone_icon} className='img-fluid' width="18px" height="18px" />&nbsp; &nbsp;{item.cater1_phone_number ? item.cater1_phone_number : "NA"}</li></>)}
                                                                {/* {item.cater_name2 && item.cater_name2 !== "null" && (
                                                                    <li><img src={user_icon} className='img-fluid' />{item.cater_name2 ? item.cater_name2 : 'Na'}</li>)} */}
                                                            </ul></th>
                                                        <th scope="row">
                                                            <ul className="thali_menu_list">
                                                                <li>{item.notes ? item.notes : '-'}</li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row">
                                                            <ul className="thali_menu_list">
                                                                <li>
                                                                    <div className="Form_box Totalcount">
                                                                        <input
                                                                            type="Number"
                                                                            placeholder="Count"
                                                                            className="form-control"
                                                                            id="inputcount"
                                                                            min="0"
                                                                            onKeyDown={preventMinus}
                                                                            onWheel={() => document.activeElement.blur()}
                                                                            onBlur={(e) => handleTotalCountChange(e, item.schedule_id)}
                                                                            onChange={(e) => handleInputChange(e, item.schedule_id)}
                                                                            value={getInputValue(item.schedule_id)}

                                                                        />

                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </th>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    )
                                    : (
                                        // Render when Inventorydata is empty
                                        <div className="No_Inventory">
                                        {loading ? (
                                        // Render a loading indicator while data is being fetched
                                        <div>
                                            {/* <div ><h1>Loading...</h1></div> */}
                                        </div>
                                        ) : (
                                        <div><h1 >Select a range of dates to generate Procurement (Miqaat) report.</h1> </div>
                                        )
                                        }
                                        </div>
                                    )}
                                </div>
                                <nav>
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={handleFirstPage}>
                                                First
                                            </button>
                                        </li>
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Previous
                                            </button>
                                        </li>

                                        {getPageNumbers().map((pageNumber) => (
                                            <li
                                                className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                                                key={pageNumber}
                                            >
                                                <button
                                                    className="page-link pagebutton"
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </button>
                                            </li>
                                        ))}

                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Next
                                            </button>
                                        </li>
                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button className="page-link" onClick={handleLastPage}>
                                                Last
                                            </button>
                                        </li>
                                    </ul>
                                </nav>

                                {/* <div className="thili_div">
                                        <div className="Back-page">
                                        </div>
                                        <div className="Back-page">
                                            <button className="btn thali_btn">Submit Changes</button>
                                        </div>
                                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
