import React, { useState } from "react";
import "../Login/Login.css";
import { useNavigate, Link } from "react-router-dom";
import { signIn } from "../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import logo from "../../img/logo.png";
import logoheading from "../../img/logoheading.png";
import logoheading2 from "../../img/logoheading2.png";
import mnu from "../../img/mnu.png";
import { useFormik } from "formik";
const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "please enter first name";
  } else if (values.first_name.length < 1) {
    errors.first_name = "Must be 15 characters or less";
  }
  if (!values.last_name) {
    errors.last_name = "please enter last name";
  } else if (values.last_name.length < 1) {
    errors.last_name = "Must be 15 characters or less";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "please enter mobile number";
  } else if (values.mobile_number.length < 1) {
    errors.mobile_number = "Must be 15 characters or less";
  }
  if (!values.email) {
    errors.email = "please enter email";
  } else if (values.email.length < 1) {
    errors.email = "Must be 15 characters or less";
  }
  if (!values.password) {
    errors.password = "Enter Your password";
  } else if (values.password.length < 1) {
    errors.password = "Must be 15 characters or less";
  }
  if (!values.address) {
    errors.address = "please enter Address";
  } else if (values.address.length < 1) {
    errors.address = "Must be 15 characters or less";
  }
  if (!values.role_id) {
    errors.role_id = "please enter role";
  } else if (values.address.length < 1) {
    errors.address = "Must be 15 characters or less";
  }

  return errors;
};
function Signin(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      password: "",
      role_id: "",
      profile_picture: "",
      address: "",
    },
    validate,
    onSubmit: (values) => {
      dispatch(signIn(values))
        .then((res) => {
          console.log("ss", res);
        })
        .catch((error) => {
          console.log("ss", error);
        });
    },
  });

  return (
    <div>
      <section className="Login_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <div className="login_bg">
                <div className="log_in_page">
                  <div className="login_box">
                    <img src={logo} className="img-fluid" />
                  </div>
                  <div className="login_info">
                    <img src={logoheading} className="img-fluid" />
                    <ul className="login_text">
                      <li>
                        <div>
                          {" "}
                          <img src={mnu} className="img-fluid" />
                        </div>{" "}
                        Don't forget to wash your hands before you indulge in
                        your healthy tiffin.
                      </li>
                      <li>
                        <div>
                          {" "}
                          <img src={mnu} className="img-fluid" />
                        </div>{" "}
                        Don't forget to wash your hands before you indulge in
                        your healthy tiffin.
                      </li>
                      <li>
                        <div>
                          {" "}
                          <img src={mnu} className="img-fluid" />
                        </div>{" "}
                        Don't forget to wash your hands before you indulge in
                        your healthy tiffin.
                      </li>
                    </ul>
                    <img src={logoheading2} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <form className="Login_form" onSubmit={formik.handleSubmit}>
                {/* <h1>Creact  your account</h1> */}
                <div className="form-group d-flex gap-2">
                  <div className="">
                    <label>First Name</label>
                    <input
                      name="first_name"
                      type="text"
                      className={`form-control ${formik.errors.first_name && formik.touched.first_name
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.first_name && formik.touched.first_name && (
                      <p className="error">{formik.errors.first_name}</p>
                    )}
                  </div>
                  <div className="">
                    <label>LastName</label>
                    <input
                      name="last_name"
                      type="text"
                      className={`form-control ${formik.errors.last_name && formik.touched.last_name
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.last_name && formik.touched.last_name && (
                      <p className="error">{formik.errors.last_name}</p>
                    )}
                  </div>
                </div>
                <div className="form-group d-flex gap-2">
                  <div className="">
                    <label>Mobile Number</label>
                    <input
                      name="mobile_number"
                      type="text"
                      className={`form-control ${formik.errors.mobile_number &&
                        formik.touched.mobile_number
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.mobile_number}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.mobile_number &&
                      formik.touched.mobile_number && (
                        <p className="error">{formik.errors.mobile_number}</p>
                      )}
                  </div>
                  <div className="">
                    <label>Email </label>
                    <input
                      name="email"
                      type="text"
                      className={`form-control ${formik.errors.email && formik.touched.email
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="error">{formik.errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="form-group d-flex gap-2">
                  <div className="">
                    <label>Password</label>
                    <input
                      name="password"
                      type="password"
                      className={`form-control ${formik.errors.password && formik.touched.password
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <p className="error">{formik.errors.password}</p>
                    )}
                  </div>

                  <div className="">
                    <label>Role</label>
                    <select
                      className={`form-control ${formik.errors.role_id && formik.touched.role_id
                        ? "is-invalid"
                        : ""
                        }`}
                      id="role"
                      name="role_id"
                      value={formik.values.role_id}
                      onChange={formik.handleChange}
                    >
                      <option>Admin</option>
                      <option>Auther</option>
                    </select>
                    {formik.errors.role_id && formik.touched.role_id && (
                      <p className="error">{formik.errors.role_id}</p>
                    )}
                  </div>
                </div>
                <div className="form-group d-flex gap-2">
                  <div className="">
                    <label>Profile picture</label>
                    <input
                      name="profile_picture"
                      type="file"
                      className={`form-control ${formik.errors.profile_picture &&
                        formik.touched.profile_picture
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                  </div>
                  <div className="">
                    <label>address</label>
                    <input
                      name="address"
                      type="text"
                      className={`form-control ${formik.errors.address && formik.touched.address
                        ? "is-invalid"
                        : ""
                        }`}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.address && formik.touched.address && (
                      <p className="error">{formik.errors.address}</p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary mr-1 submit_btn"
                  >
                    Register
                  </button>
                </div>

                {/* <div className="form-group">
                <Link to='/'>  <button type="submit" className="btn btn-primary mr-1 submit_btn" onClick={submitRegister}>Login</button></Link>

                </div> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Signin;
