import React, { useEffect, useState } from 'react'
import Sidebarmenu from '../../Sidebar/Sidebarmenu'
import Header from '../Header/Header'
import { api_url, Inventory_list, inventorylist, deleteInventory } from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import loadergif from '../../../img/landing/loader123.gif';
export default function ViewInventory() {
    const user_Role = localStorage.getItem("access_Role");
    var token = localStorage.getItem("access_token");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inventorylistData, setInventoryListData] = useState("");
    const [loading, setLoading] = useState(true);


  function convertDateForIos(date) {
    var arr = date.split(/[- :]/);
    date = new Date(arr[2], arr[0] - 1, arr[1]);
    return date;
  }

    // const [standardinventory, setStandardInventory] = useState("0");


    // const handleButtonChange = (value) => {
    //     setSelectedOption(value);
    //     const requestBody = {
    //         standardin: standardinventory,

    //     };
    //     getselectdatachange(requestBody);
    //   };
    // const handleToggleChange2 = (e) => {
    //     const newValue = standardinventory === "1" ? "0" : "1";
    //     setStandardInventory(newValue);
    //     const requestBody = {
    //         standardin: newValue,

    //     };
    //     console.log("newValue3", newValue)
    // }

    const getinventorylist = () => {
        setLoading(true);
        dispatch(inventorylist()).then((res) => {
            if (res?.payload?.status === true) {
                setInventoryListData(res?.payload?.data);
                // setActiveTab("current");
                console.log('dddd', res?.payload?.data)
            } else {
                // toast.error(res?.payload?.message);
            }
        }) .finally(() => {
            setLoading(false); // Set loading to false when data fetching is complete
          });
    };

    const performDeleteInventory = (id) => {
        dispatch(deleteInventory(id)).then((res) => {
          if (res?.payload?.success == true) {
            getinventorylist();
            toast.success("Inventory Deleted Successful");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      };

    useEffect(() => {
        getinventorylist();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date?.toISOString().slice(0, 10);
      };


    const getInventory = (thaali_inventory_request_id, status ,ingredient_count, start_date, end_date, current_week_ids) => {
        setLoading(true);
        const requestBody = {
            thaali_inventory_req_id: thaali_inventory_request_id
        };

        console.log('requestBody', ingredient_count)

        fetch(Inventory_list + 'admin/inventory', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.status === true) {
                    const Inventory = data.data;
                    const inventoryrequestid = thaali_inventory_request_id;
                    const currentWeekIds = current_week_ids?.length > 0 ? current_week_ids.split(',') : []
                    const statusdata = status
                    const ingredientcount = ingredient_count

                    const requestRotiBody = {
                        start_date: formatDate(convertDateForIos(start_date)),
                        end_date: formatDate(convertDateForIos(end_date))
                    };

                    fetch(Inventory_list + 'admin/thaali-inventory-roti-gosh', {
                        method: 'POST',
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json", // Assuming you're sending form data
                            Authorization: localStorage.getItem("access_token"),
                        },
                        body: JSON.stringify(requestRotiBody),
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data?.status === true) {
                                navigate('/Inventorylist', { state: { Inventorylist: Inventory, inventoryrequestid: inventoryrequestid,
                                     statusall: statusdata ,ingredientcountall: ingredientcount, thaaliRotiData: data?.data, current_week_ids: currentWeekIds } });
                            } else {
                                toast.error(data?.message);
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        }).finally(() => {
                            setLoading(false); // Set loading to false when data fetching is complete
                        });

                    navigate('/Inventorylist', { state: { Inventorylist: Inventory, inventoryrequestid: inventoryrequestid,
                         statusall: statusdata ,ingredientcountall: ingredientcount } });
                } else {
                    toast.error(data?.message);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when data fetching is complete
              });
            
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items to display per page

    const pageCount = Math.ceil(inventorylistData?.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1) {
            setCurrentPage(1);
        } else if (pageNumber > pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    const handleFirstPage = () => {
        handlePageChange(1);
    };

    const handleLastPage = () => {
        handlePageChange(pageCount);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const displayedItems = inventorylistData?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div>
            <Header />
            <div
                className="side_div_menu"
                style={{ display: "flex", height: "100%" }}
            >
                <Sidebarmenu />
                <section className="main_section">
                    <div className="custam">
                        <div className="main_section2">
                            <div className="ExportInventory thali_section Thali_page">
                                <div className="container-fluid">
                                    <div className="thili_div">
                                        <div className="Back-page">

                                            <h1 className="title_heading">
                                                View Inventory &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                {/* <span className="Line_div">|</span> */}
                                                {/* <span className="Date_list">{formattedStartDate} - {formattedEndDate}</span>{" "} */}
                                            </h1>{" "}
                                        </div>
                                        {/* <div className="Thali_page_btn AddStandardInventory">
                                            <label for="Standard" className="form-label">
                                                Add Standard Inventory &nbsp; &nbsp; 
                                            </label>
                                            <div className="onoffswitch">
                                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                                                    checked={standardinventory == "1"}
                                                    onChange={handleToggleChange2} />
                                                <label className="onoffswitch-label" for="rice_check">
                                                    <span className="onoffswitch-inner"></span>
                                                    <span className="onoffswitch-switch"></span>
                                                </label>
                                            </div>
                                            &nbsp; &nbsp;
                                            &nbsp;{" "}

                                            &nbsp;
                                           
                                        </div> */}
                                    </div>
                                </div>


                                <div className="thali_table_section">
                                {loading ? (
                                        // Render a loading indicator while data is being fetched
                                        <div className='Loading-img'><img src={loadergif} className='' /></div>

                                    ) : (
                                        <div></div>
                                    )
                                    }
                                    {displayedItems.length > 0 ? (
                                        <table className="table table-bordered table-responsive">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col" >
                                                        <span className="thali_table">
                                                            Req id
                                                        </span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Created By</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Created At</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Published By</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Published At</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Week Start Date</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Week End Date</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Total Ingredients</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="thali_table">Status </span>
                                                    </th>
                                                    <th scope="col"><span className='thali_table'>Actions</span></th>

                                                    {/* <th scope="col">
                                                    <span className="thali_table">Action </span>
                                                </th> */}
                                                </tr>
                                            </thead>
                                            {inventorylistData.map((items) => {
                                                const isActiveClass = items.status === "Pending" ? "Pending" : items.status === "Published" ? "Published" : items.status === "N/A" ? "nocolor" : "";

                                                
                                                
                                                
                                                const formattedDate = new Date(convertDateForIos(items?.start_date)).toLocaleDateString();
                                                const formattedenddate = new Date(convertDateForIos(items?.end_date)).toLocaleDateString();
                                                const formattedCreatedDate = new Date(convertDateForIos(items?.created_date)).toLocaleDateString();
                                                console.log ("items?.updated_date.split(/[- :]/)[0]", items?.updated_date.split(/[- :]/)[0])
                                                const formattedUpdatedDate =  items?.updated_date.split(/[- :]/)[0] !== "00" ? new Date(convertDateForIos(items?.updated_date)).toLocaleDateString() : "";


                                                return (
                                                    <tbody key={items.id}>

                                                        <tr>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link >
                                                                        <li onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>{items.thaali_inventory_request_id ? items.thaali_inventory_request_id : '-'}</li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link >
                                                                        <li onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>
                                                                            {items.created_user ? items.created_user : "-"}

                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link >
                                                                        <li onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>
                                                                            {formattedCreatedDate ? formattedCreatedDate : "-"}

                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link >
                                                                        <li onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>
                                                                        {items.updated_user ? items.updated_user : "-"}

                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link >
                                                                        <li onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>
                                                                        {formattedUpdatedDate ? formattedUpdatedDate : "-"}
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </th>

                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link >
                                                                        <li onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>
                                                                            {formattedDate ? formattedDate : "-"}

                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}>
                                                                        <li>{formattedenddate ? formattedenddate : '-'}</li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            {/* <th scope="row" >
                                                                <ul className="thali_menu_list">
                                                                    <Link onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date)}>   <li>
                                                                        {items.ingredient_count ? items.ingredient_count : '-'}
                                                                    </li>
                                                                    </Link>
                                                                </ul>
                                                            </th> */}
                                                            <th scope="row">
                                                                {" "}
                                                                <Link>
                                                                    <button
                                                                    onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)}
                                                                    className="Inv_Count_btn"
                                                                    >
                                                                    {" "}
                                                                    {items.ingredient_count
                                                                        ? items.ingredient_count
                                                                        : "-"}{" "}
                                                                    &nbsp;&nbsp; &nbsp;
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="14"
                                                                        height="14"
                                                                        viewBox="0 0 14 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                        d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                                                                        fill="#957137"
                                                                        />
                                                                    </svg>
                                                                    </button>
                                                                </Link>
                                                                </th>
                                                            <th scope="row" >
                                                                <ul className="thali_menu_list Inventorydata">
                                                                    <Link onClick={() => getInventory(items.thaali_inventory_request_id, items.status ,items.ingredient_count, items.start_date, items.end_date, items.current_week_ids)} > <li>

                                                                        <span className={isActiveClass}> {items.status ? items.status : '-'} </span>

                                                                    </li>
                                                                    </Link>
                                                                </ul>
                                                            </th>
                                                            <th scope="row">
                                  <ul className='thali_menu_list'>
                                  { (user_Role.includes("ADM") || user_Role.includes("PRM") || user_Role.includes("PRO") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                                    <li>
                                      <div className="dropdown action_btn">
                                        <button
                                          className="btn btn-secondary dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton2"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fa fa-ellipsis-h"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton2"
                                        >
                                          <li className="deletedthali" onClick={() => performDeleteInventory(items.thaali_inventory_request_id)}>
                                            <a
                                              className="dropdown-item"

                                            >
                                              {" "}
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="20"
                                                viewBox="0 0 18 20"
                                                fill="none"
                                              >
                                                <path
                                                  d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                                  fill="#777391"
                                                />
                                              </svg>
                                              &nbsp; &nbsp; &nbsp;Remove Inventory
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  }
                                  </ul></th>
                                                        </tr>
                                                    </tbody>
                                                );
                                            })}
                                        </table>
                                    )
                                        : (
                                            // Render when Inventorydata is empty
                                            <div className="No_Inventory">
                                                <h1 >No Inventory Data Available for Next Week </h1>
                                            </div>
                                        )}
                                </div>

                                <nav>
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={handleFirstPage}>
                                                First
                                            </button>
                                        </li>
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Previous
                                            </button>
                                        </li>

                                        {getPageNumbers().map((pageNumber) => (
                                            <li
                                                className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                                                key={pageNumber}
                                            >
                                                <button
                                                    className="page-link pagebutton"
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </button>
                                            </li>
                                        ))}

                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Next
                                            </button>
                                        </li>
                                        <li
                                            className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                                        >
                                            <button className="page-link" onClick={handleLastPage}>
                                                Last
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
