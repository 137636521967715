import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import "../Dashboard.css";
import remove from "../../../img/remove.png";
import right from "../../../img/Icon/right.svg";
import userprofilelist from "../../../img/userprofilelist.png";
import multiuser from "../../../img/multiuser.png";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { createcaterer,user_catera, getCatererList, updatecaterer, fetchAllUserData, Deactivecaterer, api_url, fmb_schedule_url, user_management } from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import loadergif from '../../../img/landing/loader123.gif';
import Select from "react-select";
export default function Caterer() {
    const user_Role = localStorage.getItem("access_Role");
    var token = localStorage.getItem("access_token");
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    let subtitle;
    const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
    const [RemoveuserIsOpen, setIsRemoveuserOpen] = React.useState(false);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [email, setEmail] = useState("");
    const [teamoption, setTeamoption] = useState("No");
    const [updateteamoption, setUpdateteamoption] = useState(false);
    const [catererdata, setCatererdata] = useState([]);
    const [userdatalist, setUserDataList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [inputvalue, setInputValue] = useState('');
    const [id, setId] = useState('');
    const dispatch = useDispatch();
    const [sortColumn, setSortColumn] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('asc');
    const [validationerror, setValidationerror] = useState(false)
    const [loading, setLoading] = useState(true);
    const [totalcount, setTotalCount] = useState('');
    const handleSort = (columnName) => {
        if (sortColumn === columnName) {
            setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(columnName);
            setSortOrder('asc');
        }
    };
    const getSortIcon = (columnName) => {
        if (sortColumn === columnName) {
            return sortOrder === 'asc' ? <i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>;
        }
        return null;
    };


    const sortcatererdata = [...catererdata].sort((a, b) => {

        if (sortColumn === 'Name') {
            const nameA = a.first_name.toLowerCase();
            const nameB = b.first_name.toLowerCase();
            if (nameA < nameB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (nameA > nameB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }
        // Handle sorting for other columns if needed
        return 0;
    });


    const handleToggleChange = (e) => {
        const newValue = teamoption === "Yes" ? "No" : "Yes";
        setTeamoption(newValue);

    };

    const handleToggleChange2 = (e) => {
        const checked = e.target.checked;
        const value = checked ? "Yes" : "No";
        setUpdateteamoption(value);
        switch (checked) {
            case true:
                // Handle the checked state
                console.log('Checkbox is checked');
                // Perform update or other actions here
                break;
            case false:
                // Handle the unchecked state
                console.log('Checkbox is unchecked');
                // Perform update or other actions here
                break;
            default:
                // Handle any other case (optional)
                console.log('Checkbox state is unknown');
                // Perform update or other actions here
                break;
        }
    };

    function RemoveModal(e) {
        e.preventDefault();
        setIsRemoveOpen(true);
    }

    function RemoveOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function RemovecloseModal() {
        setIsRemoveOpen(false);
    }

    function RemoveuserModal() {
        setIsRemoveuserOpen(true);
    }

    function RemoveuserOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function RemoveusercloseModal() {
        setIsRemoveuserOpen(false);
    }

    const getCatererData = () => {
        setLoading(true);
        dispatch(getCatererList()).then((res) => {
            if (res?.payload?.status == true) {
                setCatererdata(res?.payload?.data);
                setTotalCount(res?.payload?.count);
            } else {
                toast.error(res?.payload?.message);
            }
        }).finally(() => {
            setLoading(false); // Set loading to false when data fetching is complete
        });
    };

    const access_id = localStorage.getItem("access_id");
    const caterersid = (id) => {
        const requestBody = {
            caterer_id: id
        };

        fetch(user_catera + 'admin/caterers-active', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access_token")
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.status === true) {
                    // Handle successful response
                    // Update state or perform any necessary actions
                    //   setCaterer(data.data);
                    getCatererData();
                    console.error(data, 'ssss');
                } else {
                    // Handle error response
                    toast.error(data?.message);
                }
            })
            .catch(error => {
                // Handle any errors that occur during the request
                console.error(error);
            });
    };
    const mobilenumberregex = /^\d{10}$/;
    const createcatererdata = (e) => {
        e.preventDefault();


        const catererlist = {
            "first_name": firstname,
            "last_name": lastname,
            "group_name": "",
            "is_team_lead": teamoption,
            "is_active": "1",
            "created_user": access_id,
            "source": "web",
            "email": email,
            "phone_number": phoneno
        }

        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access_token")
            },
        };

        if (!firstname ||
            !lastname ||
            !teamoption ||
            !email ||
            !userdataview ||
            !phoneno
        ) {
            setValidationerror(true)
            return false
        }
        else {
            dispatch(createcaterer(catererlist, config)).then((res) => {
                console.log('eee', res)
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    getCatererData();
                    setFirstname('');
                    setLastname('');
                    setEmail('');
                    setPhoneno('');
                    handleSearch();
                    //         const offcanvas = document.getElementById("offcanvasRight");
                    // if (offcanvas) {
                    //   offcanvas.classList.remove("show"); // Remove the "show" class from the off-canvas element

                    //   // Find and remove the backdrop element
                    //   const backdrop = document.querySelector(".offcanvas-backdrop.fade.show");
                    //   if (backdrop) {
                    //     backdrop.classList.remove("show");
                    //     // backdrop.remove(); // Remove the backdrop element from the DOM
                    //   }
                    // }
                    const modal_close = document.getElementById("modal_close_btn").click();
                    setValidationerror(false)
                } else {
                    toast.error(res?.payload?.message);
                }
            });
            setFirstname('');
            setLastname('');
            setEmail('');
            setPhoneno('');
        };
    }



    const caterUpdatedata = (e) => {
        e.preventDefault();
        const catererlist = {
            "first_name": firstname,
            "last_name": lastname,
            "group_name": "3",
            "is_team_lead": updateteamoption,
            "is_active": "1",
            "created_user": access_id,
            "source": "web",
            "email": email,
            "phone_number": phoneno,
            "caterer_id": id,
        }

        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access_token")
            },
        };
        if (!firstname ||
            !lastname ||
            !teamoption
            // !email ||
            // !userdataview ||
            //  updateImage === null || 
            // !mobilenumberregex.test(phoneno)
        ) {
            setValidationerror(true)
            return false
        }
        else {
            dispatch(updatecaterer(catererlist, config)).then((res) => {
                console.log('eee', res)
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    getCatererData();

                    //     const offcanvas = document.getElementById("offcanvasRight_update");
                    // if (offcanvas) {
                    //   offcanvas.classList.remove("show"); // Remove the "show" class from the off-canvas element

                    //   // Find and remove the backdrop element
                    //   const backdrop = document.querySelector(".offcanvas-backdrop.fade.show");
                    //   if (backdrop) {
                    //     backdrop.classList.remove("show");
                    //     // backdrop.remove(); // Remove the backdrop element from the DOM
                    //   }
                    // }

                    const modal_close2 = document.getElementById("modal_close_btn2").click();
                    setValidationerror(false)
                } else {
                    toast.error(res?.payload?.message);
                }
            });
        };
    }
    useEffect(() => {
        // createcatererdata();
        getCatererData();
        getUserData();
    }, []);


    console.log('catererdata', catererdata);
    const dataset = (items) => {
        console.log(items, 'item');
        setFirstname(items?.first_name);
        setLastname(items?.last_name);
        setEmail(items?.email);
        setPhoneno(items?.phone_number);
        setUpdateteamoption(items?.is_team_lead);
        setId(items?.id);
    }
    console.log(updateteamoption, 'setInputValue');


    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = async () => {


        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access_token")
            };
            const response = await fetch(
                user_catera + `admin/search-caterer?caterers=${encodeURIComponent(searchQuery)}`,
                {
                    method: "GET",
                    headers: headers,
                });
            if (response.ok) {
                const data = await response.json();
                console.log('data:', data); // Check the structure of the received data
                if (data.data && Array.isArray(data.data)) {
                    setCatererdata(data?.data);
                    // Set the total number of pages
                    console.log('Invali:', data?.data);
                } else {
                    console.log('Invalid data format:', data?.data);
                    setCatererdata(data);
                }
            } else {
                console.log('Error: ', response?.status);
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    useEffect(() => {
        if (searchQuery) {
            handleSearch();
        }

        else {
            getCatererData();
        }
    }, [searchQuery]);


    const [totalPages, setTotalPages] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchData(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchData(pageNumber);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
        fetchData(totalPages);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 10; // Maximum number of visible pages

        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };
    const fetchData = async (page) => {


        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access_token")
            };
            // Fetch data for the specified page
            const response = await fetch(user_catera + `admin/caterer-list?page=${page}`, {
                method: "GET",
                headers: headers,
            });
            if (response.ok) {
                const responseData = await response.json();

                if (responseData && responseData?.data && Array.isArray(responseData.data.dish) && responseData.totalPages) {
                    const { Catererdata, totalPages } = responseData.data;

                    // Set the fetched data and total pages
                    setCatererdata(Catererdata);
                    setTotalPages(totalPages);
                } else {
                    setCatererdata(responseData?.data);
                    setTotalPages(responseData?.totalPages);
                }
            } else {
                console.log('Error fetching data:', response?.status);
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    useEffect(() => {
        fetchData(currentPage); // Fetch data for the initial page
    }, []);




    const [searchCriteriaUser, setSearchCriteria] = useState('');
    const [searchUser, setSearchUser] = useState('');

    const handleInputChange = (inputValue) => {
        console.log('Input Value:', inputValue);
        setSearchUser(inputValue);
        handleSearchuser(inputValue);
    };
    const handleSearchuser = async (inputValue) => {




        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access_token")
            };
            const response = await fetch(user_management + `admin/search-user?user=${encodeURIComponent(inputValue)}`, {
                method: "GET",
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();
                if (data?.data && Array.isArray(data?.data)) {
                    setUserDataList(data?.data);
                } else {
                    setUserDataList([]);
                }
            } else {
                console.log('Error: ', response?.status);
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    const getUserData = () => {
        dispatch(fetchAllUserData()).then((res) => {
            if (res?.payload?.status === true) {
                setUserDataList(res?.payload?.data);
            } else {
                toast.error(res?.payload?.message);
            }
        });
    };
    useEffect(() => {
        if (searchUser) {
            handleSearchuser();
        }
    }, [searchUser]);
    console.log('userdatalist', userdatalist)

    const userlistall = userdatalist.map((item) => {
        return {
            label: item.first_name+' '+item.last_name,
            firstname: item.first_name,
            value: item.id,
            email: item.email,
            phone: item.phone,
            lname: item.last_name,

        };
    });
    const [userdataview, setUserDataView] = useState([]);
    console.log('userdataview', userdataview )
    useEffect(() => {
        setFirstname(userdataview?.firstname);
        setLastname(userdataview?.lname);
        setEmail(userdataview?.email);
        setPhoneno(userdataview?.phone);

    }, [userdataview]);

    const carteerlist = totalcount ? totalcount : '';
    return (
        <div> <Header />
            <div
                className="side_div_menu"
                style={{ display: "flex", height: "100%" }}
            >
                <Sidebarmenu />
                <section className="main_section">
                    <div className="custam">
                        <div className="main_section2">
                            <div className="ExportInventory thali_section Thali_page">
                                <div className="container-fluid">
                                    <div className="thili_div">
                                        <div className="Back-page">
                                            {" "}
                                            {/* <Link to="/InventoryFMB">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                                                        fill="#777391"
                                                    />
                                                </svg>
                                            </Link> */}
                                            <h1 className="title_heading">
                                                Caterer({carteerlist ? carteerlist : ''})
                                            </h1>{" "}
                                        </div>
                                        { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM")) &&
                                        <div className="Thali_page_btn">
                                            <button
                                                className="btn thali_btn"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasRight"
                                                aria-controls="offcanvasRight"
                                            >
                                                Add New
                                            </button>
                                            &nbsp;
                                            {/* <button onClick={RemoveModal}>sss</button> */}
                                        </div>
                                      }
                                    </div>
                                </div>
                                <div className="thali_count_list">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Caterer"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="thali_table_section">
                                    {loading ? (
                                        // Render a loading indicator while data is being fetched
                                        <div className='Loading-img'><img src={loadergif} className='' /></div>
                                    ) : (
                                        <div></div>
                                    )
                                    }
                                    <table className="table table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col" onClick={() => handleSort('Name')}>
                                                    <span className="thali_table">
                                                        First Name &nbsp; &nbsp; &nbsp;
                                                        {getSortIcon('Name')}
                                                    </span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Last Name</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Team Lead</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Phone</span>
                                                </th>
                                                <th scope="col">
                                                    <span className="thali_table">Eamil</span>
                                                </th>

                                                <th scope="col">
                                                    <span className="thali_table">Action</span>
                                                </th>
                                                {/* <th scope="col"><span className='thali_table'>Current Qty/Unit</span></th>
                                          <th scope="col"><span className='thali_table'>Order PKG COUNT/TYPE</span></th> */}
                                            </tr>
                                        </thead>
                                        {sortcatererdata.map((items) => {
                                            const isActiveClass = items.is_active == "0" ? "active" : "";
                                            const isActivetext = items.is_active == "0" ? "Active" : "Deactive ";
                                            return (
                                                <tbody key={items.id}>

                                                    <tr>
                                                        <th scope="row" className={isActiveClass}>
                                                            <ul className="thali_menu_list">
                                                                <li>{items.first_name ? items.first_name : "-"}</li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" className={isActiveClass}>
                                                            <ul className="thali_menu_list">
                                                                <li>
                                                                    {items.last_name ? items.last_name : "-"}
                                                                </li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" className={isActiveClass}>
                                                            <ul className="thali_menu_list">
                                                                <li>
                                                                    <div className="Register_user_input">
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                               disabled
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id="rice_check"
                                                                                // value={items.is_team_lead}
                                                                                checked={items.is_team_lead == "Yes" ? 1 : 0}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" className={isActiveClass}>
                                                            <ul className="thali_menu_list">
                                                                <li>{items.phone_number ? items.phone_number : "-"}</li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row" className={isActiveClass}>
                                                            <ul className="thali_menu_list">
                                                                <li>{items.email ? items.email : "-"}</li>
                                                            </ul>
                                                        </th>
                                                        <th scope="row">
                                                            <ul className="thali_menu_list">
                                                            { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM")) &&
                                                                <li>
                                                                    <div className="dropdown action_btn">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle"
                                                                            type="button"
                                                                            id="dropdownMenuButton2"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i
                                                                                className="fa fa-ellipsis-h"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </button>
                                                                        <ul
                                                                            className="dropdown-menu"
                                                                            aria-labelledby="dropdownMenuButton2"
                                                                        >
                                                                            <li onClick={() => dataset(items)}>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    href="#"
                                                                                    data-bs-toggle="offcanvas"
                                                                                    data-bs-target="#offcanvasRight_update"
                                                                                    aria-controls="offcanvasRight_update"

                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="19"
                                                                                        height="15"
                                                                                        viewBox="0 0 19 15"
                                                                                        fill="none"
                                                                                    >
                                                                                        <path
                                                                                            d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                                                            fill="#777391"
                                                                                        />
                                                                                    </svg>{" "}
                                                                                    &nbsp; &nbsp;Edit Details
                                                                                </a>
                                                                            </li>
                                                                            <li onClick={() => caterersid(items.id)}>
                                                                                <a
                                                                                    className="dropdown-item"

                                                                                >
                                                                                    {" "}
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="18"
                                                                                        height="20"
                                                                                        viewBox="0 0 18 20"
                                                                                        fill="none"
                                                                                    >
                                                                                        <path
                                                                                            d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                                                                            fill="#777391"
                                                                                        />
                                                                                    </svg>
                                                                                    &nbsp; &nbsp; &nbsp;{isActivetext}
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                }
                                                            </ul>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            );
                                        })}
                                    </table>
                                    {/* Pagination */}

                                </div>
                                <nav>
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={handleFirstPage}>
                                                First
                                            </button>
                                        </li>

                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                Previous
                                            </button>
                                        </li>

                                        {getPageNumbers().map((pageNumber) => (
                                            <li
                                                className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                                key={pageNumber}
                                            >
                                                <button className="page-link" onClick={() => handlePageChange(pageNumber)}>
                                                    {pageNumber}
                                                </button>
                                            </li>
                                        ))}

                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                                Next
                                            </button>
                                        </li>

                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={handleLastPage}>
                                                Last
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/* popup */}
            <div
                className="offcanvas offcanvas-end Dishes_canvas vendor_canvas Cartere_canvas"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Add Caterer{" "}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="offcanvas-body">
                    <div className="Dish_Name_list">
                        <form className="row g-3" >
                            <div className="col-md-12">
                                <div className="Form_box">
                                    <label for="Name" className="form-label">
                                        {" "}
                                        Search  User
                                    </label>
                                    {/* <Select
                                        options={userlistall}
                                        onInputChange={handleInputChange}
                                        onChange={(selectedOption) => handleSearchuser(selectedOption.value)}
                                        placeholder="Search User..."
                                        value={userdataview}
                                    /> */}

                                    <Select
                                        options={userlistall}
                                        onChange={(e) => setUserDataView(e)}
                                        value={userdataview}
                                        isClearable
                                    />
                                    <ul>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Name" className="form-label">
                                        {" "}
                                        First  Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="First  Name"
                                        className={`${validationerror && !firstname ? "validation_valid form-control" : "form-control"}`}
                                        id="Name"
                                        onChange={(e) => setFirstname(e.target.value)}
                                        value={firstname}
                                    />
                                    {
                                        validationerror && !firstname &&
                                        <p className="show_invalid_text" >Name is required</p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Last Name" className="form-label">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        className={`${validationerror && !lastname ? "validation_valid form-control" : "form-control"}`}
                                        id="Last Name"
                                        onChange={(e) => setLastname(e.target.value)}
                                        value={lastname}
                                    />
                                    {
                                        validationerror && !lastname &&
                                        <p className="show_invalid_text" >Last Name is required</p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Email" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        type="Email"
                                        placeholder="Enter Email"
                                        className={`${validationerror && !email ? "validation_valid form-control" : "form-control"}`}
                                        id="Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                    {
                                        validationerror && !email &&
                                        <p className="show_invalid_text" >email is required</p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="ContactPhone" className="form-label">
                                        Phone Number
                                    </label>
                                    <input
                                        placeholder="Enter phone number"
                                        className={`${validationerror && !mobilenumberregex.test(phoneno) ? "validation_valid form-control" : "form-control"}`}
                                        id="ContactPhone"
                                        onChange={(e) => setPhoneno(e.target.value)}
                                        value={phoneno}
                                    />
                                    {
                                        validationerror && !mobilenumberregex.test(phoneno) &&
                                        <p className="show_invalid_text" >Phone Number is required</p>
                                    }
                                </div>
                            </div>
                            {/* <div className="col-md-6"></div> */}
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Team Lead" className="form-label">
                                        Team Lead
                                    </label>
                                    <ul className="thali_menu_list">
                                        <li>
                                            <div className="Register_user_input">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="roti_check"
                                                        checked={teamoption === "Yes"}
                                                        onChange={handleToggleChange}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Ingredients_title">
                                <h1></h1>
                                <div className="Save_btn">
                                    <button type="button"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close" id="modal_close_btn">Cancel</button>
                                    <button type="button" onClick={createcatererdata}>Add user</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>




            <div
                className="offcanvas offcanvas-end Dishes_canvas vendor_canvas Cartere_canvas"
                tabindex="-1"
                id="offcanvasRight_update"
                aria-labelledby="offcanvasRight_update"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Edit user{" "}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <div className="Dish_Name_list">
                        <form className="row g-3">
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Name" className="form-label">
                                        {" "}
                                        First  Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="First  Name"
                                        className={`${validationerror && !firstname ? "validation_valid form-control" : "form-control"}`}
                                        id="Name"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                    />
                                    {
                                        validationerror && !firstname &&
                                        <p className="show_invalid_text" >Name is required</p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Last Name" className="form-label">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        className={`${validationerror && !lastname ? "validation_valid form-control" : "form-control"}`}
                                        id="ITS number"
                                        value={lastname}
                                        onChange={(e) => setLastname(e.target.value)}
                                    />
                                    {
                                        validationerror && !lastname &&
                                        <p className="show_invalid_text" >Last Name is required</p>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Email" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        type="Email"
                                        placeholder="Enter Email"
                                        // className={`${validationerror && !email ? "validation_valid form-control" : "form-control"}`}
                                        className="form-control"

                                        id="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {/* {
                                        validationerror && !email &&
                                        <p className="show_invalid_text" >email is required</p>
                                    } */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="ContactPhone" className="form-label ">
                                        Phone Number
                                    </label>
                                    <input
                                        type="number"
                                        placeholder="Enter phone number"
                                        // className={`${ validationerror && !mobilenumberregex.test(phoneno) ? "validation_valid form-control" : "form-control"}`}
                                        id="ContactPhone"
                                        className="form-control"
                                        value={phoneno}
                                        onChange={(e) => setPhoneno(e.target.value)}
                                    />
                                    {/* {
                                        validationerror && !mobilenumberregex.test(phoneno) &&
                                        <p className="show_invalid_text" >Phone Number is required</p>
                                    } */}
                                </div>
                            </div>
                            {/* <div className="col-md-6"></div> */}
                            <div className="col-md-6">
                                <div className="Form_box">
                                    <label for="Team Lead" className="form-label">
                                        Team Lead
                                    </label>
                                    <ul className="thali_menu_list">
                                        <li>
                                            <div className="Register_user_input">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="roti_check"
                                                        value={updateteamoption}
                                                        checked={updateteamoption === "Yes"}
                                                        onChange={handleToggleChange2}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Ingredients_title">
                                <h1></h1>
                                <div className="Save_btn">
                                    <button type="button"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close" id="modal_close_btn2">Cancel</button>
                                    <button type="button" onClick={caterUpdatedata}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
