import React, { useEffect, useState } from "react";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Chicken from "../../../img/Chicken.png";
import Rice from "../../../img/Icon/Rice.svg";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import Drinks from "../../../img/Icon/Drinks.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Kheericon from "../../../img/Icon/Kheer_icon.png";
import soup from "../../../img/Icon/soup.svg";
import user_icon from "../../../img/Icon/user_icon.svg";
import starticon from "../../../img/Icon/starticon.png";
import calender from "../../../img/Icon/calender.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import Select from "react-select";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch } from "react-redux";
import { feed_back, getCatererList, getFeedbackdata } from "../../../Redux/Reducer/AuthReducer";
import { api_url } from "../../../Redux/Reducer/AuthReducer";
import ReactPaginate from 'react-paginate';
import loadergif from '../../../img/landing/loader123.gif';
import ven from "../../../img/ven.png";
export default function Feedback() {
  const user_Role = localStorage.getItem("access_Role");
  var token = localStorage.getItem("access_token");
  const today = new Date();
  const CurrentendOfWeek = new Date(today);
  CurrentendOfWeek.setDate(today.getDate() + (6 - today.getDay()));
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [star, setStar] = useState('')
  const [value, setValue] = useState([])
  const [catererdata, setCatererdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [caterer, setCaterer] = useState('')
  const [startDate, setStartDate] = useState(startOfWeek)
  const [endDate, setEndDate] = useState(CurrentendOfWeek);
  const [rating, setRating] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const pageCount = Math.ceil(value?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > pageCount) {
      setCurrentPage(pageCount);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(pageCount);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const displayedItems = value?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const Starlist = [
    { value: "1", label: "5" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  const getCatererData = () => {
    setLoading(true);
    dispatch(getFeedbackdata())
      .then((res) => {
        setValue(res?.payload?.data);
      })
      .catch((error) => {
        // Handle errors if the fetch operation fails.
        // You can display an error message or handle it as needed.
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const getCatererDataList = () => {
    dispatch(getCatererList()).then((res) => {
      if (res?.payload?.status == true) {
        setCatererdata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  console.log(catererdata, 'catererdata')
  useEffect(() => {
    getCatererData();
    getCatererDataList();
  }, [])
  console.log(value, 'value')

  let Catererlist;
  if (value && Array.isArray(value)) {
    Catererlist = value.map((item) => ({
      label: item.first_name,
      value: item._id,
    }));
  } else {
    Catererlist = [];
  }

  let Caterer;
  if (catererdata && Array.isArray(catererdata)) {
    Caterer = catererdata?.map((item) => ({
      label: item.first_name,
      value: item._id,
    }));
  } else {
    Caterer = [];
  }
  console.log(value, caterer, 'dfdfdfs');

  const handleDateChange = (event) => {
    const selectedDates = event.target.value.split(" - ");
    const selectedStartDate = selectedDates[0];
    const selectedEndDate = selectedDates[1];
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);

    const partsStart = selectedStartDate.split("/");
    const formattedStartDate = `${partsStart[2]}-${partsStart[0].padStart(2, '0')}-${partsStart[1].padStart(2, '0')}`;

    const partsEnd = selectedEndDate.split("/");
    const formattedEndDate = `${partsEnd[2]}-${partsEnd[0].padStart(2, '0')}-${partsEnd[1].padStart(2, '0')}`;

    console.log("Selected Dates:", "startDate =", formattedStartDate, "endDate =", formattedEndDate);

    // Call getdatechange with the formatted start and end dates and the rating
    getdatechange(formattedStartDate, formattedEndDate, rating);
  };


  // Function to make the API request with parameters in the URL
  const getdatechange = (formattedStartDate, formattedEndDate, rating) => {



    // Construct the URL with query parameters
    const url = feed_back + `admin/feedback-get?start_date=${formattedStartDate}&end_date=${formattedEndDate}&dish_rating=${rating}`;

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data?.status === true) {
          console.log('new data ', data.data);
          // Display a success message using toast
          toast.success(data?.message);
          setValue(data.data)
          // Set your data in state or perform other actions here
        } else {
          // Display an error message using toast
          toast.error(data?.message);
        }
      })
      .catch(error => {
        console.error(error);
        // Display a generic error message using toast
        toast.error('An error occurred while fetching data.');
      });
  };

  const Ratingselect = (selectedOption) => {
    setStar(selectedOption)

    getdatechange2(selectedOption)

  }


  const getdatechange2 = (selectedOption) => {
    const starLabel = selectedOption.label;
    // Construct the URL with query parameters
    const url = feed_back + `admin/feedback-get?dish_rating=${starLabel}`;

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",// Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data?.status === true) {
          console.log('new data ', data.data);
          // Display a success message using toast
          toast.success(data?.message);
          setValue(data.data)
          // Set your data in state or perform other actions here
        } else {
          // Display an error message using toast
          toast.error(data?.message);
        }
      })
      .catch(error => {
        console.error(error);
        // Display a generic error message using toast
        toast.error('An error occurred while fetching data.');
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // return empty string if dateString is undefined or empty
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    if (isNaN(day)) {
      return ""; // return empty string if parsing fails
    }

    return `${day} ${month}`;
  };


  const feedback = value !== null && value !== undefined ? value.length : null;

  console.log('feedback', feedback)

  const getFeedbackforSchedule = async (item) => {
    setLoading(true);

    const requestBody = {
      schedule_id: item.schedule_id,
    };

    fetch(feed_back + `admin/getFeedbackByScheduleId?${user_Role.includes("FBM") && 'includeNames=true'}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          const feedbacks = data?.data;
          navigate("/ScheduleFeedback", {
            state: {
              feedbacks: feedbacks,
              item: item
            },
          });
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  }

  return (
    <>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="thali_section">
                <div className="thili_div thali-flex container-fluid">
                  <div className="Back-page">
                    {" "}
                    <h1 className="title_heading">
                      Feedback ({feedback ? feedback : '-'}) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      {/* <span className="Line_div">|</span> */}
                      {/* <span className="Date_list">
                        {formatDate(startDate)}-{formatDate(endDate)}</span>{" "} */}
                    </h1>{" "}
                  </div>
                  <div className="Feedback_list">
                    <div className="Form_box rating_star">

                      <Select
                        placeholder="Rating"
                        onChange={Ratingselect}
                        options={Starlist}
                        // defaultV alue={cleartype}
                        value={star}
                      />
                      {/* <select id="Portion" className="form-select">
                        <option selected>State...</option>
                        <option>...</option>
                      </select> */}
                    </div>
                    {/* <span>|</span> */}
                    {/* <div className="Form_box rating_star">
                      <Select
                        placeholder="Caterers"
                        onChange={(e) => setCaterer(e.label)}
                        options={Caterer}

                        value={Caterer.value}

                      />
                    </div> */}
                    <span>|</span>
                    <div className="Form_box">
                      <div className='Calender_div'>
                        <img src={calender} className='img-fluid' /> &nbsp; &nbsp;
                        <DateRangePicker
                          initialSettings={{ startDate: startDate, endDate: endDate }}
                          // onDateChange={handleDateChange}
                          onApply={handleDateChange}
                        >
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={startDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thali_table_section ">
                {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className='Loading-img'><img src={loadergif} className='' /></div>

                  ) : (
                    <div></div>
                  )
                  }
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">
                          <span className="thali_table">Day & Date</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Menu</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Caterers</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Taste</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Quantity</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Dish</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Notes</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedItems && displayedItems?.map((item) => {
                        const date = new Date(item.schedule_day);

                        const day = date.toLocaleString('en-US', { weekday: 'long' });
                        const month = date.toLocaleString('en-US', { month: 'long' });
                        const numericDate = date.getDate();
                        const twoDigitYear = date.getFullYear();
                        return (
                          <tr key={item.id}>
                            <th scope="row">
                              <div className="Calendar_card">


                                {" "}
                                <h6>{day}</h6>
                                <h1>{numericDate}</h1>
                                <p>{month}, {twoDigitYear}</p>
                              </div>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                {item.save_draft == "1" && (
                                  <span className="Draft_thali_event">
                                    {item.save_draft == "1" ? "Draft" : ""}
                                  </span>
                                )}
                                {item.schedule_type == "MIQAAT" && item.is_niyaaz != '1' && (
                                  <span className="Draft_MIQAAT_event">
                                    {item.schedule_type == "MIQAAT" ? "MIQAAT" : ""}
                                  </span>
                                )}
                                {item.schedule_type == "MIQAAT" && item.is_niyaaz == "1" && (
                                  <span className="Draft_NIYAAZ_event">
                                    {item.schedule_type == "MIQAAT" && item.is_niyaaz == "1" ? "PRIVATE" : ""}
                                  </span>
                                )}
                                {item.schedule_type == "FMBTHALI" && item.is_niyaaz == "1" && (
                                  <span className="Draft_NIYAAZ_event">
                                    {item.schedule_type == "FMBTHALI" && item.is_niyaaz == "1" ? "MIQAAT-THALI" : ""}
                                  </span>
                                )}
                                {item.menu &&
                                  JSON.parse(item.menu).map((menu, index) => (
                                    <li key={index}>
                                        {menu ? menu : "-"}
                                    </li>
                                  ))}
                              </ul>
                            </th>
                            <th scope="row">
                            { item.jamanType == "COOKING" &&
                              <ul className='thali_menu_list'>
                                {item.cater_name1 === item.cater_name1 && (
                                  <li><img src={user_icon} className='img-fluid' />&nbsp; &nbsp;{item.cater_name1 ? item.cater_name1 : "NA"}</li>)}
                                {item.cater_name2 !== null && (
                                  <li><img src={user_icon} className='img-fluid' />&nbsp; &nbsp;{item.cater_name2 ? item.cater_name2 : 'NA'}</li>)}
                              </ul>
                            }
                            {item.jamanType == "ORDERING" &&
                             <ul className="thali_menu_list">
                                <li>
                                  {item.jamanType == "ORDERING" && (
                                 <span className="Draft_ordering_event">
                                  {item.jamanType == "ORDERING" ? "ORDERING" : ""}
                                </span>
                              )}
                                </li>
                                <>
                                  <li>
                                    <img
                                      src={ven}
                                      className="img-fluid"
                                      width="16px"
                                      height="16px"
                                    />
                                    &nbsp; &nbsp; 
                                    {item.order_by
                                      ? item.order_by
                                      : "-"}
                                  </li>
                                </>
                             </ul>
                              }
                              </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li style={{display: 'flex', flexDirection: "column"}}>
                                  <div>
                                    <img
                                      src={starticon}
                                      className="img-fluid"
                                    />
                                    &nbsp;&nbsp;&nbsp;{item.taste_rating ? item.taste_rating.toFixed(1) : '-'}  &nbsp;&nbsp; &nbsp;
                                  </div>
                                  { user_Role.includes("ADM") ?
                                  <Link>
                                      <button
                                        onClick={() => getFeedbackforSchedule(item)}
                                        className="Count_btn"
                                      >
                                        {" "}
                                        {item.feedback_count ? item.feedback_count : "-"}{" "}&nbsp;
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                                            fill="#957137"
                                          />
                                        </svg>
                                      </button>
                                    </Link> :
                                    <>
                                      <button
                                        onClick={(e) => e.preventDefault()}
                                        className="Count_btn"
                                      >
                                        {" "}
                                        {item.feedback_count ? item.feedback_count : "-"}{" "}&nbsp;
                                      </button>
                                    </>
                                  }
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <img
                                    src={starticon}
                                    className="img-fluid"
                                  />
                                  &nbsp;&nbsp;&nbsp;{item.quantity_rating.toFixed(1)}{" "}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                 <img
                                    src={starticon}
                                    className="img-fluid"
                                  />
                                  &nbsp;&nbsp;&nbsp;{item.dish_rating ? item.dish_rating.toFixed(1) : '-'}{" "}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul>
                                <p>
                                  <div>
                                  <div style={{ fontSize: 14, width: "370px", fontWeight: '420', color: "#2E294E" }}>{item.comment ? item.comment : '-'}</div>
                                  </div>
                                </p>
                              </ul>
                            </th>
                          </tr>
                        )
                      })}



                    </tbody>
                  </table>
                </div>
                <div className="feedback_page">
                  <nav>
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleFirstPage}>
                          First
                        </button>
                      </li>
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>

                      {getPageNumbers().map((pageNumber) => (
                        <li
                          className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                          key={pageNumber}
                        >
                          <button
                            className="page-link pagebutton"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      ))}

                      <li
                        className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                      <li
                        className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                      >
                        <button className="page-link" onClick={handleLastPage}>
                          Last
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
