import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../img/AENLOGO-15.png";
import fmbAenLogo from "../../img/AEN-FMB.png";
import logoheading from "../../img/logoheading.png";
import logoheading2 from "../../img/logoheading2.png";
import mnu from "../../img/mnu.png";
import { useFormik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import validationSchema from "../validationSchema";
import {
  Loginuser,
  api_url,
  fmb_schedule_url,
  user_management,
} from "../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { useRef } from "react";
const validate = (values) => {
  const errors = {};

  // if (!values.email) {
  //   errors.email = "Enter your ITS ID";
  // } else if (values.email.length < 1) {
  //   errors.email = "Must be 15 characters or less";
  // }
  if (!values.password) {
    errors.password = "Enter Your password";
  } else if (values.password.length < 1) {
    errors.password = "Must be 15 characters or less";
  }

  return errors;
};
export default function Login() {
  const iframeRef = useRef(null);
  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const dispatch = useDispatch();
  const [ViewIsOpen, setIsViewOpen] = React.useState(false);
  const [showTab1, setShowTab1] = useState(false);
  const [showTab2, setShowTab2] = useState(false);
  const [nonItsLogin, setNonItsLogin] = useState(false);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [errorMesg, setErrorMesg] = useState("");
  const [auth, setAuth] = useState("");
  const navigate = useNavigate();
  const [EventIsOpen, setIsEventOpen] = React.useState(false);
  const location = useLocation();

  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      history("/Landingpage");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      its_no: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      dispatch(Loginuser(values))
        .then((res) => {
          console.log("ss", res);

          if (res.payload.status == true) {
            console.log("ss", res);
            let access_token = res.payload.token;
            let type = res.payload.data.first_name;
            let lastuser = res.payload.data.last_name;
            let roletype = res.payload.data.roles;
            let accessid = res.payload.data.id;
            let itsid = res.payload.data.its_id;
            if (access_token != "" && access_token != "") {
              localStorage.setItem("access_token", access_token);
              localStorage.setItem("author_type", type);
              localStorage.setItem("author_last", lastuser);
              localStorage.setItem("access_Role", roletype);
              localStorage.setItem("access_id", accessid);
              localStorage.setItem("its_id", itsid);
              // toast.success(res.payload.message);

              if (roletype.includes("GEN")) {
                navigate("/Thaali-reg");
              } else {
                navigate("/Landingpage");
              }
            }
          } else {
            // toast.error(res.payload.message);
          }
        })
        .catch((error) => {
          console.log("ss", error);
        });
    },
  });
  function EventModal() {
    setIsEventOpen(true);
    console.log("ff");
  }

  function EventcloseModal() {
    setIsEventOpen(false);
  }
  function EventOpenModal() {}

  const getIframeUrl = () => {
    if (iframeRef.current) {
      const iframeUrl = iframeRef.current.src;
      console.log("Iframe URL:", iframeUrl);
      // Do something with the URL, e.g., store it in state or use it as needed
    }
  };

  const handleIframeLoad = () => {
    try {
      const iframeElement = iframeRef.current;

      // Access the contentWindow of the iframe
      const iframeWindow = iframeElement.contentWindow.location.href;

      // Access the URL of the iframe content
      const iframeWindowUrl = iframeWindow;
      console.log("URL of the iframe content:", iframeWindowUrl);
    } catch (error) {
      console.error("Error accessing iframe content:", error);
    }
  };

  const getnewitschange = () => {
    // Get the access token from localStorage
    const datatokan = localStorage.getItem("access_DT");
    // Check if the access token exists
    if (datatokan) {
      // Create the request body with the access token
      const requestBody = {
        its_token: datatokan,
      };

      // Make the getloginchange request with the access token
      getloginchange(requestBody);
    } else {
      // Handle the case where the access token is not found in localStorage
      console.error("Access token not found in localStorage");
      // You can add further error handling or redirect the user as needed
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_DT")) {
      getnewitschange();
    }
  }, []);
  const getloginchange = (requestBody) => {
    fetch(user_management + "admin/its-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data new", data);

          let access_token = data?.token;
          let type = data?.data?.first_name;
          let lastuser = data?.data?.last_name;
          let roletype = data?.data?.roles;
          let accessid = data?.data?.id;
          if (access_token != "" && access_token != "") {
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("author_type", type);
            localStorage.setItem("author_last", lastuser);
            localStorage.setItem("access_Role", roletype);
            localStorage.setItem("access_id", accessid);
            roletype == "GEN"
              ? navigate("/Thaali-reg")
              : navigate("/Landingpage");
          }
        } else {
          //  toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //   const storedURL = localStorage.getItem('currentURL');
  // console.log("storedURL" ,storedURL ? storedURL :'dddd');
  const [showNonItsLogin, setShowNonItsLogin] = useState(false);
  const [showNonItsFrom, setShowNonIts] = useState(true);

  const handleNonItsLoginChange = (event) => {
    setShowNonItsLogin(event.target.checked);
  };

  console.log("showNonItsLogin", showNonItsLogin);
  return (
    <div>
      <section className="Login_page">
        <ToastContainer />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <div className="login_bg">
                <div className="log_in_page">
                  <div className="login_info">
                    <img src={logo} className="img-fluid logo-main-res" />
                  </div>
                  <div className="login_info">
                    <img src={fmbAenLogo} className="img-fluid logo-res" />
                  </div>
                  <div className="login_info">
                    <img
                      src={logoheading}
                      className="img-fluid design-img login_text"
                    />
                    <ul className="login_text">
                      <li>
                        <div>
                          {" "}
                          <img src={mnu} className="img-fluid login_text" />
                        </div>{" "}
                        To continue, please login using HOF ITS Id and Password.
                      </li>
                      {/* <li>
                        <div>
                          {" "}
                          <img src={mnu} className="img-fluid" />
                        </div>{" "}
                        Don't forget to wash your hands before you indulge in
                        your healthy tiffin.
                      </li> */}
                    </ul>
                    <img
                      src={logoheading2}
                      className="img-fluid design-img login_text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <form
                className={`Login_form ITS-Link ${
                  showNonItsLogin ? "d-none" : ""
                }`}
              >
                <h1>FAIZUL MAWAID IL BURHANIYAH</h1>
                <h1>Dallas Jamaat</h1>
                <div className="form-group">
                  <a
                    className="Link-its"
                    href="https://www.its52.com/Login.aspx?OneLogin=AND"
                  >
                    Click here to Login with Your ITS ID & Password
                  </a>

                  <div className="Form_box THALI-REGISTERED-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="s_active"
                      onChange={handleNonItsLoginChange}
                      checked={showNonItsLogin}
                    />
                    <label for="ItsId" className="form-label">
                      NON ITS LOGIN
                    </label>
                  </div>
                </div>

                {/* <div className="form-group">
                  <Link to="/register">
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary mr-1 submit_btn"
                      onClick={() => Login}
                    >
                      Create New Account
                    </button>
                  </Link>
                </div> */}
              </form>

              {showNonItsLogin && (
                <form className="Login_form" onSubmit={formik.handleSubmit}>
                  <h1>Login into your account</h1>

                  <div className="form-group">
                    <label>
                      ITS ID<span>*</span>
                    </label>
                    <input
                      name="its_no"
                      placeholder="Enter your ITS ID"
                      autocomplete="off"
                      type="text"
                      className={`form-control ${
                        formik.errors.its_no && formik.touched.its_no
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.its_no}
                      onChange={formik.handleChange}
                    />
                    {/* {formik.errors.email && formik.touched.email && (
                  <p className="error">{formik.errors.email}</p>
                )} */}
                  </div>
                  <div className="form-group">
                    <label>
                      Password<span>*</span>
                    </label>
                    <input
                      name="password"
                      placeholder="Enter Password"
                      type="password"
                      autocomplete="off"
                      className={`form-control ${
                        formik.errors.password && formik.touched.password
                          ? "is-invalid"
                          : ""
                      }`}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <p className="error">{formik.errors.password}</p>
                    )}
                  </div>
                  {/* <a

                className="forget"
                href="https://www.its52.com/Login.aspx?OneLogin=AND"
              // onClick={getnewitschange}
              >
                <h4>Click here to Login with Your ITS ID & Password</h4>
              </a> */}
                  {/* {storedURL} */}
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary mr-1 submit_btn"
                    >
                      Login
                    </button>
                  </div>
                  <div className="Checkbox-hide">
                    <div className="Form_box THALI-REGISTERED-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="s_active"
                        onChange={handleNonItsLoginChange}
                        checked={showNonItsLogin}
                      />
                      <label for="ItsId" className="form-label">
                        NON ITS LOGIN
                      </label>
                    </div>
                  </div>
                  {/* <div className="form-group">
                <Link to="/register">
                  {" "}
                  <button
                    type="submit"
                    className="btn btn-primary mr-1 submit_btn"
                    onClick={() => Login}
                  >
                    Create New Account
                  </button>
                </Link>
              </div> */}
                </form>
              )}

              {/* <h5 class="version">v1.5.3</h5> */}
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={EventIsOpen}
        onAfterOpen={EventOpenModal}
        onRequestClose={EventcloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="Event_popup"
      >
        <div className="popup_modal dish-viwe">
          <div>
            <button onClick={EventcloseModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                  fill="#2E294E"
                />
              </svg>
            </button>
          </div>

          <div className="">
            {/* 
        <iframe
    src="https://www.its52.com/Login.aspx?OneLogin=AND"
    title="Full Screen Iframe"
    ref={iframeRef}
    allowFullScreen
    onLoad={handleIframeLoad}
    className="full-screen-iframe"
  ></iframe> */}
            {/* <button onClick={getIframeUrl}>Get Iframe URL</button> */}
          </div>
        </div>
      </Modal>
    </div>
  );
}
