import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import axios from "axios";
import Modal from "react-modal";
import "../Dashboard.css";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Chicken from "../../../img/Chicken.png";
import Rice from "../../../img/Icon/Rice.svg";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import Drinks from "../../../img/Icon/Drinks.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Kheericon from "../../../img/Icon/Kheer_icon.png";
import soup from "../../../img/Icon/soup.svg";
import img_upload from "../../../img/img_upload.png";
import remove from "../../../img/remove.png";
import right from "../../../img/Icon/right.svg";
import edit from "../../../img/edit.png";
import dishes_icon from "../../../img/Icon/Dishes.svg";
import evnetlog from "../../../img/fmb-logo.png";
import loadergif from '../../../img/landing/loader123.gif';
import {
  deleteDishes,
  getAllcaterer,
  getAllingredients,
  getAllRegIngredients,
  getInventory,
  getUnitList,
  getdishview,
  getDishType, api_url, getEventView, menu_schedule,fmb_food_items_dish
} from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from "../../../img/Icon/calender.svg";
import Select from "react-select";
import { ExcelExportButton, ExportToExcel } from "../../Helper/Helper";
import CsvDownloader from 'react-csv-downloader';
import { CSVLink } from 'react-csv';
import { UndoRounded } from "@mui/icons-material";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

export default function Dishes() {
  const user_Role = localStorage.getItem("access_Role");
  var token = localStorage.getItem("access_token");
  const location = useLocation();
  const navigate = useNavigate();

  const search_dish_name = location?.state?.dish_name || [];
  const schedule_type = location?.state?.schedule_type || [];



  const [cleartype, setCleartype] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [catererdata, setCatererdata] = useState("");
  const [ingredientcount, setIngredientCount] = useState("0");
  const fileInputRef = useRef(null);
  const [searchValue, setSearchValue] = useState(search_dish_name);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [ViewIsOpen, setIsViewOpen] = React.useState(false);
  const [dishes, setDishes] = useState([]);
  const [dishesnamelist, setDishesNameList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(search_dish_name);
  const [value, setValue] = useState([]);
  const [previewImage, setPreviewImage] = useState(img_upload);
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState();
  const [unitdata, setUnitdata] = useState([]);
  const [unitdataall, setUnitdataall] = useState([]);
  const [itemSelect, setItemSelect] = useState([]);
  const [name, setName] = useState("");
  const [dishnote, setDishNote] = useState();
  const [quantityname, setquantityname] = useState([]);
  const [type, setType] = useState([]);
  const [cater, setCater] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [quality, setQuality] = useState([]);
  const [unit, setUnit] = useState([]);
  const [state, setState] = useState([]);
  const [caterer, setCaterer] = useState([]);
  const [catererList, setCatererList] = useState("");
  const [inderedientList, setInderedientList] = useState([]);
  const [selectedvalue, setselectedvalue] = useState([]);
  const [dishname, setDishname] = useState([]);
  const [editdishtype, setEditDishType] = useState("");
  const [updateimage, setUpdateimage] = useState(null);
  const [dishview, setDishView] = useState([]);
  const [optiondata, setoptiondata] = useState("");
  const [IngredientsOption, setIngredientsOption] = useState("");
  const [UnitOption, setUnitOption] = useState("");
  const [dishnum, setDishNum] = useState([])
  const dishviewdata = Object?.values(dishview);
  const [id, setId] = useState("");
  const [duplicateCount, setDuplicateCount] = useState(1);
  const [updateid, setUpdateId] = useState("");
  const [dishtypelist, setDishTypeList] = useState("");
  const [catererlist, setCatererlist] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('asc');
  const [roticheck, setRotiCheck] = useState("1");
  const [ricecheck, setRiceCheck] = useState("1");
  const [totalcount, setTotalCount] = useState('');
  const [validationerror, setValidationerror] = useState(false)
  const [alldishid, setAllDishId] = useState('')
  const [EventIsOpen, setIsEventOpen] = React.useState(false);
  const [EventType, setEventType] = useState([]);
  const [niyaztype, setNiyazType] = useState({ value: "0", label: "FMB Thali" });
  const [unitdatalist, setunitDatalist] = useState([]);
  const [ingredientlistdata, setingredientlistdata] = useState([]);
  const [niyaaz_type_view, setNiyaazTypeView] = useState(schedule_type == 'FMBTHALI' ? 0 : 1);


  const ingredientRef = useRef(null);

  const Autoclickindred = () => {
    if (ingredientRef.current) {
      ingredientRef.current.click();
    }

    setTimeout(() => {
      window.location.reload()
    }, 2000);
  };

  const handleNiyaazTypeViewToggleChange = (e) => {
    setLoading (true)
    const newValue = niyaaz_type_view == 0 ? 1 : 0;
    console.log ("newValue", newValue)
    setNiyaazTypeView(newValue);
    console.log ("niyaaz_type_view", niyaaz_type_view)
    fetchData(1, newValue);
  };

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(columnName);
      setSortOrder('asc');
    }
  };
  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === 'asc' ? <i className="fa fa-sort-up"></i> : <i className="fa fa-sort-down"></i>;
    }
    return null;
  };


  const sortedDishes = [...dishes].sort((a, b) => {
    if (sortColumn === 'dishName') {
      const nameA = a.dish_name.toLowerCase();
      const nameB = b.dish_name.toLowerCase();
      if (nameA < nameB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    // Handle sorting for other columns if needed
    return 0;
  });

  const handleFirstPage = () => {
    setCurrentPage(1);
    fetchData(1, niyaaz_type_view);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, niyaaz_type_view);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    fetchData(totalPages, niyaaz_type_view);
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 10; // Maximum number of visible pages
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const fetchData = async (page, newValue) => {
    let niyaaz_type = newValue ? newValue : 0
    try {
      // Fetch data for the specified page

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };

      const response = await fetch(fmb_food_items_dish + `admin/dish/list?page=${page}&niyaaz_type=${niyaaz_type}`
        , {
          method: "GET",
          headers: headers,
        });


      if (response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.data && Array.isArray(responseData.data.dish) && responseData.totalPages) {
          const { dish, totalPages, count } = responseData.data;
          // Set the fetched data and total pages
          setDishes(dish);
          setTotalPages(totalPages);
          setTotalCount(count);
        } else {
          setDishes(responseData.data.dish);
          setTotalPages(responseData.data.totalPages);
          setTotalCount(responseData.data.count);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log('Error fetching data:', response.status);
      }
    } catch (error) {
      setLoading(false);
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    if(!searchQuery){
      fetchData(currentPage);
    }
    getUnitData() // Fetch data for the initial page
    handleSearch()
  }, []);

  const getUnitData = () => {
    dispatch(getUnitList()).then((res) => {
      if (res?.payload?.status == true) {
        setUnitdataall(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };


  const handleSearch = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await fetch(fmb_food_items_dish + `admin/search/dish?dish_name=${encodeURIComponent(searchQuery)}&niyaaz_type=${niyaaz_type_view}`, {
        method: "GET",
        headers: headers,
      });
      if (response.ok) {
        const data = await response.json();
        console.log('data:', data); // Check the structure of the received data
        if (data.data && Array.isArray(data.data)) {
          setLoading(false);
          setDishes(data?.data);
          setTotalCount(data?.data.length);
          // Set the total number of pages
          console.log('Invali:', data.data);
        } else {
          console.log('Invalid data format:', data.data);
          setLoading(false);
          setDishes(data);
        }
      } else {
        setLoading(false);
        console.log('Error: ', response.status);
      }
    } catch (error) {
      setLoading(false);
      console.log('Error: ', error);
    }
  };

  const getCatererData = () => {
    dispatch(getAllcaterer()).then((res) => {
      if (res?.payload?.status == true) {
        setCatererlist(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getCatererlistdata = catererlist.map((item) => {
    return {
      label: `${item?.first_name} ${item?.last_name ? item?.last_name : '-'}`,
      value: item.id,
    };
  });

  const ingredientlist = itemSelect.map((item) => {
    return {
      label: item.ingredient_name,
      value: item.id,
      package: item.package_unit,
      unit: item.unit,
    };
  });
  
  const unitlistdata = unitdataall.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });

  function EventModal() {
    setIsEventOpen(true);

  }

  function EventcloseModal() {
    setIsEventOpen(false);
  }
  function EventOpenModal() { }
  function RemoveModal() {
    setIsRemoveOpen(true);
  }

  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() { }

  function ViewModal(items) {
    setIsViewOpen(true);
    console.log('items', items)

    setName(items?.dish_name);
    setDishNote(items.dish_note);
    setoptiondata(items.dish_type);
    setCatererdata(items.caterer_id);
    setRiceCheck(items.allow_skip_rice)
    setRotiCheck(items.allow_skip_roti)
  }

  function ViewcloseModal() {
    setIsViewOpen(false);
  }

  function ViewOpenModal() { }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#AE8340" : null,
        boxShadow: isFocused ? "none" : "none",
        color: "#333333",
      };
    },
  };

  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleSelect = (e) => {
    setType(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const data = "dffdf";
  const getDishesData = () => {
    setLoading(true);
    dispatch(getInventory()).then((res) => {
      if (res?.payload?.status === true) {
        setDishes(res?.payload?.data?.dish);
        setTotalCount(res?.payload?.data?.count);
        setLoading(false);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getDishtypedata = () => {
    dispatch(getDishType()).then((res) => {
      if (res?.payload?.status === true) {
        setDishTypeList(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    })
  };

  let dishTypeListData = [];
  if (Array.isArray(dishtypelist)) {
    dishTypeListData = dishtypelist.map((item) => ({
      label: item.dish_type,
      value: item.id,
    }));
  } else {
    console.error("dishtypelist is not an array or is undefined");
  }

  const getingrident = () => {
    dispatch(getAllRegIngredients()).then((res) => {
      if (res?.payload?.status === true) {
        setItemSelect(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (!searchQuery) {
       getDishesData();
    }
    getCatererData();
    getingrident();
    getDishtypedata()
  }, []);

  

  const deleteDishesId = (id) => {
    dispatch(deleteDishes(id)).then((res) => {
      if (res?.payload?.status === true) {
        toast.success(res?.payload?.message);
        searchQuery ? handleSearch() : fetchData(1, niyaaz_type_view);        
        RemovecloseModal()
      } else {
        toast.error(res?.payload?.message);
      }
    });
  }
  var ingredientsArray = [];
  var ingredients2 = {
    ingredient_id: '',
    quantity: '',
    unit: '',
    ingredient_name: ''
  };
  ingredientsArray.push(ingredients2);
  const VeswDishesId2 = (catererdata) => {
    const requestBody = {
      dish_id: alldishid,
      caterer_id: catererdata
    };
    fetch(menu_schedule + 'admin/dish-get', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (data?.success === true) {
          if (data?.data) {
            setDishNum(data.data.ingredients ? data.data.ingredients : ingredientsArray);
          } else {
            // If data.data is null or undefined, you can handle it here.
            // For example, set a default value or handle the case accordingly.
            // Here, we are assuming ingredientsArray is a suitable default.
            setDishNum(ingredientsArray);
          }
        } else {
          console.error(data?.data?.ingredients ? data?.data?.ingredients : '', 'datata');
        }
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error(error);
      });
  }

  function round(value, precision) {
    return value;
}

  const ingredientsFileName =  name+"_Dish_Ingredients_Sheet_.pdf";


  const ViewPrintDishesId = (id) => {
    setLoading(true);
    const requestBody = {
      dish_id: id,
      caterer_id: ""
    };

    fetch(menu_schedule + 'admin/dish-get', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (data?.success == true) {
          dishdata1(data.data ? data.data : data.payLoad);
          if (data?.data) {
            setDishNum(data.data.ingredients ? data.data.ingredients : ingredientsArray);
          } else {
            setDishNum(ingredientsArray);
          }
          setUpdateId(data?.data?.dish_ids ? data?.data?.dish_ids : data?.payLoad?.dish_ids)

          toast.success("Wait Dish Ingredient Sheet Download");
          setTimeout(() => {
            Autoclickindred();
          }, 1000);
        } else {
          // Handle error response
          toast.error(data?.message);
        }
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error(error);
      }) .finally(() => {
        setLoading(false); // Set loading state to false after data has been fetched or in case of an error
      });
  };


  const VeswDishesId = (id) => {
    setLoading(true);
    const requestBody = {
      dish_id: id,
      caterer_id: ""
    };

    fetch(menu_schedule + 'admin/dish-get', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (data?.success == true) {
          console.log('ddd', data.data ? data.data : data.payLoad)
          dishdata1(data.data ? data.data : data.payLoad);
          setDishView(data.data ? data.data : data.payLoad)
          if (data?.data) {
            setDishNum(data.data.ingredients ? data.data.ingredients : ingredientsArray);
          } else {
            // If data.data is null or undefined, you can handle it here.
            // For example, set a default value or handle the case accordingly.
            // Here, we are assuming ingredientsArray is a suitable default.
            setDishNum(ingredientsArray);
          }
          setUpdateId(data?.data?.dish_ids ? data?.data?.dish_ids : data?.payLoad?.dish_ids)
        } else {
          // Handle error response
          toast.error(data?.message);
        }
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error(error);
      }) .finally(() => {
        setLoading(false); // Set loading state to false after data has been fetched or in case of an error
      });
  };
  

  const ChangeStatusActive = (dish_id) => {
    setLoading(true);
    fetch(fmb_food_items_dish + `admin/dish/activate/${dish_id}`, {
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data?.status == true) {
          toast.success(data?.message);
          handleSearch()
        } else {
          // Handle error response
          toast.error(data?.message);
        }
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error(error);
      }) .finally(() => {
        setLoading(false); // Set loading state to false after data has been fetched or in case of an error
      });

  };

  const ChangeStatusInActive = (dish_id) => {
    setLoading(true);
    fetch(fmb_food_items_dish + `admin/dish/inactivate/${dish_id}`, {
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data?.status == true) {
          toast.success(data?.message);
          handleSearch()
        } else {
          // Handle error response
          toast.error(data?.message);
        }
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error(error);
      }) .finally(() => {
        setLoading(false); // Set loading state to false after data has been fetched or in case of an error
      });

  }

  const handleChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setUpdateimage(event.target.files[0]);
  };


  const handleChangeImgurl = (event) => {
    setUpdateimage(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };
  const access_id = localStorage.getItem("access_id");

  const handleSubmit = (e) => {
    e.preventDefault();
    let ingredients = [];
    for (let i = 0; i < duplicateCount; i++) {
      let ingredientObject = {
        index: i,
        ingredient_id: ingredientlistdata[i],
        quantity: quantityname[i],
        unit: unitdatalist[i],
      };
      ingredients.push(ingredientObject);
    }

    if (!name ||
      !optiondata ||
      !catererdata
    ) {
      setValidationerror(true)
      return false
    }
    else {

      const postData = {
        ingredients: JSON.stringify(ingredients),
        dish_name: name,
        dish_type: optiondata.label,
        is_active: "1",
        caterer_id: catererdata.value,
        dish_note: dishnote,
        allow_skip_rice: roticheck,
        allow_skip_roti: ricecheck,
        cutting_instructions: "1",
        general_instructions: "1",
        quantity_updated_from: "1",
        created_user: access_id,
        updated_user: access_id,
        source: "web",
        niyaaz_type:niyaztype?.value,
        niyaz_type_description: niyaztype?.label

      };
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .post(fmb_food_items_dish + "admin/dish-create", postData, config, {})
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.status === true) {
            toast.success(res?.data?.message);
            fetchData(1, niyaaz_type_view);
            setName("");
            setQuality("");
            setUnit("");
            setDishNote('');
            setDuplicateCount(1);
            setingredientlistdata([]);
            setunitDatalist([]);
            setquantityname([]);
            setCatererdata('')
            setoptiondata('')
            setValidationerror(false)
            const modal_close = document.getElementById("modal_close_btn").click();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error(error);
          toast.error(error);
      });;
    };
  }

  const handleSubmitCopy = (e) => {
    console.log ("handleSubmitUpdate")
    const obj = ingredientlistdata.map((ingredient, i) => ({
      index: i,
      ingredient_id: ingredient,
      quantity: quantityname[i],
      unit: unitdatalist[i],
    }));
    if (!name ||
      !optiondata ||
      !catererdata
    ) {
      setValidationerror(true)
      return false
    }
    else {
      const postData = {
      dish_name: name+" Copy",
      dish_type: optiondata,
      is_active: "1",
      caterer_id: catererdata,
      allow_skip_rice: ricecheck,
      allow_skip_roti: roticheck,
      cutting_instructions: "1",
      general_instructions: "1",
      quantity_updated_from: "1",
      dish_note: dishnote,
      created_user: access_id,
      updated_user: access_id,
      source: "web",
      ingredients: JSON.stringify(obj),
      niyaz_type_description: niyaztype?.label,
      niyaaz_type: niyaztype?.value
      }
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .post(fmb_food_items_dish + "admin/dish-create", postData, config, {})
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.message);
            setName("");
            setQuality("");
            setUnit("");
            setValidationerror(false)
            searchQuery ? handleSearch() : fetchData(1, niyaaz_type_view);
            const modal_close2 = document.getElementById("modal_close_btn2").click();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error(error);
          toast.error(error);
      });
    };
  }


  const handleCopyToMiqaat = (e) => {
    console.log ("handleCopyToMiqaat")
    const obj = ingredientlistdata.map((ingredient, i) => ({
      index: i,
      ingredient_id: ingredient,
      quantity: ((quantityname[i]/100) + (quantityname[i]/100)) * 10,
      unit: unitdatalist[i],
    }));
    if (!name ||
      !optiondata ||
      !catererdata
    ) {
      setValidationerror(true)
      return false
    }
    else {
      const postData = {
      dish_name: name+"-FMB Copy",
      dish_type: optiondata,
      is_active: "1",
      caterer_id: catererdata,
      allow_skip_rice: ricecheck,
      allow_skip_roti: roticheck,
      cutting_instructions: "1",
      general_instructions: "1",
      quantity_updated_from: "1",
      dish_note: dishnote,
      created_user: access_id,
      updated_user: access_id,
      source: "web",
      ingredients: JSON.stringify(obj),
      niyaz_type_description: "Miqaat",
      niyaaz_type: 1
      }
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .post(fmb_food_items_dish + "admin/dish-create", postData, config, {})
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.message);
            setName("");
            setQuality("");
            setUnit("");
            setValidationerror(false)
            searchQuery ? handleSearch() : fetchData(1, niyaaz_type_view);
            const modal_close2 = document.getElementById("modal_close_btn2").click();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error(error);
          toast.error(error);
      });
    };
  }

  const reset = () => {
    setName("");
    setQuality("");
    setUnit("");
    setDishNote("");
    setCatererdata('')
    setoptiondata('')
    setDuplicateCount(1);
    setingredientlistdata([]);
    setunitDatalist([]);    
    setquantityname([]);
  };

  const handleSubmitUpdate = (e) => {
    console.log ("handleSubmitUpdate")
    e.preventDefault();
    e.preventDefault();
    const updatedIngredientsList = ingredientlistdata.filter((ingredient) => ingredient !== 0)
    const updatedQuanityName = quantityname.filter((quantity) => quantity !== 0)
    const updatedUnitDataList = unitdatalist.filter((unit) => unit !== 0)
    const obj = updatedIngredientsList.map((ingredient, i) => ({
      index: i,
      ingredient_id: ingredient,
      quantity: updatedQuanityName[i],
      unit: updatedUnitDataList[i],
    }));
    console.log(obj)
    if (!name ||
      !optiondata ||
      !catererdata
    ) {
      setValidationerror(true)
      return false
    }
    else {
      const postData = {
      dish_name: name,
      dish_type: optiondata,
      is_active: 1,
      caterer_id: catererdata,
      allow_skip_rice: ricecheck,
      dish_id: updateid,
      allow_skip_roti: roticheck,
      cutting_instructions: "1",
      general_instructions: "1",
      quantity_updated_from: "1",
      dish_note: dishnote,
      created_user: access_id,
      updated_user: access_id,
      source: "web",
      ingredient: JSON.stringify(obj),
      niyaz_type_description: niyaztype?.label,
      niyaaz_type: niyaztype?.value
      }
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .put(fmb_food_items_dish + `admin/dish/update`, postData, config, {})
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.message);
            setName("");
            setQuality("");
            setUnit("");
            setValidationerror(false)
            searchQuery ? handleSearch() : fetchData(1, niyaaz_type_view);
            const modal_close2 = document.getElementById("modal_close_btn2").click();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error(error);
          toast.error(error);
      });
    };
  }


  const dataget = (items) => {
    setId(items.id);
  };

  const filterNames = ({ dish_name }) => {
    const lowerCaseVendor = dish_name?.toLowerCase() ?? '';
    const lowerCaseSearchValue = searchValue.toLowerCase();
    return lowerCaseVendor.indexOf(lowerCaseSearchValue) !== -1;
  };

  let _export = React.createRef();;
  const exportFile = () => {
    _export.current.save();
  };

  const fileName = "dishes file"
  const dishdata1 = (dish) => {
    const dishlist = dish;
    const ingredients = dish?.ingredients ? dish?.ingredients : ingredientsArray;
    const ingredientQuantities = ingredients?.map((ingredient) => ingredient?.quantity);
    setquantityname(ingredientQuantities);
    const ingredientid = ingredients?.map((ingredient) => ingredient?.ingredient_id);
    setingredientlistdata(ingredientid);
    const ingredientunit = ingredients?.map((ingredient) => ingredient?.unit);
    setunitDatalist(ingredientunit);
    setName(dishlist?.dish_name);
    setDishNote(dishlist?.dish_note);
    setoptiondata(dishlist?.dish_type);
    setCatererdata(dishlist?.caterer_id);
    setRiceCheck(dishlist?.allow_skip_rice)
    setRotiCheck(dishlist?.allow_skip_roti)
    setCatererdata(dishlist?.caterer_id);
    setIngredientCount(dishlist?.ingredient_count);
    
    //Update Niyaz Type
    const dishNiyaz = { value: dishlist?.niyaaz_type, label: dishlist?.niyaz_type_description };
    setNiyazType(dishNiyaz);

    const dishIdToStore = dishlist.dish_id ? dishlist.dish_id : dishlist.dish_ids;
    localStorage.setItem('dish_id', dishIdToStore);

    setAllDishId(dishIdToStore);
    setQuality(ingredientQuantities);
    setUnit(ingredientunit);
    setingredientlistdata(ingredientid);
  }


  const handleIngredientUpdate = (e, index) => {    
    //Update Ingredient
    const newIngredientListData = [...ingredientlistdata];
    newIngredientListData[index] = e.value;
    setingredientlistdata(newIngredientListData);

    //Update Unit
    const newunit = [...unitdatalist];
    newunit[index] = e.unit;
    setunitDatalist(newunit);

  };

  const handleDuplicate = () => {
    setDuplicateCount(duplicateCount + 1);
  };

  const handleRemove = (index) => {
    setDuplicateCount(duplicateCount - 1);
  };

  const handleAddSection = () => {
    // const newIngredient = { ingredient_id: null, ingredient_name: '', quantity: 0, unit: ''}
    const newDishNum = dishnum;
    newDishNum.unshift(0);//[...dishnum, dishnum?.length + 1];
    setDishNum([...newDishNum]);

    const newIngredientListData = ingredientlistdata
    ingredientlistdata.unshift(0)
    setingredientlistdata(newIngredientListData)

    const newQuantityName = [...quantityname];
    newQuantityName.unshift(0);
    setquantityname(newQuantityName);
  
    const newUnitDataList = [...unitdatalist];
    newUnitDataList.unshift(0);
    setunitDatalist(newUnitDataList);

    console.log(newDishNum)
    console.log(ingredientlistdata)
  };

  const handleRemoveSection2 = (index) => {
    const newDishNum = [...dishnum];
    newDishNum.splice(index, 1);
    setDishNum(newDishNum);
    const newIngredientListData = [...ingredientlistdata];
    newIngredientListData.splice(index, 1);
    const newQuantityName = [...quantityname];
    newQuantityName.splice(index, 1);
    const newUnitDataList = [...unitdatalist];
    newUnitDataList.splice(index, 1);
  };


  const handleRemoveSection = (index) => {
    const newDishNum = [...dishnum];
    newDishNum.splice(index, 1);
    setDishNum(newDishNum);
  
    const newIngredientListData = [...ingredientlistdata];
    newIngredientListData.splice(index, 1);
    setingredientlistdata(newIngredientListData);
  
    const newQuantityName = [...quantityname];
    newQuantityName.splice(index, 1);
    setquantityname(newQuantityName);
  
    const newUnitDataList = [...unitdatalist];
    newUnitDataList.splice(index, 1);
    setunitDatalist(newUnitDataList);
  };

  const desiredIds = ingredientlistdata;  // Replace 'desiredIds' with the actual array of IDs
  const desiredItems = desiredIds?.map((desiredId) => {
    return ingredientlist?.find((item) => item.value === desiredId);
  });

  const handleToggleChange = (e) => {
    const newValue = roticheck === "1" ? "0" : "1";
    setRotiCheck(newValue);
  }
  const handleToggleChange2 = (e) => {
    const newValue = ricecheck === "1" ? "0" : "1";
    setRiceCheck(newValue);
  }

  // csv file download 
  const csvData = sortedDishes.map((items) => {
    return {
      INGREDIENTS: items.ingredient_count,
      DISHNAME: items.dish_name,
      TYPE: items.dish_type,
    };
  });

  const csvHeaders = [
    { label: "DISH NAME", key: "DISHNAME" },
    { label: "TYPE", key: "TYPE" },
    { label: "INGREDIENTS", key: "INGREDIENTS" },
  ];


  const dishlistTotal = totalcount ? totalcount : '';
  const getEventViewType = (id) => {
    dispatch(getEventView(id)).then((res) => {
      if (res.payload.status === true) {
        setIsEventOpen(true);
        console.log("res?.payload?.datasss", res.payload.status);
        setEventType(res?.payload?.payload)
      } else {
        toast.error(res.payload.message);
        console.log("res?.payload?.data", res.payload.message);
        setIsEventOpen(false);
      }
    });
  };

  const NiyazListData = [
    {   label: "FMB Thali", value: 0 },
    {  label: "Miqaat", value: 1 },
  ];

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    }
  }, [searchQuery]);

  const styles = StyleSheet.create({
    zonesheetsection: {
      marginTop: 20,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 10,
    },

    zonesheetBottomsection: {
      marginTop: 0,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 10,
    },

    ingrediantsection: {
      marginBottom: 25,
      marginTop: 15,
      marginLeft: 10,
      marginRight: 10,
    },
    columnheading: {
      marginBottom: 10,
      marginTop: 10
    },
    ingrediantOuterView: {
      flexDirection: "column",
      marginTop: 15,
      marginLeft: 35,
      marginBottom: 50,
      // backgroundColor: 'yellow',
      maxWidth: "100%"
    },
    page: {
      flexDirection: "row",
      backgroundColor: "#FFF",
      marginTop: 10,
    },

    zoneSection: {
      margin: 10,
      padding: 10,
      marginLeft: 20,
      flexGrow: 1,
    },

    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
    },
    tableRow: {
      flexDirection: "row",
      width: 400,
    },

    tableMenuRow: {
      flexDirection: "row",
      width: 400,
    },

    tableMainCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
    },

    tableMenuMainCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5
    },

    tableCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
      width: 600,
    },
    tableCellBottom: {
      borderColor: "#000",
      borderWidth: 1,
      marginLeft: -1,
      padding: 5,
      textAlign: "center",
    },


    tableZoneCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
      width: 1200,
    },
    tableZoneCellBottom: {
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      textAlign: "center",
    },


    newApp: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      textAlign: "center",
    },

    newBottomApp: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      marginLeft: -1,
      textAlign: "center",
    },

    newMainCell: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      borderTop: "none",
      textAlign: "center",
    },

    newCell: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      borderTop: "none",
      marginLeft: -1,
      textAlign: "center",
    },

    dateCellMain: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5
    },

    dateCellItem: {
      width: "110%",
    },

    thaalCellMain: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
    },

    thaalCellItem: {
      width: "80%",
    },

    tableSellMain: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
    },

    tableRightMain: {
      width: "90%",
      maxWidth: "21%",
    },

    tableSellIng: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
    },

    tableSellZoneName: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
      fontSize: 10,
      textAlign: "left",
      width: 600
    },

    tableSell: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
      fontSize: 10,
      textAlign: "center",
      width: 600
    },
    columnIngredientHeader: {
      fontSize: 10,
      color: "#000",
      marginLeft: 50,
      marginTop: 30
    },
    columnHeader: {
      fontSize: 10,
      color: "#000",
    },
    tableWidth: {
      width: "110%",
      maxWidth: "24%",
    },

    tableZoneNameWidth: {
      width: "110%",
      maxWidth: "49%",
    },

    tableCommentswidth: {
      width: "110%",
      maxWidth: "37%",
    },


    tablePortionWidth: {
      width: "110%",
      maxWidth: "18%",
    },

    tablePhoneWidth: {
      width: "110%",
      maxWidth: "20%",
    },

    tableWidth: {
      width: "110%",
      maxWidth: "24%",
    },

    tableBottomWidth: {
      width: "110%",
      maxWidth: "14%",
    },

    tableZoneBottomWidth: {
      width: "110%",
      maxWidth: "24%",
    },

    tableWidthRiceRoti: {
      width: "100%",
      maxWidth: "8%",
    },

    tableInd: {
      width: "110%",
      maxWidth: "19%",
    },

    tabletdmenuItem: {
      maxWidth: "22%",
    },
    tabletdsubmenuItem: {
      width: "110%",
      maxWidth: "13%",
    },

    tableRight: {
      width: "110%",
      maxWidth: "51%",
    },

    tableMenuRight: {
      maxWidth: "320%",
      width: 1450,
    },

    tableMenuMainRight: {
      maxWidth: "190%",
      width: 950,
    },

    tableMenuLabelRight: {
      maxWidth: "320%",
      width: 1450,
    },

    textMainCell: {
      marginLeft: 80,
    },

    textMenuMainCell: {
      marginLeft: 10,
    },

    textDateCell: {
      marginLeft: 12,
      width: 100
    },

    textThaalDateCell: {
      width: 100
    },

    textIngCell:{
      marginLeft: -15,
    },

    textQtyCell:{
      marginLeft: -7,
    },

    textUnitCell:{
      marginLeft: -5,
    },

    tableIngRight: {
      width: "90%",
      maxWidth: "45%",
    },

    tableMenuIngRight: {
      width: "150%",
      maxWidth: "65%",
    },

    tableName: {
      width: "110%",
      maxWidth: "28%",
    },

    tableRo: {
      width: "110%",
      maxWidth: "50%",
    },
    columnLeader: {
      fontSize: 10,
      color: "#000",
    },
    manData: {
      flexDirection: "row",
    },
    flowFloat: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 8,
      fontSize: 13,
      marginRight: 30,
      width: 560
    },
    labelItem: {
      fontWeight: "bold", // Ensure fontWeight is applied within labelItem style
    },
  });
  const styleslist = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "row",
      fontWeight: "bold",
      flexWrap: "wrap",
      marginRight: 15,
      marginLeft: 15,
      // justifyContent: 'center', // Adjust as needed for spacing between items
      
    },
    labelSheet: {
      width: '145px',
      textAlign: "center",
      marginRight: 2,
      fontSize: 11,
      // backgroundColor: 'yellow',
      height: '72px',
      // borderColor: 'black',
      // borderWidth: 1
    },

    fatehaSheet: {
      width: "10%",
      textAlign: "center",
      marginBottom: 5,
      marginTop: 2,
      fontSize: 10,
    },

    labelItem: {
      // Adjust to allocate space for 2 items per row with a little gap in between
      // backgroundColor: 'lightgreen',
      // marginTop:5,
      // borderColor: 'black',
      // borderWidth: 1,
      fontWeight: "bold", // Ensure fontWeight is applied within labelItem style

      // marginBottom: 1
    },

    labelItemText: {
      fontSize: 10,
    },
    noData: {
      textAlign: "center",
      fontSize: 10,
      marginTop: 5,
    },
  });

  const generateINGREDIENTS = () => (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.ingrediantOuterView}>
          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text>Dish</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableMenuRow, styles.columnHeader]}>
                <View style={[styles.tableMenuMainCell, styles.tableMenuMainRight]}>
                    <Text style={[styles.textMenuMainCell]}>{name ? name : "NA"} </Text>
                </View>
                <View style={[styles.dateCellMain, styles.dateCellItem]}>
                  <Text style={[styles.textDateCell]}>{ingredientcount ? "Total Ingredients: "+ingredientcount: "" }</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.zonesheetBottomsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text>{"Ingredient List"}</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
              <View style={[styles.tableCell, styles.tableMenuIngRight]}>
                  <Text style={[styles.textIngCell]}>Dish Name</Text>
                </View>
                <View style={[styles.tableCell, styles.tableIngRight]}>
                  <Text style={[styles.textIngCell]}>Ingredient</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdmenuItem]}>
                  <Text style={[styles.textQtyCell]}>Quantity</Text>
                </View>
                <View style={[styles.newApp, styles.tabletdsubmenuItem]}>
                  <Text style={[styles.textUnitCell]}>Unit</Text>
                </View>
              </View>
              {Array.isArray(dishnum) &&
                dishnum.map((itemall) => (
                  <View key={itemall.id} style={[styles.tableRow]}>
                    <View
                      style={[styles.tableSellMain, styles.tableMenuIngRight, styles.columnLeader]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>
                        {" "}
                        {name}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSellIng,
                        styles.tableIngRight,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.ingredient_name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text style={[styles.textQtyCell]}>
                        {itemall?.menu_quantity !== null
                          ? round(itemall?.quantity)
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newMainCell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text style={[styles.textUnitCell]}>
                        {itemall?.unit !== null
                          ? itemall?.unit
                          : "-"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <PDFDownloadLink
            document={generateINGREDIENTS()}
            fileName= {ingredientsFileName}
            className=""
            id="modal_close_btn4"
            >
            {({ blob, url, loading, error }) =>
               loading ? (
            ""
            ) : (
            <>
            {" "}
                <button className="btn" ref={ingredientRef}>
                </button>
            {/* {Autoclickbutton()} */}
            </>
            )
            }
        </PDFDownloadLink>
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      {" "}
                      {/* <Link to="/InventoryFMB">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                            fill="#777391"
                          />
                        </svg>
                      </Link> */}
                      {niyaaz_type_view === 0 ?
                      <h1 className="title_heading">
                        FMB Dishes{" "} ({dishlistTotal ? dishlistTotal : ''})
                      </h1>
                      :
                      <h1 className="title_heading">
                        Miqaat / Private Dishes{" "} ({dishlistTotal ? dishlistTotal : ''})
                      </h1>
                      }
                    </div>
                    <div className="Thali_page_btn AddStandardInventory AddUnRegisteredText">
                          <img
                              width="30px"
                              src={dishes_icon}
                              className="img-fluid"
                            />
                            &nbsp; &nbsp; &nbsp;
                        <label for="Standard">
                          View Miqaat / Private Dishes &nbsp; &nbsp;
                        </label>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            className="onoffswitch-checkbox"
                            id="rice_check"
                            checked={niyaaz_type_view == "1"}
                            onChange={handleNiyaazTypeViewToggleChange}
                          />
                          <label className="onoffswitch-label" for="rice_check">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                    </div>
                    {/* <div className="Thali_page_btn AddDishes">
                          <label for="Standard" className="form-label">
                          View Miqaat Dishes &nbsp; &nbsp;
                          </label>
                          <div className="onoffswitch">
                            <input
                              type="checkbox"
                              name="onoffswitch"
                              className="onoffswitch-checkbox"
                              id="rice_check"
                              checked={niyaaz_type_view == "1"}
                              onChange={handleNiyaazTypeViewToggleChange}
                            />
                            <label className="onoffswitch-label" for="rice_check">
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          {/* <button onClick={RemoveModal}>sss</button> */}
                    <div className="Thali_page_btn">
                      <div className="New_section">
                      { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                        <button
                          className="btn thali_btn"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          onClick={reset}
                        >
                          Add New Dish
                        </button>
                        }
                        &nbsp; &nbsp;
                        &nbsp;{" "}
                        {/* <div className="mx-2">
                          <ExcelExportButton dishes={dishes} fileName={fileName} />
                        </div> */}
                        <button className="btn thali_btn csvbtn">
                          <CSVLink data={csvData} headers={csvHeaders} filename={"dish.csv"}>
                            Export List
                          </CSVLink>
                        </button>
                      </div>
                      &nbsp;
                    </div>
                  </div>
                </div>

                <div className="thali_count_list">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div className="thali_table_user_section">
                  {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className='Loading-img'><img src={loadergif} className='' /></div>

                  ) : (
                    <div></div>
                  )
                  }
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" onClick={() => handleSort('dishName')}>
                          <span className="thali_table">Dish Name   &nbsp; &nbsp;  {getSortIcon('dishName')}</span>

                        </th>
                        <th scope="col">
                          <span className="thali_table">Type</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Caterer</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Notes</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">ingredients</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">action</span>
                        </th>
                      </tr>
                    </thead>
                    {sortedDishes && sortedDishes?.map((items) => {
                      return (
                        <tbody key={items.id}>
                          <tr>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li onClick={() => ViewModal(items)}>
                                  {items.dish_name ? items.dish_name : '-'}
                                  &nbsp; &nbsp; &nbsp;
                                  {items.is_active == "0" && (
                                <span className="Draft_inactive_event">
                                  {items.is_active == "0" ? "INACTIVE" : ""}
                                </span>
                              )}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {" "}
                                  {items.dish_type === 'Rice' && (
                                    <img src={Rice} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Roti' && (
                                    <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Noodles' && (
                                    <img src={soup} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Kharas' && (
                                    <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Gravy' && (
                                    <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Tarkari' && (
                                    <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Mithas' && (
                                    <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Soup' && (
                                    <img src={soup} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Drink' && (
                                    <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Fruit' && (
                                    <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Salad' && (
                                    <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'Kadai Gosh' && (
                                    <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                  )}
                                  {items.dish_type === 'FriedChicken' && (
                                    <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                                  )}
                                  &nbsp; &nbsp;{items.dish_type ? items.dish_type : ''}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li> {items.caterer_name ? items.caterer_name : '-'}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li> {items.dish_note && items.dish_note != "NULL" ? items.dish_note : '-'}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li> {items.ingredient_count ? items.ingredient_count : '-'}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <div className="dropdown action_btn">
                                    <button
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton2"
                                    >
                                      { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                                      <li onClick={() => VeswDishesId(items.id)}>
                                        <a
                                          className="dropdown-item"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasRight_edit"
                                          aria-controls="offcanvasRight_edit"
                                          id="view_id"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="15"
                                            viewBox="0 0 19 15"
                                            fill="none"
                                          >
                                            <path
                                              d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                              fill="#777391"
                                            />
                                          </svg>{" "}
                                          &nbsp; &nbsp;Edit Details
                                        </a>
                                      </li>
                                      }
                                      <li onClick={() => ViewPrintDishesId(items.id)}>
                                        <a
                                          className="dropdown-item"
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 34 30"
                                            fill="#777391"
                                          >
                                          <path
                                              d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"
                                              />
                                         </svg>{" "}
                                          Print Details
                                        </a>
                                      </li>
                                      { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                                      <li onClick={() => {items.is_active == '1' ? ChangeStatusInActive(items.id) : ChangeStatusActive(items.id)}}>
                                        <a
                                          className="dropdown-item"
                                          id="view_id"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                          >
                                            {items.is_active == '1' ?
                                            <path
                                            d="m15.707,9.707l-2.293,2.293,2.293,2.293c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293,2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.293,2.293,2.293-2.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Zm8.293,2.293c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"
                                            fill="#777391"
                                            />
                                            :
                                            <path
                                            d="m16.298,8.288l1.404,1.425-5.793,5.707c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701Zm7.702,3.712c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"
                                            fill="#777391"
                                            />
                                           }
                                          </svg>{""}
                                          &nbsp; &nbsp; {items.is_active == '1' ? "In Activate" : "Activate" }
                                        </a>
                                      </li>
                                      }
                                    { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                                      <li onClick={() => dataget(items)}>
                                        <a

                                          className="dropdown-item"
                                          onClick={RemoveModal}
                                        >
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="20"
                                            viewBox="0 0 18 20"
                                            fill="none"
                                          >
                                            <path
                                              d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                              fill="#777391"
                                            />
                                          </svg>
                                          &nbsp; &nbsp; &nbsp;Remove
                                        </a>
                                      </li>
                                      }
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                  {/* Pagination */}
                  {/* Pagination */}

                </div>
                <nav>
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button className="page-link" onClick={handleFirstPage}>
                        First
                      </button>
                    </li>

                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        Previous
                      </button>
                    </li>

                    {getPageNumbers().map((pageNumber) => (
                      <li
                        className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                        key={pageNumber}
                      >
                        <button className="page-link pagebutton" onClick={() => handlePageChange(pageNumber)}>
                          {pageNumber}
                        </button>
                      </li>
                    ))}

                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                      </button>
                    </li>

                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button className="page-link" onClick={handleLastPage}>
                        Last
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>


      {/* Add Dish */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Add Dish
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleSubmit}>
              {/* <div className="Img_upload">
                <div>
                  {" "}
                  <img src={previewImage} className="img-firud uplode_img" />
                </div>
                <input
                  id="img_upload"
                  type="file"
                  name="image"
                  onChange={handleChangeImgurl}
                />
              </div> */}

              <div className="col-md-12">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    Niyaz Type
                  </label>
                  <Select
                    options={NiyazListData}
                    className="dropdown"
                    placeholder="Select Niyaz Type"
                    id="inputrice"
                    styles={colourStyles}
                    value={NiyazListData.find((option) => option.value === niyaztype?.value)}
                    defaultValue={cleartype}
                    onChange={(e) => setNiyazType(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {/* {
                    validationerror && !portionid &&
                    <p className="show_invalid_text" >  Portion is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Dish Name
                  </label>
                  <input
                    type="text"
                    placeholder="Dish Name"
                    className={`${validationerror && !name ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {/* <ul className="dishnameslist">
                  {dishesnamelist && dishesnamelist.map((items) => {
                      return (
                        <li key={items.id}>
                          <span>{items.dish_name}</span>
                          </li>   );
                    })}
                  </ul> */}
                  {
                    validationerror && !name &&
                    <p className="show_invalid_text" >Dish Name is required</p>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Dish type
                  </label>
                  <Select
                    options={dishTypeListData}
                    className={`${validationerror && !optiondata ? "validation_valid dropdown" : "dropdown"}`}
                    placeholder="Select Dish Type"
                    id="inputState"
                    styles={colourStyles}
                    value={optiondata}
                    defaultValue={type}
                    onChange={(e) => setoptiondata(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {
                    validationerror && !optiondata &&
                    <p className="show_invalid_text" >Dish Type is required</p>
                  }
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Roti (Allow Skip)
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="onoffswitch">
                          <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox " id="myonoffswitch" checked={roticheck === "1"}
                            onChange={handleToggleChange} />
                          <label className="onoffswitch-label" for="myonoffswitch">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>

                      </div>
                    </div>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col-md-6">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Rice (Allow Skip)
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="onoffswitch">
                          <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                            checked={ricecheck === "1"}
                            onChange={handleToggleChange2} />
                          <label className="onoffswitch-label" for="rice_check">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Caterer
                  </label>
                  <Select
                    options={[...getCatererlistdata]}
                    className={`${validationerror && !catererdata ? "validation_valid dropdown" : "dropdown"}`}
                    placeholder="Select Caterer"
                    id="inputState"
                    styles={colourStyles}
                    value={catererdata}
                    onChange={(e) => setCatererdata(e)}
                    isClearable={false}
                    hideSelectedOptions={true}
                  />
                  {
                    validationerror && !catererdata &&
                    <p className="show_invalid_text" > Caterer is required</p>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Dish Note
                  </label>
                  <input
                    type="text"
                    placeholder="Dish Note"
                    // className={`${ validationerror && !dishnote ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setDishNote(e.target.value)}
                    value={dishnote}
                  />
                  {/* {
                    validationerror && !dishnote && 
                    <p className="show_invalid_text" > Dish Note is required</p>
                  } */}
                </div>
              </div>
              <div className="Ingredients_title position-relative p-0">
                <h1>Ingredient
                  {/* ({totalItemCount}) */}


                </h1>
                <button type="button" onClick={handleDuplicate}>
                  Add Ingredient
                </button>
              </div>

              <div className="col-md-5">
                <label htmlFor="Ingredients" className="form-label">
                  Ingredients
                </label>
              </div>
              <div className="col-md-3">
                <label htmlFor="Qty" for="Qty" className="form-label">
                  Qty
                </label>
              </div>
              <div className="col-md-3">
                <label htmlFor="Unit" for="Unit" className="form-label">
                  Unit
                </label>
              </div>
              <div className="col-md-1">
              </div>



              {/* start the componet */}
              {Array.from({ length: duplicateCount }, (_, index) => (

                <div className="mt-0" key={index + 1}>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="Form_box">
                        {/* <label htmlFor={`Ingredients${index}`} for="Ingredients " className="form-label">
                          Ingredients{" "}
                        </label> */}
                        <Select
                          options={ingredientlist}
                          className="dropdown"
                          placeholder="Select Ingredients"
                          id="inputState"
                          styles={colourStyles}
                          value={ingredientlistdata[index] ? ingredientlistdata[index]?.value : ""}
                          onChange={(e) => handleIngredientUpdate(e, index)}
                          isClearable={false}
                          hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="Form_box">
                        {/* <label htmlFor={`Qty${index}`} for="Qty" className="form-label">
                          Qty
                        </label> */}
                        <input
                          type="number"
                          placeholder="Qty"
                          min="0"
                          step="any"
                          className="form-control"
                          onKeyDown={preventMinus}
                          id={`Qty${index}`}
                          onWheel={() => document.activeElement.blur()}
                          value={ingredientlistdata[index] ? quantityname[index] : ""}
                          onChange={(e) => {
                            const newQuality = [...quantityname];
                            newQuality[index] = e.target.value;
                            setquantityname(newQuality);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="Form_box">
                        {/* <label    htmlFor={`ingredientlistdata{index}`} for="Unit" className="form-label">
                          Unit
                        </label> */}
                        {/* <Select
                          options={unitlistdata}
                          className="text"
                          id={`Unit${index}`}
                          styles={colourStyles}
                          value={unitdatalist?.value}
                          onChange={(e) => {
                            const newunit = [...unitdatalist];
                            newunit[index] = e.label;
                            setunitDatalist(newunit);
                          }}
                          isClearable={false}
                          hideSelectedOptions={false}
                        /> */}

                        <input
                          type="text"
                          placeholder="Unit"
                          className="form-control"
                          disabled
                          id="Qty"
                          value={ingredientlistdata[index] ? unitdatalist[index] : ""}
                          // onChange={(e) => setunitDatalist([...unitdatalist, e.target.value])}
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="Form_box">
                        <button className="Remove_btn" type="button" onClick={() => handleRemove(index)}>
                          <img src={remove} className="img-firud" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}



              {/* end the comppnent */}
              {/* <IndgreComponent {...data} /> */}
              {/* {inderedientList} */}
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button type="button" data-bs-dismiss="offcanvas"
                    aria-label="Close" id="modal_close_btn">Cancel</button>
                  <button type="submit" >Add Dish</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      {/* Edit Dish */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas"
        tabindex="-1"
        id="offcanvasRight_edit"
        aria-labelledby="offcanvasRight_editLabel"
      >
         {loading ? (
                        // Render a loading indicator while data is being fetched
                        <div className='Loading-img'><img src={loadergif} className='' /></div>

                      ) : (
                        <div>   {dishviewdata?.map((dish) => (
                          <div key={dish}>
                            {/* <h3>Dish Name: {dish.dish_name}</h3>
                          <p>Description: {dish.description}</p> */}
                            {/* Add more fields as needed */}
                          </div>
                        ))}</div>
                      )
                      }
     
          

        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRight_editLabel">
            Edit Dish
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* <div className="Img_upload">
            <img src={image} className="img-firud uplode_img" />
            <input
              id="img_upload"
              type="file"
              name="image"
              onChange={handleChangeImg}
              className="mb-2"

            />
          </div> */}
          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleSubmitUpdate}>

              <div className="col-md-12">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    Niyaz Type
                  </label>
                  <Select
                    options={NiyazListData}
                    className="dropdown"
                    placeholder="Select Niyaz Type"
                    id="inputrice"
                    styles={colourStyles}
                  
                    value={NiyazListData.find((option) => option.value === niyaztype?.value)}
                    defaultValue={cleartype}
                    onChange={(e) => setNiyazType(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {/* {
                    validationerror && !portionid &&
                    <p className="show_invalid_text" >  Portion is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Dish Name
                  </label>
                  <input
                    type="text"
                    placeholder="Dish Name"
                    className={`${validationerror && !name ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />

                  {
                    validationerror && !name &&
                    <p className="show_invalid_text" >Dish Name is required</p>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Dish type
                  </label>
                  <Select
                    options={dishTypeListData}
                    className={`${validationerror && !optiondata ? "validation_valid dropdown" : "dropdown"}`}
                    placeholder="Select dish"
                    id="inputState"
                    styles={colourStyles}
                    onChange={(e) => setoptiondata(e.label)}
                    isClearable={false}
                    hideSelectedOptions={false}
                    // defaultValue={editdishtype}
                    // defaultValue={dataget.dish_type}
                    value={dishTypeListData.find((option) => option.label === optiondata)}
                  />
                  {
                    validationerror && !optiondata &&
                    <p className="show_invalid_text" >Dish Type is required</p>
                  }
                </div>
              </div>

              {/* <div className="col-md-6">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    ROTI (Allow Skip)
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="onoffswitch">
                          <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox " id="myonoffswitch" checked={roticheck === "1"}
                            onChange={handleToggleChange} />
                          <label className="onoffswitch-label" for="myonoffswitch">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>

                      </div>
                    </div>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col-md-6">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Rice (Allow Skip)
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="onoffswitch">
                          <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                            checked={ricecheck === "1"}
                            onChange={handleToggleChange2} />
                          <label className="onoffswitch-label" for="rice_check">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Caterer
                  </label>
                  <Select
                    options={[...getCatererlistdata]}
                    className={`${validationerror && !catererdata ? "validation_valid dropdown" : "dropdown"}`}
                    placeholder="Select Catere"
                    styles={colourStyles}
                    onChange={(selectedOption) => {
                      // Assuming selectedOption.value contains the new value
                      setCatererdata(selectedOption.value); // Update the state first
                      // VeswDishesId2(selectedOption.value); // Call your function
                    }}

                    isClearable={false}
                    hideSelectedOptions={true}
                    value={getCatererlistdata.find((option) => option.value === catererdata)}
                  />
                  {
                    validationerror && !catererdata &&
                    <p className="show_invalid_text" > Caterer is required</p>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Dish Note
                  </label>
                  <input
                    type="text"
                    placeholder="Dish Note"
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setDishNote(e.target.value)}
                    value={dishnote}
                  />
                  {/* {
                    validationerror && !dishnote && 
                    <p className="show_invalid_text" > Dish Note is required</p>
                  } */}
                </div>
              </div>


              <div className="Ingredients_title position-relative p-0" >
                <h1>Ingredient&nbsp;({ingredientcount})</h1>
                <button type="button" onClick={handleAddSection}>Add Ingredient</button>
              </div>

              <div className="col-md-6">
                <label htmlFor="Ingredients" className="form-label">
                  Ingredients
                </label>
              </div>
              <div className="col-md-2">
                <label htmlFor="Qty" for="Qty" className="form-label">
                  Qty
                </label>
              </div>
              <div className="col-md-3">
                <label htmlFor="Unit" for="Unit" className="form-label">
                  Unit
                </label>
              </div>
              <div className="col-md-1">
              </div>

              {dishnum.length > 0 ? (
                <>
                  {dishnum.map((item, index) => (
                    <div className="row" key={item.ingredient_name}>
                      <div className="col-md-6" >
                        <div className="Form_box">
                          <Select
                            onChange={(e) => handleIngredientUpdate(e, index)}
                            className="dropdown"
                            placeholder="Ingredients"
                            id={`Ingredients${index}`}
                            styles={colourStyles}
                            isClearable={false}
                            options={ingredientlist}
                            value={ingredientlist?.filter(function (option) {
                              return option?.value === ingredientlistdata[index];
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="Form_box">
                          <input
                            type="number"
                            placeholder="Qty"
                            className="form-control"
                            min="0"
                            step="any"
                            onKeyDown={preventMinus}
                            id={`Qty${index}`}
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => {
                              const newQuality = [...quantityname];
                              newQuality[index] = e.target.value;
                              setquantityname(newQuality);
                            }}
                            value={ingredientlistdata[index] ? quantityname[index] : ""}
                            />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="Form_box">
                          <input
                          type="text"
                          placeholder="Unit"
                          className="form-control"
                          disabled
                          id="Qty"
                          value={ingredientlistdata[index] ? unitdatalist[index] : ""}
                        />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="Form_box">
                          <button type="button" className="Remove_btn"onClick={() => handleRemoveSection(index)}>
                            <img src={remove} className="img-firud" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )


                : (
                  // Render when Inventorydata is empty
                  <div className="No_Inventory">
                    <h1 >Add ingredient </h1>
                  </div>
                )}
              <div className="Ingredients_title">
               <button onClick={() => handleSubmitCopy()}
                    type="button" id="modal_delete_btn2" data-bs-dismiss="offcanvas"
                    aria-label="Close" >Create Copy
                </button>
                { niyaaz_type_view === 0 &&
                <button onClick={() => handleCopyToMiqaat()}
                    type="button" id="modal_delete_btn2" data-bs-dismiss="offcanvas"
                    aria-label="Close" >Copy to Miqaat
                </button>
                }
                <h1></h1>
                <div className="Save_btn">
                  <button type="button" data-bs-dismiss="offcanvas"
                    aria-label="Close" id="modal_close_btn2">Cancel</button>
                  <button type="submit" onClick={handleSubmitUpdate} data-bs-dismiss="offcanvas"
                    aria-label="Close">Save Changes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>




      <Modal
        isOpen={ViewIsOpen}
        onAfterOpen={ViewOpenModal}
        onRequestClose={ViewcloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal dish-viwe">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Popup_heding">
              {/* <img src={dishlist} className="img-firud" /> */}
              <div className="edit_div">
                <h1>View Dish </h1>
              </div>
            </div>
            <div>
              <button type="button" >
                <img src={edit} className="img-fluid" />
              </button>
              <button onClick={ViewcloseModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                    fill="#2E294E"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="modal_body">

            <>
              <div>
                <div className="calender_tabal" >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {" "}
                          <span className="thali_table">Dish Name</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Dish Type</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Dish Note</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Roti</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Rice</span>
                        </th>
                        {/* <th scope="col">
                              <span className="thali_table">ingredient count</span>
                            </th> */}
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <th scope="row">
                          {" "}
                          <ul className="thali_menu_list">
                            <li>
                              {" "}
                              {name ? name : 'NA'}
                            </li>
                          </ul>
                        </th>

                        <th scope="row">
                          {" "}
                          <ul className="thali_menu_list">
                            <li>
                              {" "}
                              {optiondata ? optiondata : 'NA'}
                            </li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>
                              {dishnote ? dishnote : 'NA'}
                            </li>

                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list new_data">
                            <li>
                              {ricecheck ? ricecheck : 'NA'}
                            </li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list new_data">
                            <li>
                              {roticheck ? roticheck : 'NA'}
                            </li>
                          </ul>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>

          </div>
        </div>
      </Modal>


      {/* Remove dish */}
      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove {dishname} Dish</h1>
            <p>
              Do you really want to remove Dish? This action cannot be undone
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button onClick={() => deleteDishesId(id)} className="btn thali_btn">
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={EventIsOpen}
        onAfterOpen={EventOpenModal}
        onRequestClose={EventcloseModal}
        style={customModal}
        contentLabel="EventIsOpen Modal"
        id="edit_popup"
      >
        <div className="popup_modal event-div">
          <button onClick={EventcloseModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                fill="#2E294E"
              />
            </svg>
          </button>
          <div className="Remove_from">
            <img src={evnetlog} className="img-firud event-img" />
            <h1>View Grassy</h1>
            {/* <p>
              Do you really want to remove Dish? This action cannot be undone
            </p> */}
            <div className="thali_table_section new-table ">
              <div className="Registers_tabal">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        {" "}
                        <span className="thali_table">Date</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">descritiopn</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">created by(full name)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {EventType?.map((item, index) => {
                      const formattedDate = new Date(item?.created_at).toLocaleDateString();

                      return (
                        <tr >
                          <th scope="row">
                            <ul className="thali_menu_list view_Registeruser">
                              <li className="Form_box">
                                {formattedDate}
                              </li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>
                                {item.event_body}
                              </li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>
                                {item.full_name}
                              </li>
                            </ul>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="Thali_page_btn_from btn-div">
              <button onClick={EventcloseModal} className="btn thali_btn">
                Close
              </button>
              {/* <button  className="btn thali_btn">
                Remove
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

