import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datetime/css/react-datetime.css";
import Header from "../Header/Header";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Chicken from "../../../img/Chicken.png";
import Rice from "../../../img/Icon/Rice.svg";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import Drinks from "../../../img/Icon/Drinks.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Kheericon from "../../../img/Icon/Kheer_icon.png";
import soup from "../../../img/Icon/soup.svg";
import closeicon from "../../../img/closeicon.png";
import backicon from "../../../img/back-icon.png";
import nexticon from "../../../img/next-icon.png";
import edit from "../../../img/edit.png";
import nep from "../../../img/nep.png";
import user_icon from "../../../img/Icon/user_icon.svg";
import { Link, useNavigate } from 'react-router-dom';

// import BigCalender from './BigCalender';
import {
  Calendar,
  momentLocalizer,
  dateFnsLocalizer,
} from "react-big-calendar";
import { getAllcaterer, getThalilist, api_url, menu_schedule } from "../../../Redux/Reducer/AuthReducer";
import moment from "moment";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { Toolbar } from "react-big-calendar";
import Select from "react-select";
import remove from "../../../img/remove.png";
import dishlist from "../../../img/dishlist.png";
import axios from "axios";
import { toast } from "react-toastify";
import {
  createCalender,
  getDishType,
  getDishTypeView, getThaliview, Updatethali, dishtypelistall, deletethali, getDishName, getDishNameView
} from "../../../Redux/Reducer/AuthReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/'
import { useDispatch } from "react-redux";
import loadergif from '../../../img/landing/loader123.gif';
import { set } from "date-fns";
const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#AE8340" : null,
      boxShadow: isFocused ? "none" : "none",
      color: "#333333",
    };
  },
};
const localizer = momentLocalizer(moment);

function CustomToolbar(props) {
  const { label, onView, onNavigate, view, hideCalendar } = props;

  const handleViewChange = (newView) => {
    onView(newView);
  };
  const handleNext = () => {
    onNavigate("NEXT");
  };

  const handlePrev = () => {
    onNavigate("PREV");
  };
  const handleDayButtonClick = () => {
    hideCalendar();
  };
  return (
    <div className="rbc-toolbar">
      <div className="calender_titile">
        <h1>Calendar Setup</h1>
      </div>
      <div className="rbc-toolbar-buttons">
        <button onClick={handlePrev}>
          <img src={backicon} className="img-fluid" />
        </button>
        <div className="rbc-toolbar-label">{label}</div>
        <button onClick={handleNext}>
          <img src={backicon} className="img-fluid" />
        </button>
      </div>
      <div className="month_btn">
        <button
          className={view === "month" ? "active" : ""}
          onClick={() => handleViewChange("month")}
        >
          Month
        </button>
        {/* <button
          className={view === "week" ? "active" : ""}
          onClick={() => handleViewChange("week")}
        >
          Week
        </button>
        <button
          className={view === "day" ? "active" : ""}
          onClick={() => handleDayButtonClick()}
        >
          Day
        </button> */}
      </div>
    </div>
  );
}


function CustomEvent({ event }) {
  const currentDate = moment().startOf('day');
  const previousDate = moment(event?.start).startOf('day');
  const isTodayOrFuture = moment(previousDate).isSameOrAfter(currentDate, 'day');
  // const eventlist =event.map((scheduleTypedata) => scheduleTypedata?.scheduleType );

console.log ("Event", event)


  return (
    <>
      <div className={`custom-event ${event.schedule_type === "MIQAAT" ? "MIQAAT" : "FMBThali"} ${event.save_draft == "0" ? "0" : "save_draft"} ${event.jamanType == "ORDERING" ? "ORDERING" : "COOKING"}`}>

        {event.save_draft == "1" && (<span className="Draft_thali_event">{event.save_draft == "1" ? "Draft" : ""}</span>)}
        {event.schedule_type == "MIQAAT" && event.is_niyaaz != '1' && (<span className="Draft_MIQAAT_event">{event.schedule_type == "MIQAAT" && event.is_niyaaz != '1' ? "MIQAAT" : ""}</span>)}
        {event.schedule_type == "MIQAAT" && event.is_niyaaz == "1" && (<span className="Draft_NIYAAZ_event">{event.is_niyaaz == "1" ? "PRIVATE" : ""}</span>)}
        {event.schedule_type == "FMBTHALI" && event.is_niyaaz == "1" && (<span className="Draft_NIYAAZ_event">{event.is_niyaaz == "1" ? "MIQAAT-THALI" : ""}</span>)}
        {event.jamanType == "ORDERING" && (<span className="Draft_ordering_event">{event.jamanType == "ORDERING" ? "ORDERING" : ""}</span>)}
        {/* <div className="Menu_test">Menu</div>
        {event.menudata && JSON.parse(event.menudata).map((menuItem, index) => (

          <div className="week_list d-none" key={index}>
            <div className="event-title" >
              {menuItem === 'Rice' && (
                <img src={Rice} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Roti' && (
                <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Gravy' && (
                <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Sweetes' && (
                <img src={Kheericon} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Soup' && (
                <img src={soup} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Drinks' && (
                <img src={Drinks} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Mango' && (
                <img src={Drinks} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Salad' && (
                <img src={Drinks} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Kheer' && (
                <img src={Kheericon} className="img-firud" alt="Veg Dish" />
              )}
              {menuItem === 'Kadai Gosh' && (
                <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
              )}

              {menuItem === 'FriedChicken' && (
                <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
              )}

              {menuItem}
            </div>
          </div>
        ))} */}
         &nbsp;
        <ul className="select_div d-none">
        &nbsp;
          {event.menudata && JSON.parse(event.menudata).map((menuItem, index, menuArray) => (
            <li key={index} className={`event ${menuItem === menuArray[menuArray.length - 1] ? 'allsame' : ''}`}>
              <div className="event-title">
                {/* {menuItem === 'Rice' && (
                  <img src={Rice} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Roti' && (
                  <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Gravy' && (
                  <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Sweetes' && (
                  <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Soup' && (
                  <img src={soup} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Drinks' && (
                  <img src={Drinks} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Mango' && (
                  <img src={Drinks} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Salad' && (
                  <img src={Drinks} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Kheer' && (
                  <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                )}
                {menuItem === 'Kadai Gosh' && (
                  <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                )}

                {menuItem === 'FriedChicken' && (
                  <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                )} */}
                {menuItem}
              </div>
            </li>
          ))}
        </ul>
        {/* <div className="d-none show_btn">View More</div> */}
        <div className="fateha_data fateha_div">
          <div className="Menu_test">fateha</div>
          <div className="event-title">

            {event.is_fateha_dish_name ? event.is_fateha_dish_name : ""}&nbsp;({event.title ? event.title : "NA"})

          </div>
        </div>

        <div className="fateha_data">
          <div className="Menu_test">Caterers</div>
          <div className="event-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                d="M0.856374 4.9042L3.09048 6.76596L2.39897 9.58519C2.23939 10.1171 2.82452 10.5427 3.30326 10.2235L5.80333 8.68091L8.3034 10.2235C8.78214 10.4895 9.36727 10.0639 9.20769 9.58519L8.51618 6.76596L10.7503 4.9042C11.1758 4.53185 10.9631 3.84034 10.3779 3.84034L7.45232 3.62756L6.33526 0.914717C6.12249 0.43598 5.43098 0.43598 5.21821 0.914717L4.10115 3.62756L1.17553 3.84034C0.696794 3.89353 0.484022 4.58504 0.856374 4.9042Z"
                fill="#777391"
              />
            </svg>
            &nbsp;&nbsp;&nbsp; <span>{event.cater_name1 ? event.cater_name1 : 'NA'}</span>
          </div>
          <div className="event-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                d="M0.856374 4.9042L3.09048 6.76596L2.39897 9.58519C2.23939 10.1171 2.82452 10.5427 3.30326 10.2235L5.80333 8.68091L8.3034 10.2235C8.78214 10.4895 9.36727 10.0639 9.20769 9.58519L8.51618 6.76596L10.7503 4.9042C11.1758 4.53185 10.9631 3.84034 10.3779 3.84034L7.45232 3.62756L6.33526 0.914717C6.12249 0.43598 5.43098 0.43598 5.21821 0.914717L4.10115 3.62756L1.17553 3.84034C0.696794 3.89353 0.484022 4.58504 0.856374 4.9042Z"
                fill="#777391"
              />
            </svg>
            &nbsp;&nbsp;&nbsp;  <span>{event.cater_name2 ? event.cater_name2 : 'NA'}</span>
          </div>
        </div>

        <div className="fateha_data">
          <div className="Menu_test">Remarks</div>
          <div className="event-title">
            {event.notesdata}
          </div>
          {isTodayOrFuture && (
            <button className="btn event_btn">Edit</button>
          )}
        </div>
        {/* <button className='Event_btn'>view More</button> */}
      </div>
    </>
  );
}


function getNextThursday(selectedDate) {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = weekdays[selectedDate.getDay()];

  const daysUntilThursday = (4 - weekdays.indexOf(dayOfWeek) + 7) % 7;
  const nextThursdayDate = new Date(selectedDate);
  nextThursdayDate.setDate(selectedDate.getDate() + daysUntilThursday);

  const formattedNextThursday = moment(nextThursdayDate).format('MM/DD/YYYY');
  return formattedNextThursday; // Return the date in DD/MM/YYYY format
}
export default function CalendarPage(props) {
  const user_Role = localStorage.getItem("access_Role");
  function RemoveModal(id) {
    setIsRemoveOpen(true);
    setRemovelist(id)
    console.log("ddd",id);
  }

  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }
  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  var token = localStorage.getItem("access_token");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [existingEventlist, setexistingEventList] = useState("");
  const [Bigdate, setBigdate] = useState("");
  const [removelist, setRemovelist] = useState("");
  const [events, setEvents] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showpopupcurrent, setShowPopupCurrent] = useState(false);
  const [editshowPopup, setEditShowPopup] = useState(false);
  const [showview, setShowview] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDivVisible, setDivVisible] = useState(false);
  const [addDish, setAddDish] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("FMBTHALI");
  const [selectedroti, setSelecteRoti] = useState("HALF");
  const [selectedgravy, setSelectedGravy] = useState("HALF");
  const [selecteddish, setSelectedDish] = useState("FULL");
  const [ricelist, setRiceList] = useState("HALF");
  const [jamantype, setJamanType] = useState("COOKING");
  const [scheduleday, setScheduleDay] = useState("");
  const [pickupendtime, setPickupEndTime] = useState("");
  const [catererid1, setCatererId1] = useState("");
  const [catererid2, setCatererId2] = useState("");
  const [createduser, setCreatedUser] = useState("");
  const [wantrice, setWantRice] = useState("");
  const [wantroti, setWantRoti] = useState("");
  const [dishid, setDishId] = useState(null);
  const [dishtypeid, setDishTypeId] = useState('');
  const [isfateha, setIsFateha] = useState("");
  const [fatehaname, setFatehaName] = useState("");
  const [wantgravy, setWantGravy] = useState("");
  const [rotitype, setRotiType] = useState("");
  const [gravytype, setGravyType] = useState("");
  const [ricetype, setRiceType] = useState("");
  const [cleartype, setCleartype] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [duplicateCount, setDuplicateCount] = useState(0);
  const [dishname, setDishName] = useState("");
  const [storedDishes, setStoredDishes] = useState([]);
  const [dishtypelist, setDishTypeList] = useState("");
  const [dishNamelist, setDishNameList] = useState("");
  const [optiondata, setoptiondata] = useState("");
  const [type, setType] = useState([]);
  const [dishnamelistdata, setDishNameListData] = useState("");
  const [dishIdList, setDishIdList] = useState(
    Array.from({ length: storedDishes?.length }, () => "")
  );
  const [catererlist, setCatererlist] = useState([]);
  const [catererfist, setCatererfirst] = useState("");
  const [caterersecond, setCaterersecond] = useState("");
  const [thalidata, setThalidata] = useState([]);
  const [order, setOrder] = useState("");
  const [Viswthali, setViswthali] = useState("");
  const [comment, setComment] = useState("");
  const [note, setNote] = useState("");
  const moment = require('moment');
  const currentDate = moment(selectedDate).format('YYYY-MM-DD');
  const [hideCalendar, setHideCalendar] = useState(true);
  const [getthalidataall, setGetThaliDataAll] = useState([]);
  const Thaliviewdata = Object.values(getthalidataall);
  const [disheditdata, setDishEditdata] = useState([]);
  const [disheditdataname, setDishNameData] = useState([]);
  const [updatethalidata, setUpdateThaliData] = useState([]);
  const [newdishlist, setNewDishList] = useState([]);
  const selectedDatelist = new Date(selectedDate);
  const nextThursdayDate = getNextThursday(selectedDatelist);
  const [alldatelist, setAllDateList] = useState(nextThursdayDate);
  const [endtime, setEndTime] = useState({ value: "1", label: "10.00 AM" });
  const [currentPage, setCurrentPage] = useState(1);
  const [validationerror, setValidationerror] = useState(false);
  const [drafitall, setDrafitAll] = useState('');
  const [errormasg, setErrorMasg] = useState("");
  const [savedraft, setSaveDraft] = useState("0");
  const [is_niyaaz, setIsNiyaaz] = useState("0");
  const [namedishtype, setNameDishType] = useState("");
  const [Defultdishtype, setDefultDishType] = useState({ value: "", label: '' });
  const [selectedOption2, setSelectedOption1] = useState("1");
  const [allowSkipRoti, setAllowSkipRoti] = useState("0");
  const [allowSkipRice, setAllowSkipRice] = useState("0");
  const itemsPerPage = 10; // Number of items to display per page

  const pageCount = Math.ceil(thalidata?.length / itemsPerPage);

function convertDateForIos(date) {
    var arr = date?.split(/[- :]/);
    date = new Date(arr[2], arr[0]-1, arr[1]);
    return date;
}

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > pageCount) {
      setCurrentPage(pageCount);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(pageCount);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const displayedItems = thalidata?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const locales = {
    "en-US": enUS,
  };

  console.log("currentDate", selectedDate);

  useEffect(() => {
    // Calculate next Thursday date and set it as initial value
    setAllDateList(nextThursdayDate);
  }, [nextThursdayDate]);

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format('MM/DD/YYYY');
    setAllDateList(formattedDate);
    console.log('formattedDate', formattedDate)
  };

  console.log('alldatelist', alldatelist)
  const now = new Date();
  const localizer = momentLocalizer(moment);

  const getCatererData = () => {
    dispatch(getAllcaterer()).then((res) => {
      if (res?.payload?.status == true) {
        setCatererlist(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getnewdishlist = () => {
    dispatch(dishtypelistall()).then((res) => {
      if (res?.payload?.status == true) {
        setNewDishList(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getdeletethali = (id) => {
    dispatch(deletethali(id)).then((res) => {
      if (res?.payload?.status == true) {

        setShowview(false)
        // setNewDishList(res?.payload?.data);
        getThaliData();
        toast.success("Remove Thali successful");
          setIsRemoveOpen(false);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };


  let getnewlistdata;
  if (newdishlist && Array.isArray(newdishlist)) {
    getnewlistdata = newdishlist?.map((item) => ({
      label: item?.dish_name,
      value: item?.id,
    }));
  } else {
    getnewlistdata = [];
  }

  const access_id = localStorage.getItem("access_id");
  const handleUpdateSubmit2 = (e, savedraftall) => {
    setLoading(true);
    e.preventDefault();
    const obj = disheditdata?.map((item) => ({
      dish_id: item?.dish_id ? item?.dish_id : item.dishid.value || '', // Use default value only if dish_name is null or undefined
      quantity: item?.quantity ? item?.quantity : item?.selecteddish || '', // Use default value only if quantity is null or undefined
      is_fateha: "false",
    }));

    const requestData = {
      schedule_type: selectedOption,
    };


    if (selectedOption === "FMBTHALI") {

      requestData.save_draft = savedraftall;
      requestData.is_niyaaz = is_niyaaz;
      requestData.jamanType = jamantype || jamantype; // Set default value as an empty string
      requestData.dish = obj  // Set default value as an empty array
      requestData.schedule_type = selectedOption;
      requestData.schedule_day = currentDate || currentDate; // Set default value as an empty string
      requestData.pickup_start_time = currentDate || currentDate; // Set default value as an empty string
      requestData.pickup_end_time = currentDate || currentDate; // Set default value as an empty string
      requestData.caterer_id1 = catererfist?.value || catererfist; // Set default value as an empty string
      requestData.caterer_id2 = caterersecond?.value || caterersecond; // Set default value as an empty string
      requestData.notes = comment || comment; // Set default value as an empty string
      requestData.order_by = order; // Set default value as an empty string
      requestData.source = "community";
      requestData.created_user = access_id;
      requestData.dish_id = dishtypeid?.value || dishtypeid;
      requestData.is_active = "1";
      requestData.portion_type_code = "1";
      requestData.pickup_location_code = "2";
      requestData.allow_skip_rice = allowSkipRice;
      requestData.allow_skip_roti = allowSkipRoti;
      requestData.quantity = selectedroti || selectedroti; // Set default value as an empty string
      requestData.fateha_name = fatehaname || fatehaname; // Set default value as an empty string
      requestData.rsvp_date = alldatelist;
      requestData.rsvp_time = endtime;
    } else if (selectedOption === "MIQAAT") {
      requestData.save_draft = savedraftall;
      requestData.is_niyaaz = is_niyaaz;
      requestData.jamanType = jamantype || jamantype; // Set default value as an empty string
      requestData.dish = obj  // Set default value as an empty array
      requestData.schedule_type = selectedOption;
      requestData.schedule_day = currentDate || currentDate; // Set default value as an empty string
      requestData.pickup_start_time = currentDate || currentDate; // Set default value as an empty string
      requestData.pickup_end_time = currentDate || currentDate; // Set default value as an empty string
      requestData.caterer_id1 = catererfist?.value || catererfist; // Set default value as an empty string
      requestData.caterer_id2 = caterersecond?.value || caterersecond; // Set default value as an empty string
      requestData.notes = comment || comment; // Set default value as an empty string
      requestData.order_by = order; // Set default value as an empty string
      requestData.source = "community";
      requestData.created_user = access_id;
      requestData.dish_id = dishtypeid?.value || dishtypeid;
      requestData.is_active = "1";
      requestData.portion_type_code = "1";
      requestData.pickup_location_code = "2";
      requestData.allow_skip_rice = allowSkipRice;
      requestData.allow_skip_roti = allowSkipRoti;
      requestData.quantity = selectedroti || selectedroti; // Set default value as an empty string
      requestData.fateha_name = fatehaname || fatehaname; // Set default value as an empty string
      requestData.rsvp_date = alldatelist;
      requestData.rsvp_time = endtime; // Set default value as an empty string
    } else if (selectedOption === "MIQAAT") {
      requestData.save_draft = savedraftall;
      requestData.is_niyaaz = is_niyaaz;
      requestData.jamanType = jamantype || jamantype; // Set default value as an empty string
      requestData.dish = obj  // Set default value as an empty array
      requestData.schedule_type = selectedOption;
      requestData.schedule_day = currentDate || currentDate; // Set default value as an empty string
      requestData.pickup_start_time = currentDate || currentDate; // Set default value as an empty string
      requestData.pickup_end_time = currentDate || currentDate; // Set default value as an empty string
      requestData.caterer_id1 = catererfist?.value || catererfist; // Set default value as an empty string
      requestData.caterer_id2 = caterersecond?.value || caterersecond; // Set default value as an empty string
      requestData.notes = comment || comment; // Set default value as an empty string
      requestData.order_by = order; // Set default value as an empty string
      requestData.source = "community";
      requestData.created_user = access_id;
      requestData.dish_id = dishtypeid?.value || dishtypeid;
      requestData.is_active = "1";
      requestData.portion_type_code = "1";
      requestData.pickup_location_code = "2";
      requestData.allow_skip_rice = "0";
      requestData.allow_skip_roti = "0";
      requestData.quantity = selectedroti || selectedroti; // Set default value as an empty string
      requestData.fateha_name = fatehaname || fatehaname; // Set default value as an empty string
      requestData.rsvp_date = alldatelist;
      requestData.rsvp_time = endtime;
    }

    dispatch(Updatethali({ id: Viswthali, values: requestData }))
      .then((res) => {
        // Handle the successful update
        if (res?.payload?.status === true) {
            toast.success(res?.payload.message);
            handleEventSubmit();
            setEditShowPopup(false);
            getThaliData();
        } else{
          toast.error(res?.payload.message);
        }
      })
      .catch((error) => {
        // Handle the failed update
        toast.error(error.message);
      });

  };

  const getthaliId = (id) => {
    dispatch(getThaliview(id)).then((res) => {
      if (res?.payload?.status === true) {
        setGetThaliDataAll(res?.payload);
        thalilistviewdata(res?.payload)
        setDishEditdata(res?.payload.dish)
        EditThali();
        console.log('sdsdsd')
      } else {
        toast.error(res?.payload?.message);
      }
    });
  }

  const thalilistviewdata = (Thalidatalist) => {
    const alldishlist = Thalidatalist.dish;
    const allthalilist = Thalidatalist.thali;
    console.log('dataaaaaa', allthalilist.schedule_type)
    // const setoptionthali = allthalilist.map((allthalilist) => allthalilist.schedule_type);
    setSelectedOption(allthalilist.schedule_type)
    setIsNiyaaz(allthalilist.is_niyaaz)
    setJamanType(allthalilist.jamanType)
    allthalilist.jamanType === 'COOKING' ? setDivVisible (false) : setDivVisible (true)
    setCatererfirst(allthalilist.caterer_id1)
    setCaterersecond(allthalilist.caterer_id2)
    setFatehaName(allthalilist.fateha_name)
    setAllowSkipRice(allthalilist?.allow_skip_rice)
    setAllowSkipRoti(allthalilist?.allow_skip_roti)
    setOrder(allthalilist.order_by)
    setAllDateList(allthalilist.rsvp_date)
    setEndTime(allthalilist.rsvp_time)
    setDishId(allthalilist.id)
    setSaveDraft(allthalilist.save_draft)
    setDishTypeId(allthalilist.dish_id)
    setViswthali(allthalilist.id)
    setComment(allthalilist.notes)
    const dish_name = alldishlist.map((alldishlist) => alldishlist.dish_name);
    setDishNameData(dish_name);

  }



  const getThaliData = () => {
    setLoading(true);
    dispatch(getThalilist()).then((res) => {
      if (res?.payload?.status == true) {
        setThalidata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };


  let getCatererlistdata;
  if (catererlist && Array.isArray(catererlist)) {
    getCatererlistdata = catererlist?.map((item) => ({
      label: `${item?.first_name} ${item?.last_name ? item?.last_name : '-'}`,
      value: item?.id,
    }));
  } else {
    getCatererlistdata = [];
  }


  const timezone = [
    { value: "1", label: "10.00 AM" },
    { value: "2", label: "11.00 AM" },
    { value: "3", label: "12.00 AM" },
    { value: "5", label: "01.00 PM" },
    { value: "6", label: "02.00 PM" },
    { value: "7", label: "03.00 PM" },
    { value: "8", label: "04.00 PM" },
    { value: "9", label: "05.00 PM" },
    { value: "10", label: "06.00 PM" },
    { value: "11", label: "07.00 PM" },
    { value: "12", label: "08.00 PM" },
    { value: "13", label: "09.00 PM" },
    { value: "14", label: "10.00 PM" },
    { value: "15", label: "11.00 PM" },
    { value: "16", label: "12.00 PM" },
    { value: "17", label: "01.00 AM" },
    { value: "18", label: "02.00 AM" },
    { value: "19", label: "03.00 AM" },
    { value: "20", label: "04.00 AM" },
    { value: "21", label: "05.00 AM" },
    { value: "22", label: "06.00 AM" },
    { value: "23", label: "07.00 AM" },
    { value: "24", label: "08.00 AM" },
    { value: "4", label: "09.00 AM" },
  ];
  const openEventPopup = (event) => {
    setSelectedEvent(event);
  };

  const EditThali = () => {
    setEditShowPopup(true)
    setShowview(false)

  };

  const closeEventPopup = () => {
    setSelectedEvent(null);
    setShowview(false)
  };
  const handlePopupClose = () => {
    setShowPopup(true);
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.color || "#fff";
    const style = {
      marginTop: -45,
      borderRadius: "3px",
      opacity: isSelected ? 1 : 1,
      color: "#fff",
      border: "none",
      display: "block",
    };
    return { style };
  };

  const components = {
    event: CustomEvent,
    toolbar: (toolbarProps) => (
      <CustomToolbar {...toolbarProps} hideCalendar={handleHideCalendar} />
    ),
  };
  const handleHideCalendar = () => {
    setHideCalendar(false);
  };
  const handleHideCalendar2 = () => {
    setHideCalendar(true);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleEventCreate = ({ slotInfo, start, events }) => {
    setSelectedSlot(slotInfo);
    setSelectedDate(start);
    console.log ("setSelectedDate", start, selectedDate)

    let dateLog = moment(selectedDate)?.format("MM/DD/YYYY")
    let dateFormatLog = selectedDate?.toDateString().split(" ")[0]

    console.log ("dateLog dateFormatLog", dateLog, dateFormatLog)
    
    const existingEvent = events4.filter((event) => moment(event.start).isSame(start, 'day'));
    console.log('existingEvent', existingEvent)
    if (existingEvent.length > 0) {
      setShowPopup(false);
      setShowview(true)
      console.log('existingEvent', existingEvent)
      setexistingEventList(existingEvent)
    } else {
      console.log('No existing event found for this date');
      // Perform actions for no existing event
      if (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM"))
      {
        setShowPopup(true);
      }
    }
  };

  const handleplus = () => {
    setShowPopup(true);
    setShowview(false)
  };

  const handleEventSubmit = (newEvent) => {
    setEvents([...events, newEvent]);
    setShowPopup(false);
  };

  const handleTabClick = (event) => {
    setDivVisible(!isDivVisible);
    setJamanType(event.target.value);
  };

  function AddDishModal() {
    setAddDish(true);
    setRepetitionCount("0")
  }
  function DishcloseModal() {
    setAddDish(false);
    setRepetitionCount("0")
    reset();
  }
  function RemoveOpenModal() {
  }

  const handleRadioChange = (event) => {
  setSelectedOption(event.target.value);
  setIsNiyaaz ('0')
};

const handleRadioNiyaazChange = (event) => {
  setSelectedOption(event.target.value);
  setIsNiyaaz ('1')
};

  const handleRadioButtonChange = (e, index) => {
    const { value } = e.target;

    setStoredDishes((prevDishes) =>
      prevDishes.map((item, i) =>
        i === index ? { ...item, selecteddish: value } : item
      )
    );
  };


  const handleRadioButtonChange2 = (e, index) => {
    const { value } = e.target;
    const updatedDishes = disheditdata.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          quantity: value,
        };
      }
      return item;
    });
    const updatedDishes2 = disheditdata.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          selecteddish: value,
        };
      }
      return item;
    });

    console.log("updatedDishes2", updatedDishes2);
    setDishEditdata(updatedDishes);
  };

  const handleGravyChange = (event) => {
    setSelectedGravy(event.target.value);
  };

  const handleRiceChange = (event) => {
    setRiceList(event.target.value);
  };
  const handleDishChange = (event) => {
    setSelectedDish(event.target.value);
  };

  const popupRef = useRef(null);

  useEffect(() => {
    getDishtypedata();
    getCatererData();
    getThaliData();
    getnewdishlist();

  }, []);

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  const handleCreateCalendar = (requestData) => {
    dispatch(createCalender(requestData));
  };

  const fetchDataInterval = 3000; // 60 seconds (adjust as needed)

  const fetchThaliData = () => {
    getThaliData(); // Call your data-fetching function here
  };

  const handleSubmit2 = (e, savedraftall) => {
    console.log('ss', e, "savedraftall", savedraftall)
    e.preventDefault();
    let obj; // Declare obj here with an initial value or leave it undefined
    if (Array.isArray(storedDishes)) {
      obj = storedDishes.map((item, i) => ({
        dish_id: item?.dishid?.value,
        quantity: item?.selecteddish,
        is_fateha: "false",
      }));
    } else {
      // Handle the case where storedDishes is not an array
      console.error("storedDishes is not an array.");
      // You can add further error handling or return early if needed.
    }


    const requestData = {
      schedule_type: selectedOption,
    };


    if (!Array.isArray(storedDishes) || storedDishes.length == 0

      // || !fatehaname

      // || !dishtypeid

    ) {
      // Handle the case where storedDishes is not an array or is empty
      setValidationerror(true);
      setErrorMasg('Please Add  Dish')
      return false;
    }


    else if (selectedOption === "FMBTHALI") {
      requestData.save_draft = savedraftall;
      requestData.is_niyaaz = is_niyaaz;
      requestData.jamanType = jamantype;
      requestData.dish = obj;
      requestData.schedule_type = selectedOption;
      requestData.jamanType = jamantype;
      requestData.schedule_day = currentDate;
      requestData.pickup_start_time = currentDate;
      requestData.pickup_end_time = currentDate;
      requestData.caterer_id1 = catererfist?.value;
      requestData.caterer_id2 = caterersecond?.value;
      requestData.notes = comment;
      requestData.dish_id = dishtypeid?.value;
      requestData.order_by = order;
      requestData.source = "community";
      requestData.created_user = access_id;
      requestData.is_active = "1";
      requestData.portion_type_code = "1";
      requestData.pickup_location_code = "2";
      requestData.quantity = selectedroti;
      requestData.fateha_name = fatehaname;
      requestData.rsvp_date = alldatelist;
      requestData.rsvp_time = endtime.label ? endtime.label : "10.00 AM";
       requestData.allow_skip_rice = allowSkipRice;
      requestData.allow_skip_roti = allowSkipRoti;
    } else if (selectedOption === "MIQAAT") {
      requestData.jamanType = jamantype;
      requestData.is_niyaaz = is_niyaaz;
      requestData.save_draft = savedraftall;
      requestData.dish = obj;
      requestData.schedule_type = selectedOption;
      requestData.jamanType = jamantype;
      requestData.schedule_day = currentDate;
      requestData.pickup_start_time = currentDate;
      requestData.pickup_end_time = currentDate;
      requestData.caterer_id1 = catererfist?.value;
      requestData.caterer_id2 = caterersecond?.value;
      requestData.notes = comment;
      requestData.allow_skip_rice = "0";
      requestData.allow_skip_roti = "0";
      requestData.dish_id = dishtypeid?.value;
      requestData.order_by = order;
      requestData.source = "community";
      requestData.created_user = access_id;
      requestData.is_active = "1";
      requestData.portion_type_code = "1";
      requestData.pickup_location_code = "2";
      requestData.quantity = selectedroti;
      requestData.fateha_name = fatehaname;
      requestData.rsvp_date = alldatelist;
      requestData.rsvp_time = endtime.label;
    }



    setCatererId1('')
    setCatererId2('')
    setCatererfirst('')
    setCaterersecond('')
    setAllowSkipRoti("0")
    setAllowSkipRice("0")
    setFatehaName('')
    setDishId('')
    setComment('')
    setDishNameData('');
    handleCreateCalendar(requestData);
    handleEventSubmit();
    setShowPopup(false)
    setIsFateha('')
    setStoredDishes('')
    setErrorMasg('')
    setValidationerror(false);
    setEndTime({ value: "1", label: "10.00 AM" });
    setTimeout(() => {
      getThaliData();
    }, 3000);

  };
  // useEffect(() => {
  //   const dataFetchInterval = setTimeout(fetchThaliData, fetchDataInterval);

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(dataFetchInterval);
  //   };
  // }, []);

  const handleDuplicate = (e) => {
    // setDuplicateCount(duplicateCount + 1);

    const newDishNum = [...storedDishes, storedDishes?.length + 1];
    setStoredDishes(newDishNum);
    console.log('newDishNumnewDishNum', newDishNum)

    setAddDish(false);
    // setoptiondata(e);
    setDishId(e.value);
    // setSelectedDish(e.target.value);
    setStoredDishes([...storedDishes, { optiondata, dishid, selecteddish }]);
    setDishEditdata([...disheditdata, { optiondata, dishid, selecteddish }]);
    // dishlistdatatype()
    reset();
    getDishnamedata();
  };


  console.log('storedDishes', storedDishes)
  const reset = () => {
    setoptiondata(null);
    setDishId(null);
  };

  const handleRemove = (index) => {
    setDuplicateCount(duplicateCount - 1);
    const updatedDishes = [...storedDishes];
    updatedDishes.splice(index, 1);
    setStoredDishes(updatedDishes);
  };

  const handleEditRemove = (index) => {
    console.log ("handleEditRemove called")

    setDuplicateCount(duplicateCount - 1);
    const updatedDishes = [...disheditdata];
    updatedDishes.splice(index, 1);
    setDishEditdata(updatedDishes);
  };

  const handleNavigateDishPage = (dish_name, schedule_type) => {
    console.log ("handleNavigateDishPage called", dish_name, schedule_type)
    navigate('/Dishes', { state: { schedule_type: schedule_type, dish_name: dish_name} });
  };
  

  const getDishtypedata = () => {
    dispatch(getDishType()).then((res) => {
      if (res?.payload?.status === true) {
        setDishTypeList(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const initialDishType = { value: '', label: '' };
  const Resetfild = (e) => {
    e.preventDefault();
    setDishTypeId(null)
    setFatehaName("")
  }
  const niyaazdish = selectedOption === "FMBTHALI" ? 0 : 1;


  const getDishnamedata = () => {
    dispatch(getDishName(niyaazdish)).then((res) => {
      if (res?.payload?.status === true) {
        setDishNameListData(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  let dishTypeListData = [];
  if (Array.isArray(dishtypelist)) {
    dishTypeListData = dishtypelist.map((item) => ({
      label: item.dish_type,
      value: item.id,
    }));
  } else {
    console.error("dishtypelist is not an array or is undefined");
  }

  let dishNameListall = [];
  if (Array.isArray(dishNamelist)) {
    dishNameListall = dishNamelist.map((item) => ({
      label: item.dish_name,
      value: item.id,
    }));
  } else {
    console.error("dishtypelist is not an array or is undefined");
  }

  console.log('dishNameListall', dishNamelist)

  // const niyaazdish = selectedOption === "FMBTHALI" ? 1 : 0;
  const DishTypeName = (id, niyaazType) => {

    const queryParams = new URLSearchParams();
    queryParams.append('niyaaz_type', JSON.stringify(niyaazType));
    queryParams.append('dish_type', id);


    const url = `${queryParams.toString()}`;
    dispatch(getDishTypeView(url)).then((res) => {
      if (res?.payload === res?.payload) {
        // toast.success(res.payload.message);
        console.log('res', res)
        setDishNameListData(res?.payload?.data);

      } else {
        // toast.error(res.payload.message);
      }
    });
  };

  console.log("niyaazdish", niyaazdish);

  const DishNameType = (id, niyaazType) => {


    const queryParams = new URLSearchParams();
    queryParams.append('dish_type', id);
    queryParams.append('niyaaz_type', JSON.stringify(niyaazType));

    const url = `${queryParams.toString()}`;

    dispatch(getDishNameView(url)).then((res) => {
      if (res?.payload === res?.payload) {

        console.log("res?.payload?.data", res?.payload?.data.repetition_count);
        setNameDishType(res?.payload?.data)
        setRepetitionCount(res?.payload?.data[0]?.repetition_count)
        setoptiondata({
          value: res?.payload?.data[0]?.dish_type,
          label: res?.payload?.data[0]?.dish_type
        })
      } else {
        // Handle the error here
      }
    });
  };



  let dishviewlistdata = [];
  if (Array.isArray(dishnamelistdata)) {
    dishviewlistdata = dishnamelistdata?.map((item) => ({
      label: item.dish_name,
      value: item.id,
      repetitioncountlist: item.repetition_count,
    }));
  } else {
    console.error("dishtypelist is not an array or is undefined new");
  }


  const handleDishIdChange = (index, label) => {
    const updatedDishes = storedDishes?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          dishid: {
            ...item.dishid,
            label: label,
          },
        };
      }
      return item;
    });

    setStoredDishes(updatedDishes);
    getDishnamedata();

  };

  const handleDishIdChange2 = (index, e) => {
    const updatedDishes = disheditdata.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          dish_name: e.label,
          dish_id: e.value
        };
      }
      return item;
    });

    setDishEditdata(updatedDishes);
    getDishnamedata();

  };


  function formatDateTo_mm_dd_yyyy(date) {
    // Get the components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, "0");

    // Format the date as mm-dd-yyyy
    return `${month}-${day}-${year}`;
  }


  const handleAllowSkipRiceToggleChange = (e) => {
    const newValue = allowSkipRice === "1" ? "0" : "1";
    setAllowSkipRice(newValue);
    console.log("newValue", newValue)
  }
  const handleAllowSkipRotiToggleChange = (e) => {
    const newValue = allowSkipRoti === "1" ? "0" : "1";
    setAllowSkipRoti(newValue);
    console.log("newValue3", newValue)
  }


  const events4 = thalidata?.map((item, i) => {
    const startDate = convertDateForIos(item.schedule_day);
    const endDate = moment(startDate).endOf('day').hour(15).toDate();
    return {
      start: new Date(startDate),
      end: endDate,
      title: item.fateha_name,
      cater_name1: item.cater_name1,
      cater_name2: item.cater_name2,
      menudata: item.menu,
      notesdata: item.notes,
      id: item.id,
      schedule_type: item.schedule_type,
      jamanType : item.jamanType,
      save_draft: item.save_draft,
      is_niyaaz: item.is_niyaaz,
      is_fateha_dish_name: item.is_fateha_dish_name

      // caterer_id: '1'
    };
  });
  // setunitDatalist(ingredientunit);
  // console.log("storedDishes", storedDishes[0].dishid, 'sss',);
  const [repetitioncount, setRepetitionCount] = useState('');

  console.log("new", repetitioncount);

  useEffect(() => {
    getDishnamedata();
  }, [selectedOption]);

  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="COlor_box">
                <h5>Today Date<span></span></h5>
                <h5>Miqaat <span></span></h5>
                <h5>Save draft <span></span></h5>
              </div>
              {hideCalendar ? (
<>              
{loading ? (
                        // Render a loading indicator while data is being fetched
                        <div className='Loading-img'><img src={loadergif} className='' /></div>

                      ) : (
                        <div></div>
                      )
                      }

                <Calendar
                  localizer={localizer}
                  events={events4}
                  defaultView="month"
                  views={["day", "week", "month"]}
                  style={{ height: "100vh" }}
                  eventPropGetter={eventStyleGetter}
                  components={components}
                  selectable={true}
                  onSelectSlot={(slotInfo) => handleEventCreate(slotInfo)}
                  onSelectEvent={handleEventCreate}
                  formats={formats}
                />
                </>
              ) : (
                <div className="thali_section">

                  <div className="rbc-toolbar">
                    <div className="calender_titile">
                      <h1>Calendar Setup</h1>
                    </div>
                    <div className="rbc-toolbar-buttons">

                    </div>
                    <div className="month_btn">
                      <button onClick={handleHideCalendar2}>
                        Month
                      </button>
                      <button onClick={handleHideCalendar2}>
                        Week
                      </button>
                      <button onClick={handleHideCalendar2}>
                        Day
                      </button>
                    </div>
                  </div>
                  <div className="custom-event">
                    <div className="calender_table">
                      {loading ? (
                        // Render a loading indicator while data is being fetched
                        <div className='Loading-img'><img src={loadergif} className='' /></div>

                      ) : (
                        <div></div>
                      )
                      }

                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col"><span className='thali_table'>Day & Date</span></th>
                            <th scope="col"><span className='thali_table'>Menu</span></th>
                            <th scope="col"><span className='thali_table'>Caterers</span></th>
                            <th scope="col"><span className='thali_table'>Fateha</span></th>
                            <th scope="col"><span className='thali_table'>Remarks</span></th>
                            <th scope="col"><span className='thali_table'>Actions</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedItems?.map((tahlitems) => {
                            const date = new Date(convertDateForIos(tahlitems.schedule_day));

                            const day = date.toLocaleString('en-US', { weekday: 'long' });
                            const month = date.toLocaleString('en-US', { month: 'long' });
                            const numericDate = date.getDate();
                            const twoDigitYear = date.getFullYear();



                            return (
                              <tr key={tahlitems.id} className={`${tahlitems.schedule_type === "MIQAAT" ? "MIQAATTYPE" : "FMBThaliTYPE"} ${tahlitems.save_draft == "0" ? "0" : "save_draft_LIST"}`}>
                                <th scope="row">
                                  <div className='Calendar_card'>
                                    {" "}
                                    <h6>{day}</h6>
                                    <h1>{numericDate}</h1>
                                    <p>{month}, {twoDigitYear}</p>

                                  </div>
                                </th>
                                <th scope="row">

                                  <ul className="thali_menu_list">

                                    {tahlitems.save_draft == "1" && (<span className="Draft_thali_event">{tahlitems.save_draft == "1" ? "Draft" : ""}</span>)}
                                    {tahlitems.schedule_type == "MIQAAT" && tahlitems.is_niyaaz != "1" && (<span className="Draft_MIQAAT_event">{tahlitems.schedule_type == "MIQAAT" && tahlitems.is_niyaaz != "1" ? "MIQAAT" : ""}</span>)}
                                    {tahlitems.schedule_type == "MIQAAT" && tahlitems.is_niyaaz == "1" && (<span className="Draft_NIYAAZ_event">{tahlitems.is_niyaaz == "1" ? "PRIVATE" : ""}</span>)}
                                    {tahlitems.schedule_type == "FMBTHALI" && tahlitems.is_niyaaz == "1" && (<span className="Draft_NIYAAZ_event">{tahlitems.is_niyaaz == "1" ? "MIQAAT-THALI" : ""}</span>)}
                                    {tahlitems.jamanType == "ORDERING" && (<span className="Draft_ordering_event">{tahlitems.schedule_type == "ORDERING" ? "ORDERING" : ""}</span>)}


                                    {tahlitems.menu ? JSON.parse(tahlitems.menu).map((menuItem, index) => (
                                      <li key={index}>
                                        <div className="event-title">
                                          {menuItem === 'Rice' && (
                                            <img src={Rice} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Roti' && (
                                            <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Gravy' && (
                                            <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Sweetes' && (
                                            <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Soup' && (
                                            <img src={soup} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Drinks' && (
                                            <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Mango' && (
                                            <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Salad' && (
                                            <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Kheer' && (
                                            <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem === 'Kadai Gosh' && (
                                            <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                          )}

                                          {menuItem === 'FriedChicken' && (
                                            <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                                          )}
                                          {menuItem ? menuItem : '-'}
                                        </div>
                                      </li>
                                    )) : "NA"}
                                  </ul>
                                </th>
                                <th scope="row"><ul className='thali_menu_list'>
                                  <li>
                                    {tahlitems.cater_name1 && tahlitems.cater_name1 !== "null" && (<>

                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z" fill="#777391" />
                                      </svg> &nbsp;
                                      {tahlitems.cater_name1 ? tahlitems.cater_name1 : 'NA'}
                                    </>
                                    )}
                                    {/* <img src={user_icon} className='img-fluid' /> */}


                                  </li>
                                  <li>
                                    {tahlitems.cater_name2 && tahlitems.cater_name2 !== "null" && (<>

                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z" fill="#777391" />
                                      </svg> &nbsp;
                                      {tahlitems.cater_name2 ? tahlitems.cater_name2 : 'NA'}
                                    </>
                                    )}
                                    {/* <img src={user_icon} className='img-fluid' /> */}


                                  </li>


                                </ul></th>


                                <th scope="row"><ul className='thali_menu_list'>
                                  <li>{tahlitems.is_fateha_dish_name ? tahlitems.is_fateha_dish_name : ''} &nbsp; ({tahlitems.fateha_name ? tahlitems.fateha_name : 'NA'})</li>
                                </ul></th>
                                <th scope="row">
                                  <ul className='thali_menu_list'>
                                    <li>{tahlitems.notes ? tahlitems.notes : "NA"}</li>
                                  </ul></th>
                                <th scope="row">

                                  <ul className='thali_menu_list'>
                                    <li>
                                      <div className="dropdown action_btn">
                                        <button
                                          className="btn btn-secondary dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton2"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fa fa-ellipsis-h"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton2"
                                        >
                                          {/* <li  onClick={() => getthaliId(tahlitems.id)}>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRight_update"
                                            aria-controls="offcanvasRight_update"

                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="15"
                                              viewBox="0 0 19 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                fill="#777391"
                                              />
                                            </svg>{" "}
                                            &nbsp; &nbsp;Edit Thali
                                          </a>
                                        </li> */}
                                          <li className="deletedthali" onClick={() => getdeletethali(tahlitems.id)}>
                                            <a
                                              className="dropdown-item"

                                            >
                                              {" "}
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="20"
                                                viewBox="0 0 18 20"
                                                fill="none"
                                              >
                                                <path
                                                  d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                                  fill="#777391"
                                                />
                                              </svg>
                                              &nbsp; &nbsp; &nbsp;Remove Thali
                                            </a>
                                          </li>
                                        </ul>
                                      </div>


                                    </li>

                                  </ul></th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <nav>
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleFirstPage}>
                          First
                        </button>
                      </li>
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>

                      {getPageNumbers().map((pageNumber) => (
                        <li
                          className={`page-item  ${currentPage === pageNumber ? 'active' : ''}`}
                          key={pageNumber}
                        >
                          <button
                            className="page-link pagebutton"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      ))}

                      <li
                        className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                      <li
                        className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}
                      >
                        <button className="page-link" onClick={handleLastPage}>
                          Last
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}



              {/* Add Thali popup */}
              {showPopup && (
                <div
                  className="offcanvas calender_canvas offcanvas-end"
                  ref={popupRef}
                >
                  <div className="offcanvas-header p-0">
                    <div className="title_div">
                      {" "}
                      <h5 className="title_heading" id="title_heading">
                        Add Thali
                      </h5>
                      <span className="Line_div">|</span>
                      {savedraft == "1" && (<span className="Draft_thali">{savedraft == "1" ? "Draft" : ""}</span>)}
                      {selectedOption == "MIQAAT" && is_niyaaz != "1" && (<span className="Draft_MIQAAT">{selectedOption == "MIQAAT" && is_niyaaz != "1"  ? "MIQAAT" : ""}</span>)}
                      {selectedOption == "MIQAAT" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{is_niyaaz == "1" ? "PRIVATE" : ""}</span>)}
                      {selectedOption == "FMBTHALI" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{is_niyaaz == "1" ? "MIQAAT-THALI" : ""}</span>)}
                      {jamantype == "ORDERING" && (<span className="Draft_ORDERING">{jamantype == "ORDERING" ? "ORDERING" : ""}</span>)}
                      <span className="Date_list">
                        {moment(selectedDate).format("MM/DD/YYYY")}{" "}
                        {selectedDate.toDateString().split(" ")[0]}{" "}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowPopup(false)}
                    ></button>
                  </div>

                  <div className="offcanvas-body">
                    <div className="Calnder_tab">
                      <h5 className="Calnder_tab_heding">Niyaz Type</h5>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li
                          className={
                            selectedOption === "FMBTHALI" && is_niyaaz != "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input active"
                              defaultChecked
                              id="home-tab"
                              type="radio"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              name="flexRadioDefault"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              value="FMBTHALI"
                              checked={selectedOption === "FMBTHALI" && is_niyaaz != "1"}
                              onChange={handleRadioChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              FMB Thali
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "FMBTHALI" && is_niyaaz == "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="FMBTHALI"
                              checked={selectedOption === "FMBTHALI" && is_niyaaz == "1"}
                              onChange={handleRadioNiyaazChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Miqaat Thali
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "MIQAAT" && is_niyaaz != "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="MIQAAT"
                              checked={selectedOption === "MIQAAT" && is_niyaaz != "1"}
                              onChange={handleRadioChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Miqaat
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "MIQAAT" && is_niyaaz == "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="MIQAAT"
                              checked={selectedOption === "MIQAAT" && is_niyaaz == "1"}
                              onChange={handleRadioNiyaazChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Private
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="Fmb_thali_calender">
                            <h5 className="Calnder_tab_heding">Jaman type</h5>
                            <ul
                              className="nav nav-tabs"
                              id="myTab2"
                              role="tablist"
                            >
                              <li
                                className={
                                  isDivVisible ? "nav-item" : "nav-item tab1"
                                }
                                role="presentation"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input active"
                                    type="radio"

                                    defaultChecked={!isDivVisible}
                                    name="Cooking"
                                    value="COOKING"
                                    onChange={handleTabClick}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="cooking-tab"
                                  >
                                    Cooking In-House
                                  </label>
                                </div>
                              </li>
                              <li
                                className={
                                  !isDivVisible ? "nav-item" : "nav-item tab1"
                                }
                                role="presentation"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    defaultChecked={isDivVisible}
                                    name="Cooking"
                                    value="ORDERING"
                                    onChange={handleTabClick}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Ordering-tab"
                                  >
                                    Ordering
                                  </label>
                                </div>
                              </li>
                            </ul>

                            <div className="Menu_class">
                              {/* Order By */}
                              {isDivVisible ? (
                                <>
                                  <div className="row Fateha_div">
                                    <h1 className="Caterer_heading">
                                      Order from (Vendor)
                                    </h1>
                                    <div className="col-md-12">
                                      <div className="Form_box">
                                        {/* <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Order from (Vendor)
                                        </label> */}
                                        {/* <Select
                                          options={orders}
                                          className="dropdown "
                                          placeholder="Select"
                                          styles={colourStyles}
                                          hideSelectedOptions={true}
                                          value={order}
                                          onChange={(e) => setOrder(e)}
                                        /> */}

                                        <input
                                          type="text"
                                          placeholder="Order from (Vendor)"
                                          // className={`${validationerror && !fatehaname ? "validation_valid form-control" : "form-control"}`}
                                          className="form-control"
                                          id="Fateha"
                                          onChange={(e) =>
                                            setOrder(e.target.value)
                                          }
                                          value={order}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row">
                                    <h1 className="Caterer_heading">Caterer</h1>
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Caterer1
                                        </label>
                                        <Select
                                          options={getCatererlistdata}
                                          className="dropdown "
                                          placeholder="Select "
                                          styles={colourStyles}
                                          id="inputState2"
                                          hideSelectedOptions={true}
                                          isClearable={true}
                                          value={catererfist}
                                          onChange={(e) => setCatererfirst(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Caterer2
                                        </label>
                                        <Select
                                          options={getCatererlistdata}
                                          className="dropdown "
                                          placeholder="Select"
                                          hideSelectedOptions={true}
                                          isClearable={true}
                                          styles={colourStyles}
                                          value={caterersecond}
                                          onChange={(e) => setCaterersecond(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* roti_div */}
                            <div className="roti_div">
                              <div className="Roti_list">
                                <h5>Dishes</h5>
                                <button onClick={AddDishModal} className="btn">
                                  Add Dish
                                </button>
                              </div>
                              {/* {Array.from({ length: duplicateCount }, (_, index) => ( */}
                              {Array.isArray(storedDishes) && storedDishes.length > 0 ? (
                                storedDishes?.map((item, index) => (
                                  <div key={index}>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="Form_box">
                                          <label
                                            for="inputCaterer"
                                            className="form-label"
                                          >
                                            {item?.optiondata?.label}
                                            {/* {   DishTypeName(item.optiondata.label)} */}
                                            {/* {console.log('dddaaaaa',item.dishid.label)} */}
                                          </label>
                                          <Select
                                            options={dishviewlistdata}
                                            className="dropdown"
                                            placeholder="Select Dish Name"
                                            id="inputState"
                                            styles={colourStyles}
                                            hideSelectedOptions={false}
                                            isClearable={false}
                                            defaultValue={type}
                                            onChange={(e) =>
                                              handleDishIdChange(index, e.label)
                                            }
                                            onMenuOpen={(e) =>
                                              DishTypeName(item?.optiondata?.label, niyaazdish)
                                            }
                                           
                                            value={
                                              item?.dishid?.label
                                                ? dishviewlistdata.find(
                                                  (option) =>
                                                    option?.label ===
                                                    item?.dishid?.label
                                                )
                                                : null
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="Form_box">
                                          <label
                                            for="inputCaterer"
                                            className="form-label"
                                          >
                                            <br />

                                          </label>
                                          <div className="roti_full d-none">
                                            <div
                                              className={
                                                item.selecteddish === "HALF"
                                                  ? "form-check tab1"
                                                  : "form-check"
                                              }
                                            >
                                              <input
                                                class="form-check-input active"
                                                type="radio"
                                                defaultChecked
                                                value="HALF"
                                                checked={
                                                  item.selecteddish === "HALF"
                                                }
                                                onChange={(e) =>
                                                  handleRadioButtonChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                              <label
                                                class="form-check-label"
                                                for="Half"
                                              >
                                                Half
                                              </label>
                                            </div>
                                            <div
                                              className={
                                                item.selecteddish === "FULL"
                                                  ? "form-check tab1"
                                                  : "form-check"
                                              }
                                            >
                                              <input
                                                class="form-check-input active"
                                                type="radio"
                                                defaultChecked
                                                value="FULL"
                                                checked={
                                                  item.selecteddish === "FULL"
                                                }
                                                onChange={(e) =>
                                                  handleRadioButtonChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                              <label
                                                class="form-check-label"
                                                for="Half"
                                              >
                                                Full
                                              </label>
                                            </div>
                                          </div>
                                          <div className="roti_full ">
                                            <div class="Delect_roti">
                                              <img
                                                onClick={handleRemove}
                                                src={remove}
                                                className="img-firud"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                // Render alternative content when storedDishes is null
                                <p className="error">{errormasg}</p>
                              )}
                            </div>

                            <div className="row">
                            {selectedOption === "MIQAAT" ? <>   
                          </> :
                            <div className="col-md-6">
                            <div className="Form_box">
                              <label for="Standard" className="form-label">
                                Rice (Allow Skip)
                              </label>
                              <ul className="thali_menu_list">
                                <div>
                                  <div className="Register_user_input">
                                    <div className="onoffswitch">
                                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox " id="myonoffswitch" checked={allowSkipRice === "1"}
                                        onChange={handleAllowSkipRiceToggleChange} />
                                      <label className="onoffswitch-label" for="myonoffswitch">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>

                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          }
                          {selectedOption === "MIQAAT" ? <>   
                          </> :
                          <div className="col-md-6">
                            <div className="Form_box">
                              <label for="Standard" className="form-label">
                                Roti (Allow Skip)
                              </label>
                              <ul className="thali_menu_list">
                                <div>
                                  <div className="Register_user_input">
                                    <div className="onoffswitch">
                                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                                        checked={allowSkipRoti === "1"}
                                        onChange={handleAllowSkipRotiToggleChange} />
                                      <label className="onoffswitch-label" for="rice_check">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          }
                          </div>

                            <div className="Fateha_div">
                              <div className="row">
                                <h1 className="Caterer_heading">Fateha</h1>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                    >
                                      Dish Name
                                    </label>
                                    <Select
                                      options={getnewlistdata}
                                      className="dropdown"
                                      placeholder="Select Fateha Dish"
                                      id="inputrice"
                                      styles={colourStyles}
                                      hideSelectedOptions={true}
                                      isClearable={true}
                                      value={dishtypeid}
                                      defaultValue={cleartype}
                                      onChange={(e) => setDishTypeId(e)}
                                    />

                                    {/* {
                                      validationerror && !dishtypeid &&
                                      <p className="show_invalid_text" >  Dish is required</p>
                                    } */}
                                    {/* <input
                                      type="text"
                                      placeholder="Enter Fateha Name"
                                      className="form-control"
                                      id="Fateha"
                                      onChange={(e) =>
                                        setDishTypeId(e)
                                      }
                                      value={dishtypeid}
                                    /> */}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"

                                    >
                                      Fateha Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Fateha Name"
                                      // className={`${validationerror && !fatehaname ? "validation_valid form-control" : "form-control"}`}
                                      className="form-control"
                                      id="Fateha"
                                      onChange={(e) =>
                                        setFatehaName(e.target.value)
                                      }
                                      value={fatehaname}
                                    />

                                    {/* {
                                      validationerror && !fatehaname &&
                                      <p className="show_invalid_text" >    fateh Name is required</p>
                                    } */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="Fateha_div">
                              <div className="row Menu_class">
                                <h1 className="Caterer_heading">
                                  Additional Notes
                                </h1>
                                <div className="col-md-12">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Comments
                                    </label>
                                    <textarea
                                      placeholder="Add your comments here..."
                                      className="form-control"
                                      id="Testlist"
                                      rows="4"
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {selectedOption === "MIQAAT" ? <>

                            </> : <> <div className="Fateha_div">
                              <div className="row Menu_class">
                                <h1 className="Caterer_heading">
                                  Rsvp Date
                                </h1>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Rsvp End Date
                                    </label>
                                    <DatePicker value={alldatelist} onChange={handleDateChange} />
                                    {/* {alldatelist} */}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Rsvp End Time
                                    </label>
                                    {/* <p>{endtime}</p> */}
                                    <Select
                                      options={timezone}
                                      className="dropdown"
                                      placeholder="Select End Time "
                                      styles={colourStyles}
                                      hideSelectedOptions={true}
                                      value={endtime}
                                      onChange={(selectedOption) => setEndTime(selectedOption)}
                                    // defaultValue={timezone[0]}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div></>}

                          </div>
                        </div>

                        {/* Comments */}
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Ingredients_title">
                    <div className="Save_btn">
                      <button type="button" onClick={() => setShowPopup(false)}>
                        Cancel
                      </button>
                      <button type="submit" onClick={(e) => { setSaveDraft('1'); handleSubmit2(e, "1") }}>
                        Save As Draft
                      </button>
                      <button type="submit" onClick={(e) => handleSubmit2(e, '0')} >
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
              )}




              {/* Edit  Thali popup */}
              {editshowPopup && (
                <div
                  className="offcanvas calender_canvas offcanvas-end"
                  ref={popupRef}
                >

                  {Thaliviewdata.map((Thalidatalist) => (
                    <div key={Thalidatalist}>

                    </div>
                  ))}
                  <div className="offcanvas-header p-0">
                    <div className="title_div">
                      {" "}
                      <h5 className="title_heading" id="title_heading">
                        Edit Thali
                      </h5>
                      <span className="Line_div">|</span>
                      {savedraft == "1" && (<span className="Draft_thali">{savedraft == "1" ? "Draft" : ""}</span>)}
                      {selectedOption == "MIQAAT" && is_niyaaz != "1" && (<span className="Draft_MIQAAT">{selectedOption == "MIQAAT" && is_niyaaz != "1"  ? "MIQAAT" : ""}</span>)}
                      {selectedOption == "MIQAAT" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{is_niyaaz == "1" ? "PRIVATE" : ""}</span>)}
                      {selectedOption == "FMBTHALI" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{is_niyaaz == "1" ? "MIQAAT-THALI" : ""}</span>)}
                      {jamantype == "ORDERING" && (<span className="Draft_ORDERING">{jamantype == "ORDERING" ? "ORDERING" : ""}</span>)}

                      <span className="Date_list">
                        {moment(selectedDate).format("MM/DD/YYYY")}{" "}
                        {selectedDate.toDateString().split(" ")[0]}{" "}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setEditShowPopup(false)}
                    ></button>
                  </div>

                  <div className="offcanvas-body">
                    <div className="Calnder_tab">
                      <h5 className="Calnder_tab_heding">Niyaz Type</h5>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li
                          className={
                            selectedOption === "FMBTHALI" && is_niyaaz != '1'
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input active"
                              defaultChecked
                              id="home-tab"
                              type="radio"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              name="flexRadioDefault"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              value="FMBTHALI"
                              checked={selectedOption === "FMBTHALI" && is_niyaaz != "1"}
                              onChange={handleRadioChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              FMB Thali
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "FMBTHALI" && is_niyaaz == "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="FMBTHALI"
                              checked={selectedOption === "FMBTHALI" && is_niyaaz == "1"}
                              onChange={handleRadioNiyaazChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Miqaat Thali
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "MIQAAT" && is_niyaaz != '1'
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="MIQAAT"
                              checked={selectedOption === "MIQAAT" && is_niyaaz != '1'}
                              onChange={handleRadioChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Miqaat
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "MIQAAT" && is_niyaaz == "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="MIQAAT"
                              checked={selectedOption === "MIQAAT" && is_niyaaz == "1"}
                              onChange={handleRadioNiyaazChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Private
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="Fmb_thali_calender">
                            <h5 className="Calnder_tab_heding">Jaman type</h5>
                            <ul
                              className="nav nav-tabs"
                              id="myTab2"
                              role="tablist"
                            >
                              <li
                                className={
                                  isDivVisible ? "nav-item" : "nav-item tab1"
                                }
                                role="presentation"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input active"
                                    type="radio"


                                    checked={!isDivVisible}
                                    name="Cooking"
                                    value="COOKING"
                                    onChange={handleTabClick}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="cooking-tab"
                                  >
                                    Cooking In-House
                                  </label>
                                </div>
                              </li>
                              <li
                                className={
                                  !isDivVisible ? "nav-item" : "nav-item tab1"
                                }
                                role="presentation"
                              >
                                <div class="form-check">
                                  <input

                                    class="form-check-input"
                                    type="radio"
                                    checked={isDivVisible}
                                    name="Cooking"
                                    value="ORDERING"
                                    onChange={handleTabClick}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Ordering-tab"
                                  >
                                    Ordering
                                  </label>
                                </div>
                              </li>
                            </ul>

                            <div className="Menu_class">
                              {/* Order By */}
                              {isDivVisible ? (
                                <>
                                  <div className="row Fateha_div">
                                    <h1 className="Caterer_heading">
                                      Order from (Vendor)
                                    </h1>
                                    <div className="col-md-12">

                                      {/* <Select
                                          options={orders}
                                          className="dropdown "
                                          placeholder="Select"
                                          hideSelectedOptions={true}
                                          styles={colourStyles}
                                          value={order}
                                          onChange={(e) => setOrder(e)}
                                        /> */}

                                      <div className="Form_box">
                                        {/* <label
                                            for="inputCaterer"
                                            className="form-label"
                                          >
                                            Order from (Vendor)
                                          </label> */}
                                        <input
                                          type="text"
                                          placeholder="Enter Order from (Vendor) "
                                          className="form-control"
                                          id="Fateha"
                                          onChange={(e) =>
                                            setOrder(e.target.value)
                                          }
                                          value={order}
                                        />
                                      </div>

                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row">
                                    <h1 className="Caterer_heading">Caterer</h1>
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Caterer1
                                        </label>
                                        <Select
                                          options={getCatererlistdata}
                                          className="dropdown "
                                          placeholder="Select"
                                          styles={colourStyles}
                                          isClearable={true}
                                          hideSelectedOptions={true}
                                          onChange={(e) => setCatererfirst(e)}
                                          value={getCatererlistdata.find((option) => option.value === catererfist)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Caterer2
                                        </label>
                                        <Select
                                          options={getCatererlistdata}
                                          className="dropdown "
                                          placeholder="Select"
                                          isClearable={true}
                                          hideSelectedOptions={true}
                                          styles={colourStyles}
                                          onChange={(e) => setCaterersecond(e)}
                                          value={getCatererlistdata.find((option) => option.value === caterersecond)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* roti_div */}

                            <div className="roti_div">
                              <div className="Roti_list">
                                <h5>Dishes</h5>
                                <button onClick={AddDishModal} className="btn">
                                  Add Dish
                                </button>
                              </div>
                              {/* {Array.from({ length: duplicateCount }, (_, index) => ( */}
                              {disheditdata.map((item, index) => (
                                <div key={index}>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          {item.dish_type ? item.dish_type : item?.optiondata?.value}
                                          {/* {   DishTypeName(item.optiondata.label)} */}
                                          {/* {console.log('dddaaaaa',item.dishid.label)} */}
                                        </label>




                                        <Select
                                          options={dishviewlistdata}
                                          className="dropdown"
                                          placeholder="Select Dish Name"
                                          id="inputState"
                                          hideSelectedOptions={false}
                                          isClearable={false}
                                          styles={colourStyles}
                                          defaultValue={cleartype}
                                          onChange={(e) =>
                                            handleDishIdChange2(index, e)
                                          }
                                          onMenuOpen={(e) =>
                                            DishTypeName(item.dish_type ? item.dish_type : item.optiondata.value, niyaazdish)
                                          }
                                          
                                          value={item?.dish_name ? { label: item?.dish_name, value: item?.dish_name } : { label: item?.dishid?.label, value: item?.dishid?.value }}
                                        />

                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          <br />
                                        </label>
                                        <div className="roti_full d-none">
                                          <div
                                            className={
                                              item.quantity === "HALF"
                                                ? "form-check tab1"
                                                : "form-check"
                                            }
                                          >
                                            <input
                                              class="form-check-input active"
                                              type="radio"
                                              defaultChecked
                                              value="HALF"
                                              checked={
                                                item.quantity === "HALF" ? item.quantity === "HALF" : item.selecteddish === "HALF"
                                              }
                                              onChange={(e) =>
                                                handleRadioButtonChange2(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              class="form-check-label"
                                              for="Half"
                                            >
                                              Half
                                            </label>
                                          </div>
                                          <div
                                            className={
                                              item.quantity === "FULL"
                                                ? "form-check tab1"
                                                : "form-check"
                                            }
                                          >
                                            <input
                                              class="form-check-input active"
                                              type="radio"
                                              defaultChecked
                                              value="FULL"
                                              checked={
                                                item.quantity === "FULL" ? item.quantity === "FULL" : item.selecteddish === "FULL"
                                              }
                                              onChange={(e) =>
                                                handleRadioButtonChange2(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              class="form-check-label"
                                              for="Half"
                                            >
                                              Full
                                            </label>
                                          </div>
                                        </div>
                                        <div className="roti_full">
                                          <div class="Delect_roti">
                                            <img
                                                onClick={() => handleEditRemove(index)}
                                              src={remove}
                                              className="img-firud"
                                            />
                                          </div>
                                          <button
                                          onClick={() => handleNavigateDishPage(item?.dish_name ? item?.dish_name : item?.dishid?.label, selectedOption)}
                                          className="Inv_Cal_Count_btn"
                                          >
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="17.5"
                                              height="17.5"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                          >
                                              <path
                                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                                              fill="#957137"
                                              />
                                          </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="row">
                            {selectedOption === "MIQAAT" ? <>   
                          </> :
                            <div className="col-md-6">
                            <div className="Form_box">
                              <label for="Standard" className="form-label">
                                Rice (Allow Skip)
                              </label>
                              <ul className="thali_menu_list">
                                <div>
                                  <div className="Register_user_input">
                                    <div className="onoffswitch">
                                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox " id="myonoffswitch" checked={allowSkipRice === "1"}
                                        onChange={handleAllowSkipRiceToggleChange} />
                                      <label className="onoffswitch-label" for="myonoffswitch">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>

                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          }
                          {selectedOption === "MIQAAT" ? <>   
                          </> :
                          <div className="col-md-6">
                            <div className="Form_box">
                              <label for="Standard" className="form-label">
                                Roti (Allow Skip)
                              </label>
                              <ul className="thali_menu_list">
                                <div>
                                  <div className="Register_user_input">
                                    <div className="onoffswitch">
                                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                                        checked={allowSkipRoti === "1"}
                                        onChange={handleAllowSkipRotiToggleChange} />
                                      <label className="onoffswitch-label" for="rice_check">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          }
                          </div>

                            <div className="Fateha_div">
                              <div className="row">
                                <h1 className="Caterer_heading del_all">Fateha <div class="Delect_roti">Clear: <img
                                  onClick={Resetfild}
                                  src={remove}
                                  className="img-firud"
                                />
                                </div></h1>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer" className="form-label"
                                    >
                                      Dish Name
                                    </label>
                                    <Select
                                      options={getnewlistdata}
                                      className="dropdown"
                                      placeholder="Select Fateha Dish"
                                      id="inputrice"
                                      styles={colourStyles}
                                      defaultValue={cleartype}
                                      onChange={(e) => setDishTypeId(e)}
                                      value={getnewlistdata.find((option) => option.value === dishtypeid)}
                                      isClearable={true}
                                      hideSelectedOptions={true}
                                    />

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Fateha Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Fateha Name"
                                      className="form-control"
                                      id="Fateha"
                                      onChange={(e) =>
                                        setFatehaName(e.target.value)
                                      }
                                      value={fatehaname}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="Fateha_div">
                              <div className="row Menu_class">
                                <h1 className="Caterer_heading">
                                  Additional Notes
                                </h1>
                                <div className="col-md-12">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Comments
                                    </label>
                                    <textarea
                                      placeholder="Add your comments here..."
                                      className="form-control"
                                      id="Testlist"
                                      rows="4"
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                      value={comment}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Comments */}
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div className="row Menu_class">
                            <div className="col-md-12">
                              <div className="Form_box">
                                <label
                                  for="inputCaterer"
                                  className="form-label"
                                >
                                  Comments
                                </label>
                                <textarea
                                  placeholder="Add your comments here..."
                                  className="form-control"
                                  id="Testlist"
                                  rows="8"
                                  onChange={(e) => setNote(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedOption === "MIQAAT" ? <>

                        </> : <><div className="Fateha_div">
                          <div className="row Menu_class">
                            <h1 className="Caterer_heading">
                              Rsvp Date
                            </h1>
                            <div className="col-md-6">
                              <div className="Form_box">
                                <label
                                  for="inputCaterer"
                                  className="form-label"
                                >
                                  Rsvp End Date
                                </label>
                                <DatePicker value={alldatelist} onChange={handleDateChange} />
                                {/* {alldatelist} */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="Form_box">
                                <label
                                  for="inputCaterer"
                                  className="form-label"
                                >
                                  Rsvp End Time
                                </label>
                                {/* <p>{endtime}</p> */}
                                <Select
                                  options={timezone}
                                  className="dropdown"
                                  placeholder="Select End Time "
                                  styles={colourStyles}
                                  hideSelectedOptions={true}
                                  // value={endtime.label}
                                  value={timezone.find((option) => option.label === endtime)}
                                  onChange={(e) => setEndTime(e.label)}
                                />

                              </div>
                            </div>
                          </div>
                        </div></>}

                      </div>
                    </div>
                  </div>
                  <div className="Ingredients_title">
                    <div className="Save_btn">
                      <button type="button" onClick={() => setEditShowPopup(false)} >
                        Cancel
                      </button>
                      <button type="submit" onClick={(e) => { setSaveDraft('1'); handleUpdateSubmit2(e, "1") }}>
                        Save As Draft
                      </button>
                      <button type="submit" onClick={(e) => handleUpdateSubmit2(e, '0')} >
                        Publish
                      </button>
                      {/* <button type="submit" onClick={handleUpdateSubmit2} >
                        Publish
                      </button> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>






      {/* Thali schedule view popup */}

      <Modal
        isOpen={showview}
        onRequestClose={setShowview}
        style={customStyles}
        contentLabel="Remove Modal"
        id="edit_calender_popup"
      >
        <div className="popup_modal">

          <div className="modal_body">
            {existingEventlist && existingEventlist.map((existingdata, index) => {
              const currentDate = moment().startOf('day');
              const previousDate = moment(existingEventlist[0]?.start).startOf('day');
              const isTodayOrFuture = moment(previousDate).isSameOrAfter(currentDate, 'day');
              const thalidraft = existingdata?.save_draft;
              const thalischeduletype = existingdata?.schedule_type;
              const jamantype = existingdata?.jamanType;
              const is_niyaaz = existingdata?.is_niyaaz;

              return (
                <>
                  <div key={index}>
                 
                    <div className="Modal_heding">
                      <h1>
                        Thaali schedule{" "}
                        <span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
                          {moment(existingEventlist[0]?.start).format("DD/MM/YYYY")}
                        </span>
                        { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) && isTodayOrFuture &&
                        <button            onClick={() => RemoveModal(existingdata.id)} >
                          <img src={remove} className="img-fluid remove-icon-thaali" />
                        </button>
                        }
                      </h1>
                      <div className="edit_btn_celender">

                        {thalidraft == "1" && (<span className="Draft_thali">{thalidraft == "1" ? "Draft" : ""}</span>)}
                        {thalischeduletype == "MIQAAT" && is_niyaaz != '1' && (<span className="Draft_MIQAAT">{thalischeduletype == "MIQAAT" && is_niyaaz != '1' ? "MIQAAT" : ""}</span>)}
                        {jamantype == "ORDERING" && (<span className="Draft_ORDERING">{jamantype == "ORDERING" ? "ORDERING" : ""}</span>)}
                        {thalischeduletype == "MIQAAT" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{is_niyaaz == "1" ? "PRIVATE" : ""}</span>)}
                        {thalischeduletype == "FMBTHALI" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{is_niyaaz == "1" ? "MIQAAT-THALI" : ""}</span>)}
                        { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) && isTodayOrFuture && (
                       
                        <button type="button" onClick={handleplus} >  <img src={nep} className="img-fluid plusimg" /></button>
                       
                        )}
                        { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) && isTodayOrFuture && (
                          <button type="button" onClick={() => getthaliId(existingdata.id)}>
                            <img src={edit} className="img-fluid" />
                          </button>
                        )}
                       

                        <button onClick={closeEventPopup}>
                          <img src={closeicon} className="img-fluid  " />
                        </button>

                       


                      </div>
                    </div>
                    <div className="calender_tabal newcalender" >
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              {" "}
                              <span className="thali_table">Menu</span>
                            </th>
                            <th scope="col">
                              <span className="thali_table">fateha</span>
                            </th>
                            <th scope="col">
                              <span className="thali_table">Caterers</span>
                            </th>
                            <th scope="col">
                              <span className="thali_table">Remarks</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                {existingdata.menudata &&
                                  JSON.parse(existingdata.menudata).map((menuItem, index) => (
                                    <li key={index}>
                                      <div className="event-title">
                                        {menuItem === 'Rice' && (
                                          <img src={Rice} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Roti' && (
                                          <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Gravy' && (
                                          <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Sweetes' && (
                                          <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Soup' && (
                                          <img src={soup} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Drinks' && (
                                          <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Mango' && (
                                          <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Salad' && (
                                          <img src={Drinks} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Kheer' && (
                                          <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem === 'Kadai Gosh' && (
                                          <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                                        )}

                                        {menuItem === 'FriedChicken' && (
                                          <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                                        )}
                                        {menuItem ? menuItem : 'NA'}
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </th>
                            <th scope="row">
                              {" "}
                              <ul className="thali_menu_list">
                                <li>
                                  {" "}
                                  {console.log("existingdata", existingdata, existingdata.title)}
                                  {existingdata.is_fateha_dish_name ? existingdata.is_fateha_dish_name : ''} &nbsp; ({existingdata.title ? existingdata.title : 'NA'})
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{existingdata.cater_name1 && existingdata.cater_name1 !== "null" && (
                                  <> <img src={user_icon} className="img-fluid" />
                                    {existingdata.cater_name1 ? existingdata.cater_name1 : 'NA'}</>
                                )}

                                </li>
                                <li>{existingdata.cater_name2 && existingdata.cater_name2 !== "null" && (
                                  <> <img src={user_icon} className="img-fluid" />
                                    {existingdata.cater_name2 ? existingdata.cater_name2 : 'NA'}</>
                                )}

                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list new_data">
                                <li>
                                  {existingdata.notesdata ? existingdata.notesdata : 'NA'}
                                </li>
                              </ul>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          {/* <h2>{selectedEvent?.Dish}</h2> */}
        </div>
      </Modal>





      {/* Add Dish popup  */}
      <Modal
        isOpen={addDish}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={DishcloseModal}
        style={customStyles}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        {" "}
        <div className="popup_modal add_dish_Add">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Popup_heding">
              <img src={dishlist} className="img-firud" />
              &nbsp; &nbsp; &nbsp;&nbsp;
              <div className="edit_div">
                <h1>Add Dish </h1>
              </div>
            </div>
            <button onClick={DishcloseModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                  fill="#2E294E"
                />
              </svg>
            </button>
          </div>
          <div className="edit_from">
            <form>
              <div className="form-group  Form_box">
                <label>Dish type</label>

                <Select
                  options={dishTypeListData}
                  className="dropdown"
                  placeholder="Select Dish Type"
                  id="inputState"
                  styles={colourStyles}
                  hideSelectedOptions={true}
                  value={optiondata}
                  defaultValue={Defultdishtype}
                  onChange={(e) => {
                    setoptiondata(e);
                    DishTypeName(e.label, niyaazdish);
                    setDishId('');
                  }}

                />
              </div>

              <div className="form-group Form_box">
                <label className="repetitioncount">Dish Name   <span> Repetition Count {repetitioncount ? repetitioncount : "0"}</span></label>

                <Select
                  //  key={DishTypeName}
                  options={dishviewlistdata}
                  className="dropdown"
                  placeholder="Select Dish Name"
                  id="inputState"
                  hideSelectedOptions={true}
                  styles={colourStyles}
                  value={dishid ? dishid : "no dish add "}
                  defaultValue={type}
                  // onChange={(e) => setDishId(e)}
                  onChange={(e) => {
                    setDishId(e);
                    // setRepetitionCount(e.repetitioncountlist);
                    DishNameType(e.label, niyaazdish);
                    console.log("repetitioncount", e);
                  }}

                />
              </div>

              <div className="fulltali d-none">
                <div class="form-check">
                  <input
                    class="form-check-input active"
                    type="radio"
                    defaultChecked
                    checked={selecteddish === "HALF"}
                    onChange={handleDishChange}
                    value="HALF"
                  />
                  <label class="form-check-label" for="Half">
                    Half
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input active"
                    type="radio"
                    defaultChecked
                    value="FULL"
                    checked={selecteddish === "FULL"}
                    onChange={handleDishChange}
                  />
                  <label class="form-check-label" for="Half">
                    Full
                  </label>
                </div>
              </div>
              <div className="Thali_page_btn_from">
                <button
                  type="button"
                  onClick={DishcloseModal}
                  className="btn thali_btn"
                >
                  Cancel
                </button>
                &nbsp; &nbsp; &nbsp;
                <button
                  button="button"
                  className="btn thali_btn"
                  onClick={handleDuplicate}
                >
                  Add Dish
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>




      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove  Thaali</h1>
            <p>
              Do you really want to remove Thaali? This action cannot be undone
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button  onClick={() => getdeletethali(removelist)}   className="btn thali_btn">
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
